import { useEffect, useState } from 'react';

import { INotif } from '../../../../interfaces/notif.interface';
import { notifService } from '../../../../services/notif.service';
import { NotifsList } from '../../NotifsList';

export const Notifications = () => {
  const [opened, setOpened] = useState(false);
  const [notifs, setNotifs] = useState<INotif[]>([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    function refresh() {
      notifService
        .getList()
        .then(notifs => {
          setNotifs(notifs);
          setCount(notifs.filter(notif => !notif.isRead).length);
        })
        .catch(err => console.log(err));
    }

    refresh();
    const timer = setInterval(refresh, 10 * 1000);

    return () => clearInterval(timer);
  }, []);

  function toggle() {
    if (opened && notifs.length > 0) {
      notifService
        .tagAsReadBefore(notifs[0].id)
        .catch(err => console.error(err));
    }
    setCount(0);
    setOpened(!opened);
  }

  return (
    <div className="hidden md:ml-4 md:flex md:items-center" >
      <button onClick={toggle}
        className="flex-shrink-0 px-1.5 py-1 hover:bg-secondaryPandaColor rounded-full hover:text-white z-10">
        <span className="sr-only">Notifications</span>

        <span className="relative inline-block text-sm">
          <i className="fas fa-bell fa-lg fa-inverse" />
          { count > 0 && <span className="absolute top-0 right-0 inline-flex items-center justify-center px-1.5 py-0.5 text-xs leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{count}</span> }
        </span>
      </button>
      {opened && <NotifsList notifs={notifs} onClick={toggle} />}
    </div>
  );
};
