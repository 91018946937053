import { UserRole } from '../enums/user-role.enum';

export const userRoleLabels: {[role in UserRole]: string} = {
  [UserRole.AdminRoot]: 'Super administrateur',
  [UserRole.AdminExpert]: 'Expert',
  [UserRole.AdminPresta]: 'Prestataire',
  [UserRole.AdminBilling]: 'Facturation',

  [UserRole.ClientRoot]: 'Administrateur',
  [UserRole.ClientRH]: 'Collaborateur RH',
  [UserRole.ClientNonRD]: 'Collaborateur non R&D',
  [UserRole.ClientLevel1]: 'Collaborateur niveau 1',
  [UserRole.ClientLevel2]: 'Collaborateur niveau 2',
  [UserRole.ClientLevel3]: 'Collaborateur niveau 3',
};

export function getRoleLabel(role: UserRole): string {
  return userRoleLabels[role];
}
