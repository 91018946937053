import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';

import { useAdmins } from '../../../../../hooks/use-admins';
import { Spinner } from '../../../../general/Spinner';
import { IMissionAdmin } from '../../../../../interfaces/mission-admin.interface';
import { getMissionAdminFunctionLabel, MissionAdminFunction, MissionCIxAdminFunctionList, MissionFinancingFunctionAdminList } from '../../../../../enums/mission-admin-function.enum';
import { Box } from '../../../../general/Box';
import { MissionCategory } from '../../../../../enums/mission-category.enum';
import clsx from 'clsx';
import { getRoleLabel } from '../../../../../casl/roles-label';
import { NoData } from '../../../../general/NoData';
import { ButtonBar } from '../../../../general/buttons/ButtonBar';

type Props = {
  name: string;
  title: string;
  category: MissionCategory;
  control: Control<any>;
  register: UseFormRegister<any>;
}

export const AdminInput = ({ name, title, category, control, register }: Props) => {
  const { admins } = useAdmins();
  const { fields, append, remove } = useFieldArray({ control, name });
  const defaultValues: IMissionAdmin = {
    adminId: admins ? admins[0].id : '',
    function: MissionAdminFunction.Responsible
  };

  if (!admins) {
    return <Spinner />;
  }

  return (
    <Box title={title} className="mt-6">
      <div className="ml-4 px-6 grid grid-cols-12 gap-3">
        <div className = "col-span-6 text-left text-xs font-medium text-gray-500">Fonction<span className="text-sm text-red-600"> *</span></div>
        <div className = "col-span-6 text-left text-xs font-medium text-gray-500">Administrateur<span className="text-sm text-red-600"> *</span></div>
      </div>
      <div className="grid grid-flow-row auto-rows-auto gap-4">

        {fields.map((field, index) => {
          const data = field as any as IMissionAdmin & Record<'id', string>;
          return (
            <div key={data.id} className={clsx('ml-4 px-6 grid grid-cols-12 gap-3', index % 2 ? 'bg-gray-50' : 'bg-white')}>
              <div className="col-span-6">
                <select {...register(`${name}.${index}.function` as const, { required: true })} defaultValue={data.function} >
                  { category === MissionCategory.CirCii && MissionCIxAdminFunctionList.map(fnc => <option key={fnc} value={fnc}>{getMissionAdminFunctionLabel(fnc)}</option> ) }
                  { category !== MissionCategory.CirCii && MissionFinancingFunctionAdminList.map(fnc => <option key={fnc} value={fnc}>{getMissionAdminFunctionLabel(fnc)}</option> ) }
                </select>
              </div>
              <div className="col-span-5">
                <select {...register(`${name}.${index}.adminId` as const, { required: true })} defaultValue={data.adminId} >
                  { admins.map(admin => <option key={admin.id} value={admin.id}>{`${admin.lastname} ${admin.firstname} (${getRoleLabel(admin.role)})`}</option> ) }
                </select>
              </div>

              <div className="col-span-1">
                <p className="inline-flex items-center px-2 py-1.5 md:px-12 md:py-2 text-sm font-medium text-red-700 cursor-pointer" onClick={() => remove(index)}><i className="fas fa-trash-alt fa-1x"/></p>
              </div>
            </div>
          );
        })}
        { fields.length === 0 && <NoData>Aucun administrateur</NoData> }
      </div>
      <ButtonBar>
        <button className="btn" type="button" onClick={() => append({ ...defaultValues })}>
          <i className="fas fa-plus fa-1x"/>
          <span className="hidden md:flex pl-0.5 text-xs">Ajouter un administrateur</span>
        </button>
      </ButtonBar>
    </Box>
  );
};
