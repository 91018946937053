import { Media } from './Media';
import { NoData } from './NoData';
import { IMedia } from '../../interfaces/media.interface';

type Props = {
  list: IMedia[];
  onDeleteRequest?: (media: IMedia) => void;
};
export const MediaList = ({ list = [], onDeleteRequest }: Props) => (
  <div className="flex justify-center flex-wrap">
    { list.map(media => <Media key={media.url} media={media} onDeleteRequest={onDeleteRequest} />) }
    { list.length === 0 && <NoData>Aucun document disponible</NoData>}
  </div>
);
