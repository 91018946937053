import { useHistory } from 'react-router-dom';

import { AdminForm } from '../../general/AdminForm';
import { Page } from '../../general/Page';
import { useAdmin } from '../../../hooks/use-admin';
import { Spinner } from '../../general/Spinner';
import { NotFound } from '../../general/NotFound';

type Props = {
  adminId?: string;
};

export const AdminFormPage = ({ adminId }: Props) => {
  const history = useHistory();
  const { loading, admin } = useAdmin(adminId);

  if (loading) {
    return <Spinner />;
  }

  if (adminId && !admin) {
    return <NotFound title="Utilisateur introuvable" />;
  }

  return (
    <Page>
      <AdminForm admin={admin} onSave={admin => history.push(`/admins/${admin.id}`)} />
    </Page>
  );
};
