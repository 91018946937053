import { FormFieldBase, RegisterFunction } from './types';

// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
type AutoComplete = 'name' | 'honorific-prefix' | 'given-name' | 'additional-name' | 'family-name' | 'honorific-suffix' | 'nickname' | 'username' | 'new-password' | 'current-password' | 'organization-title' | 'organization' | 'street-address' | 'address-line1' | 'address-line2' | 'address-line3' | 'address-level4' | 'address-level3' | 'address-level2' | 'address-level1' | 'country' | 'country-name' | 'postal-code' | 'cc-name' | 'cc-given-name' | 'cc-additional-name' | 'cc-family-name' | 'cc-number' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'transaction-currency' | 'transaction-amount' | 'language' | 'bday' | 'bday-day' | 'bday-month' | 'bday-year' | 'sex' | 'url' | 'photo' | 'tel' | 'tel-country-code' | 'tel-national' | 'tel-area-code' | 'tel-local' | 'tel-local-prefix' | 'tel-local-suffix' | 'tel-extension' | 'email' | 'impp';

export type FormFieldInput = FormFieldBase & {
  placeholder?: string;
  type?: 'text';
  onChange?: (value: any) => void;
  autoComplete?: 'on' | 'off' | AutoComplete;
}

export const Input = ({ field, register } : { field: FormFieldInput, register: RegisterFunction }) => (
  <input type='text'
    id={field.id || field.name}
    defaultValue={field.defaultValue}
    placeholder={field.placeholder || field.label}
    autoComplete={field.autoComplete || 'on'}
    {...register(field.name, field.registerOptions)}
     onChange={e => field.onChange && field.onChange(e.target.value)}
  />
);
