import { Children, FC, isValidElement, PropsWithChildren, ReactChild, ReactElement, ReactFragment, ReactPortal } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const Separator = () => (
  <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
      <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round"/>
    </svg>
  </div>
);

type PanelProps = {
  to?: string;
  onClick?: () => void;
  checked?: boolean;
  grayed?: boolean;
  active?: boolean;
  icon?: string;
}

export const Panel: FC<PanelProps> = ({ children }) => <>{children}</>;

function isPanel(child: ReactChild | ReactFragment | ReactPortal): child is ReactElement<PropsWithChildren<PanelProps>> {
  return isValidElement(child) && child.type === Panel;
}

type Props = {
  className?: string;
}

export const Panels: FC<Props> = ({ className, children }) => {
  const history = useHistory();
  const items = Children.toArray(children).filter(isPanel);

  return (
    <nav>
      <ol className={clsx('border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0', className)}>
        {
          items.map((item, index) => {
            const props = item.props;
            const clickable = Boolean(props.to) || Boolean(props.onClick);
            const onClick = props.onClick || (() => {
              if (props.to) {
                history.push(props.to);
              }
            });

            let color = 'text-gray-700 group-hover:text-gray-900';
            let borderColor = 'border-gray-500 group-hover:border-gray-600';
            if (props.active) {
              color = 'text-indigo-600 group-hover:text-indigo-800';
              borderColor = 'border-indigo-600 group-hover:border-indigo-800';
            } else if (props.checked) {
              borderColor = 'border-indigo-600 group-hover:border-indigo-800';
            } else if (props.grayed) {
              color = 'text-gray-500 group-hover:text-gray-900';
              borderColor = 'border-gray-300 group-hover:border-gray-600';
            }

            return (
              <li key={index} className={clsx('relative md:flex-1 md:flex group', clickable && 'cursor-pointer')} onClick={onClick} >
                <div className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                  <span className={clsx('flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 rounded-full', borderColor)}>
                    {
                      props.checked ?
                        (
                          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                            <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                            </svg>
                          </span>
                        ) :
                        (
                          props.icon ? <i className={clsx(color, props.icon)} /> :
                            <span className={color}>{(1 + index).toString().padStart(2, '0')}</span>
                        )
                    }
                  </span>
                  <span className={clsx('ml-4 text-sm font-medium', color)}>{props.children}</span>
                </div>
                { (index < items.length - 1) && <Separator /> }
              </li>
            );
          })
        }
      </ol>
    </nav>
  );
};
