import { ChangeEvent, useEffect, useState } from 'react';
import { usePrevious } from '../../hooks/use-previous';

export function toHMString(minutes: number, separator = ':'): string {
  if (minutes) {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${h}${separator}${m < 10 ? 0 : ''}${m}`;
  }
  return '';
}

function convertDotNotation(value: string): string {
  value = value.replace(',', '.');
  if (value.indexOf('.') >= 0) {
    return toHMString(60 * parseFloat(value));
  }
  return value;
}

function convertToMinutes(value: string): number {
  value = convertDotNotation(value);
  const match = (value || '').match(/^(\d\d?)(?:[:hH]?(\d\d))?$/);
  if (match) {
    const h = parseInt(match[1]) || 0;
    const m = parseInt(match[2]) || 0;
    return 60 * h + m;
  }
  return 0;
}

function roundMinutes(minutes: number): number {
  minutes = Math.max(minutes, 0);
  minutes = Math.min(minutes, 24 * 60);
  minutes =  minutes - (minutes % 30);
  return minutes;
}

type Props = {
  onChange: (value: number) => void;
  value: number;
  disabled?: boolean;
}

export const TimeSelect = ({ onChange, value: initialValue, disabled }: Props) => {
  const [value, setValue] = useState<number>(initialValue);
  const [text, setText] = useState<string>(() => toHMString(initialValue));
  const previous = usePrevious(value);

  useEffect(() => {
    if (value !== previous) {
      onChange(value);
    }
  }, [onChange, value, previous]);

  useEffect(() => {
    setText(current => {
      if (convertToMinutes(current) === initialValue) {
        return current; // do not update text to let user value visible and to not disturb him, because initial value may have changed because of the user input
      }
      return toHMString(initialValue);
    });
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value || '';
    setText(newValue);
    const minutes = convertToMinutes(newValue);
    setValue(minutes);
  };

  const handleBlur = () => {
    const rounded = roundMinutes(value);
    setValue(rounded);
    setText(toHMString(rounded));
  };

  return (
    <input type="text" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-7 sm:text-sm border-gray-300 disabled:border-none disabled:shadow-none rounded-md text-center" placeholder={disabled ? '' : '8:00'} disabled={disabled} value={text} onChange={handleChange} onBlur={handleBlur}/>
  );
};
