import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { getRoleLabel } from '../../../casl/roles-label';
import { adminService } from '../../../services/admin.service';
import { Spinner } from '../../general/Spinner';
import { Table, TableColumn } from '../../general/Table';
import { IAdmin } from '../../../interfaces/admin.interface';
import { IAdminProject } from '../../../interfaces/admin-project.interface';
import { CardAvatarName } from '../../general/CardAvatarName';
import { IUser } from '../../../interfaces/user.interface';
import { formatPhone } from '../../../utils/format-phone-number';

type Props = {
    project: IAdminProject;
    className?: string;
}

export const ProjectExpertList = ({ project, className }: Props) => {
  const [admins, setAdmins] = useState<IAdmin[]>([]);

  useEffect(() => {
    Promise
      .all(project.expertIds.map(expertId => adminService.get(expertId)))
      .then(setAdmins);
  }, [project]);

  const columns = useMemo<Array<TableColumn<IAdmin>>>(
    () => [
      {
        Header: 'Nom',
        accessor: admin => `${admin.lastname} ${admin.firstname}`,
        Cell: ({ row: { original: admin } }: CellProps<IUser, string>) => <CardAvatarName user={admin} link={`/admins/${admin.id}`} />,
        disableSortBy: true
      },
      { Header: 'Fonction', accessor: 'job' },
      { Header: 'Email', accessor: 'email', disableSortBy: true },
      { Header: 'Téléphone', accessor: admin => formatPhone(admin.phone), disableSortBy: true },
      { Header: 'Type', accessor: admin => getRoleLabel(admin.role) },
    ],
    []
  );

  if(!admins) {
    return <Spinner />;
  }

  return <Table columns={columns} data={admins} className={className} />;
};
