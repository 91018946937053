import { useContext, useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { userService } from '../../services/user.service';
import { Spinner } from './Spinner';
import { Table, TableColumn } from './Table';
import { getRoleLabel } from '../../casl/roles-label';
import { IUser } from '../../interfaces/user.interface';
import { IProject } from '../../interfaces/project.interface';
import { CardAvatarName } from './CardAvatarName';
import { AccountContext } from '../contexts/account.context';
import { Action } from '../../casl/enums';
import { formatPhone } from '../../utils/format-phone-number';
import { OpenIconLink } from './OpenIconLink';

type Props = {
  project: IProject;
  className?: string;
}

export const ProjectUserList = ({ project, className }: Props) => {
  const [users, setUsers] = useState<IUser[]>();
  const { isAdmin, getUser } = useContext(AccountContext);
  const showUserLink = isAdmin || userService.can(Action.List, getUser());

  useEffect(() => {
    Promise
      .all(project.userIds.map(userId => userService.get(userId)))
      .then(setUsers);
  }, [project]);

  const columns = useMemo<Array<TableColumn<IUser>>>(
    () => [
      {
        Header: 'Nom',
        accessor: user => `${user.lastname} ${user.firstname}`,
        Cell: ({ row: { original: user } }: CellProps<IUser>) =>
          <CardAvatarName user={user} link={isAdmin ? `/clients/${project.clientId}/users/${user.id}` : (showUserLink ? `/users/${user.id}` : '')} />,
        disableSortBy: true
      },
      { Header: 'Type', accessor: user => getRoleLabel(user.role) },
      { Header: 'Fonction', accessor: 'job' },
      { Header: 'Email', accessor: 'email', disableSortBy: true },
      { Header: 'Téléphone',
        accessor: 'phone', Cell : ({ value }) => formatPhone(value), disableSortBy: true },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({ row: { original: user } }: CellProps<IUser>) => showUserLink ? <OpenIconLink to={isAdmin ? `/clients/${project.clientId}/users/${user.id}` : `/users/${user.id}`} title="Voir le profil" /> : ''
      },
    ],
    [project.clientId, isAdmin, showUserLink]
  );

  if (!users) {
    return <Spinner />;
  }

  return <Table columns={columns} data={users} className={className} />;
};
