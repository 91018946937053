import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { Table, TableCell, TableColumn } from '../../general/Table';
import { Page } from '../../general/Page';
import { formatToFrench } from '../../../utils/timeslot-dates';
import { ITimeslotStatus, ITimeslotSummary, timeslotService } from '../../../services/timeslot.service';
import { Spinner } from '../../general/Spinner';
import { TeamTimeslotSummary } from '../../general/TeamTimeslotSummary';
import { Box } from '../../general/Box';
import { TimeslotSummaryBadge } from '../../general/TimeslotSummaryBadge';
import { Action } from '../../../casl/enums';
import { TextLink } from '../../general/TextLink';
import { OpenIconLink } from '../../general/OpenIconLink';

export const TimeslotSummaryPage = () => {
  const [status, setStatus] = useState<ITimeslotStatus>();

  useEffect(() => {
    timeslotService
      .getStatus()
      .then(setStatus)
      .catch(err => console.error(err));
  }, []);

  const canValidate = timeslotService.can(Action.Update, {}, 'validated');

  const columns = useMemo<Array<TableColumn<ITimeslotSummary>>>(
    () => [
      {
        Header: 'Période',
        Cell: ({ row: { original: summary } } : TableCell<ITimeslotSummary>) => (
          <TextLink to={`/timeslots/view?date=${summary.from}`} className='text-sm font-medium'>
            <span>du {formatToFrench(summary.from)} au {formatToFrench(summary.to)}</span>
          </TextLink>
        ),
      },
      {
        Header: 'Status',
        Cell: ({ row: { original: summary } } : TableCell<ITimeslotSummary>) => <TimeslotSummaryBadge summary={summary} asManager={canValidate}  />
      },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({ row: { original: summary } }: CellProps<ITimeslotSummary>) => <OpenIconLink to={`/timeslots/view?date=${summary.from}`} title="Voir la fiche" />
      },
    ],
    [canValidate]
  );

  if (!status) {
    return <Spinner />;
  }

  return (
    <Page>
      <Table columns={columns} data={status.summaries} pageSize={10} />
      {
        status.team && (
          <Box title="Les fiches de votre équipe">
            <TeamTimeslotSummary team={status.team} />
          </Box>
        )
      }
    </Page>
  );
};
