import {  useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  name: string;
  defaultValue?: Result;
  control: Control<any>;
}

type Result = {
  comment?: string;
}

export const InputField = ({ name, defaultValue, control }: Props) => {
  const commentId = `${name}__comment_`;

  const initialValue = useMemo(() => defaultValue || {}, [defaultValue]);

  return (
    <Controller
      name={name}
      defaultValue={initialValue}
      control={control}
      render={({ field: { onChange, value: data } }) => (
         <textarea className="flex w-full mx-2" rows={3} id={commentId} name={name} defaultValue={initialValue.comment} onChange={(e) => onChange({ ...data, comment: e.target.value || undefined })}/>
      )}
    />
  );
};
