import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { FormField } from '../../general/form/Form';
import { BoxForm } from '../../general/BoxForm';
import { invoiceService } from '../../../services/invoice.service';
import { getPaymentTypeLabel, getPaymentTypeList } from '../../../enums/payment-type.enum';
import { IInvoice } from '../../../interfaces/invoice.interface';

type Props = {
  missionId?: string;
  invoice?: IInvoice;
}

export const InvoiceForm = ({ missionId, invoice }: Props) => {
  const history = useHistory();

  const fields: FormField[] = [
    { name: 'id', type: 'hidden', defaultValue: invoice?.id },
    { name: 'missionId', type: 'hidden', defaultValue: missionId || invoice?.missionId },
    { name: 'provisionalDate', label: 'Date prévisionnelle de facturation', type: 'date', defaultValue: invoice?.provisionalDate || '' },
    { name: 'ready', label: 'Facturable ?', type: 'select', options: [{ label: 'Oui', value: '1' }, { label: 'Non', value: '0' }], defaultValue: invoice?.ready ? '1' : '0' },
    { name: 'information', label:'Informations complémentaires', type: 'textarea', defaultValue: invoice?.information || '', cols: 8 },
    { name: 'billingDate', label: 'Date de Facturation', type: 'date', defaultValue: invoice?.billingDate || '' },
    { name: 'number', label: 'Numéro', type: 'text', defaultValue: invoice?.number || '' },
    { name: 'amountCts', label: 'Montant H.T.', type: 'currency', defaultValue: invoice?.amountCts },
    { name: 'deadline', label: 'Échéance', type: 'date', defaultValue: invoice?.deadline || '' },
    {
      name: 'paymentType',
      label: 'Mode de paiement',
      type: 'select',
      options: [{ label: '', value: '' }].concat(getPaymentTypeList().map(value => ({ label: getPaymentTypeLabel(value), value }))),
      defaultValue: invoice?.paymentType || ''
    },
    { name: 'lastPaymentClientReminder', label: 'Date de la dernière relance client', type: 'date', defaultValue: invoice?.lastPaymentClientReminder || '' },
    { name: 'paidDate', label: 'Date de paiement par le client', type: 'date', defaultValue: invoice?.paidDate || '' },
  ];

  const submit = useCallback((data: any) => {
    return invoiceService
      .upsert({
        ...data,
        ready: data.ready === '1',
        amountCts: data.amountCts ?? null,
        paymentType: data.paymentType || null,
      })
      .then((invoice) => {
        history.push(`/clients/${invoice.clientId}/invoices/${invoice.id}`);
      });
  }, [history]);

  return (
    <BoxForm
      title={invoice ? "Modification d'une facture" : "Création d'une nouvelle facture"}
      fields={fields}
      submit={submit}
      labelOk={invoice ? 'Modifier' : 'Créer'}
      backOnCancel
    />
  );
};
