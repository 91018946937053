import { Link, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import { adminService } from '../../../../services/admin.service';
import { missionService } from '../../../../services/mission.service';
import { userService } from '../../../../services/user.service';
import { clientService } from '../../../../services/client.service';
import { projectService } from '../../../../services/project.service';
import { TextLink } from '../../TextLink';
import { AccountContext } from '../../../contexts/account.context';
import { adminProjectService } from '../../../../services/admin.project.service';
import { invoiceService } from '../../../../services/invoice.service';

type Item = {
    label: string;
    url: string;
}

const translations: {[key: string]: string} = {
  admins: 'Administrateurs',
  'advanced-search': 'Recherche avancée',
  'time-entries': 'Pointages',
  credentials: 'Identifiant',
  users: 'Utilisateurs',
  clients: 'Clients',
  missions: 'Missions',
  profile: 'Profil',
  projects: 'Projets',
  timeslots: 'Fiches de temps',
  worklog: 'Cahier de suivi',
  invoices: 'Factures',
};

function isMongoId(id: string) {
  return /^[a-f0-9]{24}$/.test(id);
}

async function getLabel(isAdmin: boolean, subject: string, id: string): Promise<string> {
  if (subject === 'users') {
    const user = await userService.get(id);
    return `${user.firstname} ${user.lastname}`;
  }
  if (subject === 'clients') {
    const client = await clientService.get(id);
    return client.name;
  }
  if (subject === 'projects') {
    const project = await (isAdmin ? adminProjectService.get(id) : projectService.get(id));
    return project.name;
  }
  if (subject === 'admins') {
    const admin = await adminService.get(id);
    return `${admin.lastname} ${admin.firstname}`;
  }
  if (subject === 'missions') {
    const mission = await missionService.get(id);
    return mission.name;
  }
  if (subject === 'invoices') {
    const invoice = await invoiceService.get(id);
    return invoice.number || '(sans numéro)';
  }
  return id;
}

async function forgeItems(isAdmin: boolean, route: string): Promise<Item[]> {
  // remove last part if it is an action verb
  route = (route || '/')
    .replace(/\/(?:edit|create|view)$/g, '')
    .replace(/\/$/, '');
  const parts = route.split('/');

  return Promise.all(
    parts
      .map(async (label, index) => {
        if (isMongoId(label)) {
          label = await getLabel(isAdmin, parts[index - 1], label);
        }
        if (translations[label]) {
          label = translations[label];
        }
        return {
          label: label,
          url: parts.slice(0, index + 1).join('/')
        };
      })
  );
}

export const Breadcrumb = () => {
  const { isAdmin } = useContext(AccountContext);
  const location = useLocation();
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    forgeItems(isAdmin, location.pathname).then(setItems);
  }, [isAdmin, location]);

  return (
    <nav className="hidden bg-white border-b border-gray-200 lg:flex h-11" aria-label="Breadcrumb">
      <ol className="max-w-none w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
        {
          items.map(item => (
            <li className="flex" key={item.url}>
              <div className="flex items-center">
                {
                  item.label ?
                    <>
                      <svg className="flex-shrink-0 w-6 h-full text-gray-200" preserveAspectRatio="none" viewBox="0 0 24 44" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                      </svg>
                      <TextLink to={item.url} className='ml-4 text-sm font-medium'>{ item.label }</TextLink>
                    </>
                    :
                    <Link to='/' className="text-gray-500 hover:text-gray-600 h-44px">
                      <i className="fas fa-home fa-1x flex-shrink-0 h-5 w-5" />
                      <span className="sr-only">Accueil</span>
                    </Link>
                }
              </div>
            </li>
          ))
        }
      </ol>
    </nav>
  );
};
