import { Children, FC, isValidElement, ReactElement, ReactNode } from 'react';

export class ChildManager {
  private elements: ReactElement[];

  constructor(children: ReactNode | ReactNode[]) {
    this.elements = Children.toArray(children).filter(isValidElement);
  }

  filter(type: FC<any>): ReactElement[] {
    return this.elements.filter(child => child.type === type);
  }

  extract(index: number, type?: FC): ReactElement | undefined {
    if (index < 0) {
      index += this.elements.length;
    }
    if (!type || this.elements[index]?.type === type) {
      return this.elements.splice(index, 1).shift();
    }
  }

  findAndExtract(type: FC): ReactElement | undefined {
    const item = this.elements.find(child => child.type === type);
    if (item) {
      this.elements = this.elements.filter(child => child !== item);
    }
    return item;
  }

  children() {
    return this.elements.slice();
  }

  length() {
    return this.elements.length;
  }
}
