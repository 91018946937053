
export enum MonitoringFinancingStatus {
  Complete = 'complete',
  Received = 'received',
  Requested = 'requested',
  NotRequested = 'not_requested',
}

export const MonitoringFinancingStatusList: MonitoringFinancingStatus[] = [MonitoringFinancingStatus.NotRequested, MonitoringFinancingStatus.Requested, MonitoringFinancingStatus.Received, MonitoringFinancingStatus.Complete];

export const MonitoringFinancingStatusLabels : {[ status in MonitoringFinancingStatus ]: string} = {
  [MonitoringFinancingStatus.Complete]: 'Complet',
  [MonitoringFinancingStatus.Received]: 'Reçu',
  [MonitoringFinancingStatus.Requested]: 'Demandé',
  [MonitoringFinancingStatus.NotRequested]: 'Non demandé',
};

export function getMonitoringFinancingStatusLabel(status: MonitoringFinancingStatus): string {
  return MonitoringFinancingStatusLabels[status];
}
