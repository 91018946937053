import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { AccountContext } from '../../contexts/account.context';
import { Page } from '../../general/Page';
import { UserForm } from '../../general/UserForm';

export const MyProfileFormPage = () => {
  const history = useHistory();
  const { getUser } = useContext(AccountContext);
  const user = getUser();

  const onSave = useCallback(() => {
    history.push('/profile');
  }, [history]);

  return (
    <Page>
      <UserForm clientId={user.clientId} user={user} onSave={onSave}/>
    </Page>
  );
};
