import { ProjectCategory } from '../enums/project-category.enum';
import { IAdminProject } from '../interfaces/admin-project.interface';

/**
 * Sort project by start date desc and limit the result to 6
 * (if project is of type 'autre' push it to the end)
 * @param projects
 */
export function sortProjectsAndLimit(projects: IAdminProject[]): IAdminProject[] {
  return projects
    .sort((a, b) => {
      if (a.category === ProjectCategory.Autre && b.category !== ProjectCategory.Autre) {
        return 1;
      } else if (b.category === ProjectCategory.Autre) {
        return 1;
      }
      return a.start > b.start ? -1 : 1;
    })
    .slice(-6);
}
