import { Aside, Page } from '../../general/Page';
import { ProjectForm } from '../components/ProjectForm';
import { ProjectTreeBox } from '../../general/ProjectTreeBox';
import { useAdminProject } from '../../../hooks/use-project';
import { Spinner } from '../../general/Spinner';
import { NotFound } from '../../general/NotFound';

type Props = {
    projectId: string;
}

export const ProjectFormPage = ({ projectId }: Props) => {
  const { project, loading } = useAdminProject(projectId);

  if (loading) {
    return <Spinner />;
  }

  if (!project) {
    return <NotFound title="Projet introuvable" />;
  }

  return (
    <Page>
      <Aside>
        <ProjectTreeBox project={project} />
      </Aside>
      <ProjectForm project={project} />
    </Page>
  );
};
