import clsx from 'clsx';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';

import { IClientAgreement } from '../../../interfaces/client-agreement.interface';
import { Box } from '../../general/Box';
import { DateInput } from '../../general/form/components/DateInput';
import { FormErrorsHandler } from '../../general/form/components/FormErrorsHandler';
import { NoData } from '../../general/NoData';
import { ButtonBar } from '../../general/buttons/ButtonBar';

export const AgreementInput = ({ name, title, error, control, register, agreementValue } : { name: string, title: string,  error: any, control: Control<any>, register: UseFormRegister<any>, agreementValue: IClientAgreement[] | undefined }) => {
  const { fields, append, remove } = useFieldArray({ control, name });
  const defaultValues: IClientAgreement = {
    name: '',
    signed: '',
    duration: 0,
  };
  return (
    <Box title={title} className="mt-6">
      <div className="ml-4 px-6 grid grid-cols-8 gap-3">
        <div className = "col-span-3 text-left text-xs font-medium text-gray-500">Nature de la lettre<span className="text-sm text-red-600"> *</span></div>
        <div className = "col-span-2 text-left text-xs font-medium text-gray-500">Date de signature<span className="text-sm text-red-600"> *</span></div>
        <div className = "col-span-2 text-left text-xs font-medium text-gray-500">Engagement (en mois)<span className="text-sm text-red-600"> *</span></div>
      </div>

      <div className="grid grid-flow-row auto-rows-auto gap-4">
        {fields.map((field, index) => {
          const fieldErrors = error?.[index] || undefined;
          const fieldValue = agreementValue?.[index] || { ...defaultValues };

          return (
            <div key={field.id} className={clsx('ml-4 px-6 grid grid-cols-8 gap-3', index % 2 ? 'bg-gray-50' : 'bg-white')}>
              <div className="col-span-3">
                <input type="text" {...register(`${name}.${index}.name` as const, { required: true })} defaultValue={fieldValue.name} />
                <FormErrorsHandler error={fieldErrors?.name} />
              </div>
              <div className="col-span-2">
                <DateInput field={{ type: 'date', name: `${name}.${index}.signed`, defaultValue: fieldValue.signed, label: 'Date de signature', registerOptions: { required: true } }} control={control} />
                <FormErrorsHandler error={fieldErrors?.signed} />
              </div>
              <div className="col-span-2">
                <input type="text" {...register(`${name}.${index}.duration` as const, { validate: {
                     NumberOnly: (value) => value.length > 0 ? /^[0-9]{0,3}$/.test(value) : undefined
                  } })} defaultValue={fieldValue.duration} />
                <FormErrorsHandler error={fieldErrors?.duration} />
              </div>
              <div className="col-span-1">
                <p className="inline-flex items-center px-2 py-1.5 md:px-12 md:py-2 text-sm font-medium text-red-700 cursor-pointer" onClick={() => remove(index)}><i className="fas fa-trash-alt fa-1x"/></p>
              </div>
            </div>
          );
        })}
        { fields.length === 0 && <NoData>Aucune lettre de mission signée</NoData> }
      </div>
      <ButtonBar>
        <button className="btn" type="button" onClick={() => append({ ...defaultValues })}>
          <i className="fas fa-plus fa-1x"/>
          <span className="hidden md:flex pl-0.5 text-xs">Ajouter une lettre</span>
        </button>
      </ButtonBar>
    </Box>
  );
};
