import { Spinner } from '../../general/Spinner';
import { MainError } from '../../general/MainError';
import { UserList } from '../../general/UserList';
import { Box } from '../../general/Box';
import { Page } from '../../general/Page';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { AddButton } from '../../general/buttons/AddButton';
import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';
import { EditButton } from '../../general/buttons/EditButton';
import { useClient } from '../../../hooks/use-client';
import { ClientDetails } from '../components/ClientDetails';
import { MissionList } from '../components/MissionList';
import { ProjectList } from '../components/ProjectList';
import { WorklogList } from '../components/WorklogList';
import { ExportBox } from '../components/ExportBox';
import { Tab, Tabs } from '../../general/Tabs';
import { TeamTimeslotStatus } from '../components/TeamTimeslotStatus';
import { InvoiceList } from '../components/InvoiceList';
import { missionService } from '../../../services/mission.service';
import { invoiceService } from '../../../services/invoice.service';
import { projectService } from '../../../services/project.service';
import { timeslotService } from '../../../services/timeslot.service';
import { worklogService } from '../../../services/worklog.service';

type Props = {
  clientId: string;
}

export const ClientPage = ({ clientId }: Props) => {
  const { loading, client } = useClient(clientId);

  if (loading) {
    return <Spinner />;
  }

  if (!client) {
    return <MainError>Ce client n&apos;a pas été trouvé</MainError>;
  }

  return (
    <Page>
      <Box title={client.name} transparent main>
        <Tabs>
          <Tab title="Détails" name='details'>
            <ButtonBar>
              <Can I={Action.Update} a={client} as={Subject.Client}>
                <EditButton to={`/clients/${client.id}/edit`} />
              </Can>
            </ButtonBar>
            <ClientDetails clientId={client.id} />
          </Tab>

          <Tab title="Utilisateurs" name='users'>
            <ButtonBar>
              <Can I={Action.Create} a={Subject.User}>
                <AddButton to={`/clients/${client.id}/users/create`} label='Ajouter un utilisateur' />
              </Can>
            </ButtonBar>
            <UserList clientId={client.id} />
          </Tab>

          { projectService.can(Action.List) && (
            <Tab title="Projets" name='projects'>
              <ProjectList clientId={client.id} />
            </Tab>
          ) }

          { timeslotService.can(Action.List) && (
            <Tab title='Fiches de temps' name='timeslots'>
              <TeamTimeslotStatus clientId={client.id} />
            </Tab>
          ) }

          { worklogService.can(Action.Read) && (
            <Tab title="Cahiers de suivi" name='worklogs'>
              <WorklogList clientId={client.id}/>
            </Tab>
          ) }

          { timeslotService.can(Action.List) && (
            <Tab title='Exports' name='exports'>
              <ExportBox clientId={client.id}/>
            </Tab>
          ) }

          { missionService.can(Action.List) && (
            <Tab title="Missions" name='missions'>
              <ButtonBar>
                <Can I={Action.Create} a={Subject.Mission}>
                  <AddButton to={`/clients/${client.id}/missions/create`} label="Créer une mission" />
                </Can>
              </ButtonBar>
              <MissionList clientId={client.id} showEnded={true} />
            </Tab>
          ) }

          { invoiceService.can(Action.List) && (
            <Tab title="Factures" name='invoices'>
              <InvoiceList clientId={client.id}  emptyMsg="Aucune facture"/>
            </Tab>
          ) }

        </Tabs>
      </Box>

    </Page>
  );
};
