import { RegisterFunction } from './types';

export type FormFieldHidden = {
  id?: string;
  name: string;
  defaultValue: any;
  type: 'hidden';
}

export const Hidden = ({ field, register } : { field: FormFieldHidden, register: RegisterFunction }) => (
  <input
    type="hidden"
    id={field.id || field.name}
    defaultValue={field.defaultValue}
    {...register(field.name)}
  />
);
