import { useContext, useEffect, useMemo, useState } from 'react';

import { Page } from './Page';
import { getDays, getBoundaries, getWeekNumber, getNow, addToYMD, formatToShortFrench } from '../../utils/timeslot-dates';
import { projectService } from '../../services/project.service';
import { Spinner } from './Spinner';
import { ConfirmationPopup } from './ConfirmationPopup';
import { TimeslotTable, useTimeslotTable } from './TimeslotTable';
import { ITimeslotSummary, timeslotService } from '../../services/timeslot.service';
import { ITimeslot } from '../../interfaces/timeslot.interface';
import { showToast } from './show-toast';
import { toHMString } from './TimeSelect';
import { useQuery } from '../../hooks/use-query';
import { TimeslotSummaryBadge } from './TimeslotSummaryBadge';
import { Action } from '../../casl/enums';
import { IProject } from '../../interfaces/project.interface';
import { ToggleButton } from './buttons/ToggleButton';
import { UserTitleCard } from './UserTitleCard';
import { AccountContext } from '../contexts/account.context';
import { adminProjectService } from '../../services/admin.project.service';
import { useUser } from '../../hooks/use-user';

function sortProjects(projects: IProject[], parentId?: string): IProject[] {
  const roots = projects
    .filter(project => project.parentId === parentId)
    .sort((a, b) => {
      if (a.isTask && !b.isTask) {
        return -1;
      }
      if (!a.isTask && b.isTask) {
        return 1;
      }
      if (a.start !== b.start) {
        return a.start < b.start ? -1 : 1;
      }
        return a.name < b.name ? -1 : 1;
    });

  return ([] as IProject[]).concat(...roots.map(project => [project, ...sortProjects(projects, project.id)]));
}

type Props = {
  clientId?: string;
  userId?: string;
};

type DataState = {
  timeslots?: ITimeslot[];
  summary?: ITimeslotSummary;
};

export const TimeslotPage = ({ clientId, userId }: Props) => {
  const { isAdmin, account  } = useContext(AccountContext);
  const { user } = useUser(userId || account.id);
  const [unlock, setUnlock] = useState(false);
  const [weekEndVisible, setWeekEndVisible] = useState(false);
  const [projects, setProjects] = useState<IProject[]>();
  const { ref, isDirty, duration } = useTimeslotTable();
  const [{ timeslots, summary }, setData] = useState<DataState>({});
  const [postponedAction, setPostponedAction] = useState<keyof typeof handlers>('');
  const { query, updateQuery } = useQuery();
  const date = query.get('date');
  const boundaries = useMemo(() => getBoundaries(date || undefined), [date]);

  const now = getNow();
  const days = useMemo(() => getDays(boundaries.from, boundaries.to), [boundaries]);

  useEffect(() => {
    (isAdmin ? adminProjectService : projectService)
      .getList(isAdmin ? { clientId, userId } : userId ? { userId } : { assignedOnly : true } )
      .then(projects => {
        setProjects(sortProjects(projects));
      })
      .catch(err => console.error(err));
  }, [isAdmin, userId]);

  useEffect(() => {
    setData({});
    timeslotService
      .getList({ from: boundaries.from, to: boundaries.to, userId, clientId })
      .then(response => {
        // ensure boundaries didn't change while loading the timeslots
        if (response.from === boundaries.from) {
          setUnlock(false);
          setData({
            timeslots: response.timeslots,
            summary: response.summaries[0]
          });
        }
      })
      .catch(err => console.error(err));
  }, [boundaries, userId]);

  const hasDataOnWeekEnd = (timeslots: ITimeslot[]) => {
    const weekend = days.slice(-2).map(day => day.date);
    return timeslots.some(timeslot => weekend.includes(timeslot.date));
  };

  if (!projects || !timeslots || !summary || !user) {
    return <Spinner />;
  }

  const handlers = {
    previousWeek: () => updateQuery({ date: addToYMD(boundaries.from, -7) }),
    nextWeek: () => updateQuery({ date: addToYMD(boundaries.from, 7) }),
    cancel: () => {
      ref.current?.reset();
      setUnlock(false);
    },
    '': () => 0
  };

  function checkDirty(name: keyof typeof handlers) {
    return function () {
      if (isDirty) {
        setPostponedAction(name);
      } else {
        handlers[name]();
      }
    };
  }

  function proceedToPostponedAction() {
    handlers[postponedAction]();
    resetConfirmation();
  }

  function resetConfirmation() {
    setPostponedAction('');
  }

  function save(options: { draft?: boolean; validated?: true }) {
    const timeslots = ref.current?.getValues() || [];
    timeslotService
      .upsertMany({
        userId,
        from: boundaries.from,
        to: boundaries.to,
        timeslots,
        ...options
      })
      .then(response => {
        setData({
          timeslots: response.timeslots,
          summary: response.summaries[0]
        });
        setUnlock(false);
        showToast({ type: 'success' });
      })
      .catch(error => {
        showToast({ error });
      });
  }

  const canUpdate = timeslotService.can(Action.Update, { userId: userId || account.id }, 'duration');
  const canValidate = timeslotService.can(Action.Update, { userId: userId || account.id, validated: true } , 'validated');

  const enableCancel = isDirty || unlock;
  const enableSubmit = isDirty || (!canValidate && (summary.draft || summary.incomplete)) || (canValidate && timeslots.length && !summary.validated);

  return (
    <Page>
      <UserTitleCard user={user} />
      <div className="flex justify-start relative">
        <button className="btn" onClick={checkDirty('previousWeek')}><i className="fas fa-caret-left" /></button>
        <span className="flex items-center bg-white border rounded px-5 mx-3 text-xs text-gray-600">SEMAINE {getWeekNumber(boundaries.from)}</span>
        <button className="btn" onClick={checkDirty('nextWeek')} disabled={ boundaries.to >= now }><i className="fas fa-caret-right" /></button>

        {
          !summary.free && <>
            <ToggleButton className="mx-3" onChange={setWeekEndVisible} initialValue={hasDataOnWeekEnd(timeslots)}>
              <span className="ml-2 text-sm font-medium text-gray-500 group-hover:text-gray-600">Week-End</span>
            </ToggleButton>

            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
              <TimeslotSummaryBadge summary={summary} asManager={canValidate} />
              <span className="text-gray-500 sm:text-sm px-1 ml-3">Total:</span>
              <span className="text-green-500 sm:text-sm">{toHMString(duration, 'h') || '0h'}</span>
            </div>
          </>
        }
      </div>

      {
        summary.free ? (
          <div className="flex-1 flex justify-center items-center">
            <span className="px-24 py-12 border rounded-xl italic text-gray-500 text-center">
              Semaine sans affectation <br />
              <span className='text-xs text-gray-400'>(du {formatToShortFrench(boundaries.from)} au {formatToShortFrench(boundaries.to)})</span>
            </span>
          </div>
        )
          :
          <>
            <TimeslotTable ref={ref} days={days} user={user} projects={projects} timeslots={timeslots} disabled={!canUpdate || summary.validated && !unlock} weekEndVisible={weekEndVisible} />

            {
              canUpdate && (
                <div className="flex justify-end">
                  {
                    summary.validated && !unlock ? <>
                      {
                        canValidate && <button className="btn ml-3" onClick={() => setUnlock(true)}>Modifier</button>
                      }
                    </> :
                      <>
                        <button className="btn btn-cancel ml-3" disabled={!enableCancel} onClick={checkDirty('cancel')}>Annuler</button>
                        {
                          !summary.validated && !summary.submitted && <button className="btn btn-secondary ml-3" disabled={!isDirty} onClick={() => save({ draft: true })}>Enregistrer</button>
                        }
                        <button className="btn btn-primary ml-3" disabled={!enableSubmit} onClick={() => save(canValidate ? { validated: true } : { draft: false })}>
                          {
                            canValidate ? (summary.validated ? 'Mettre à jour' : 'Valider') : (summary.submitted ? 'Mettre à jour' : 'Faire valider')
                          }
                        </button>
                      </>
                  }
                </div>
              )
            }

          </>
      }

      { Boolean(postponedAction) && (
        <ConfirmationPopup title='Attention !' onNo={resetConfirmation} onYes={proceedToPostponedAction}>
          <p className="text-sm text-gray-500">
            Des modifications n&apos;ont pas été enregistrées, êtes-vous sûr de vouloir continuer et perdre ces modifications ?
          </p>
        </ConfirmationPopup>
      )}
    </Page>
  );
};
