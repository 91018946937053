import { useMemo } from 'react';

import { getClientFunctionLabel } from '../../enums/client-function.enum';

import { IClientContact } from '../../interfaces/client-contact.interface';
import { IClient } from '../../interfaces/client.interface';
import { NoData } from './NoData';
import { Table, TableColumn } from './Table';
import { formatPhone } from '../../utils/format-phone-number';

type Props = {
  client: IClient;
}

export const ClientContactList = ({ client }: Props) => {
  const columns = useMemo<Array<TableColumn<IClientContact>>>(
    () => [
      { Header: 'Fonction', accessor: contact => getClientFunctionLabel(contact.role) },
      { Header: 'Nom', accessor: 'lastname' },
      { Header: 'Prénom', accessor: 'firstname' },
      { Header: 'Email', accessor: 'email', disableSortBy: true },
      { Header: 'Téléphone', accessor: contact => formatPhone(contact.phone), disableSortBy: true },
    ],
    []
  );

  if (!client.contacts) {
    return <NoData>Aucun contact</NoData>;
  }

  return <Table columns={columns} data={client.contacts} />;
};
