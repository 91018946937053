export enum PaymentType {
  BankTransfert = 'bank_transfert',
  BankWithdrawal = 'bank_withdrawal',
  Another = 'another',
}

export const getPaymentTypeList = () => Object.values(PaymentType);

export const PaymentTypeLabels: {[type in PaymentType]: string} = {
  [PaymentType.BankTransfert]: 'Virement',
  [PaymentType.BankWithdrawal]: 'Prélèvement',
  [PaymentType.Another]: 'Autre',
};

export function getPaymentTypeLabel(type: PaymentType): string {
  return PaymentTypeLabels[type];
}
