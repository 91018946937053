import { subject } from '@casl/ability';

import { apiService } from './api.service';
import { ICreateTimeslot, ITimeslot } from '../interfaces/timeslot.interface';
import { buildQueryString } from '../utils/build-query-string';
import { abilityService } from './ability.service';
import { Action, Subject } from '../casl/enums';

interface IUpdateManyTimeslots {
  userId?: string;
  from: string;
  to: string;
  draft?: boolean;
  validated?: boolean;
  timeslots: ICreateTimeslot[];
}

interface ITimeslotsResponse {
  from: string;
  to: string;
  timeslots: ITimeslot[];
  summaries: ITimeslotSummary[];
}

export interface IUserTimeslotSummary {
  userId: string;
  summaries: ITimeslotSummary[];
}

export interface ITimeslotStatus {
  summaries: ITimeslotSummary[];
  team?: IUserTimeslotSummary[];
}

export interface IClientTimeslotStatus {
  team: IUserTimeslotSummary[];
}

function upsertMany(dto: IUpdateManyTimeslots): Promise<ITimeslotsResponse> {
  return apiService.put<IUpdateManyTimeslots, ITimeslotsResponse>('timeslots/many', dto);
}

interface IGetListQuery {
  clientId?: string;
  userId?: string;
  from: string;
  to: string;
}

function getList(query: IGetListQuery): Promise<ITimeslotsResponse> {
  return apiService.get<ITimeslotsResponse>(`timeslots?${buildQueryString(query)}`);
}

export interface ITimeslotSummary {
  from: string;
  to: string;
  validated?: boolean;
  submitted?: boolean;
  draft?: boolean;
  incomplete?: boolean;
  free?: boolean;
}

function getStatus(): Promise<ITimeslotStatus> {
  return apiService.get<ITimeslotStatus>('timeslots/status');
}

function getClientStatus(clientId: string): Promise<IClientTimeslotStatus> {
  return apiService.get<IClientTimeslotStatus>(`timeslots/client/${clientId}/status`);
}

function can(action : Action, source?: Partial<ITimeslot>, field?: keyof ITimeslot): boolean {
  const ability = abilityService.getAbility();
  return ability.can(action, source ? subject(Subject.Timeslot, source) : Subject.Timeslot, field);
}

export const timeslotService = {
  can,
  upsertMany,
  getList,
  getStatus,
  getClientStatus,
};
