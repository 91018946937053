import { useHistory } from 'react-router-dom';
import { useCallback, useContext } from 'react';

import { AccountContext } from '../../contexts/account.context';
import { AdminForm } from '../../general/AdminForm';
import { Page } from '../../general/Page';
import { IAdmin } from '../../../interfaces/admin.interface';

export const MyProfileFormPage = () => {
  const { getAdmin, setAccount } = useContext(AccountContext);
  const history = useHistory();
  const onSave = useCallback((admin: IAdmin) => {
    setAccount(admin);
    history.push('/profile');
  }, [history, setAccount]);
  return (
    <Page>
      <AdminForm admin={getAdmin()} onSave={onSave}/>
    </Page>
  );
};
