import { useLocation } from 'react-router-dom';
import { FC, useEffect } from 'react';

export const ScrollToTop: FC = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{children}</>;
};
