import { FormFieldBase, RegisterFunction } from './types';

export type FormFieldCheckboxes = FormFieldBase & {
  type: 'checkboxes';
  onChange?: (value: any) => void;
  options: Array<{label?: string, value: any}>;
  defaultValue?: any[];
  spread?: 1 | 2 | 3 | 4
}

const classBySpread = {
  1: 'col-span-12 sm:col-span-12',
  2: 'col-span-12 sm:col-span-6',
  3: 'col-span-12 sm:col-span-4',
  4: 'col-span-12 sm:col-span-3',
};

export const Checkboxes = ({ field, register } : { field: FormFieldCheckboxes, register: RegisterFunction }) => (
  <div className="w-full grid grid-cols-12 gap-2">
    {
      field.options.map((option, idx) => <div className={`${classBySpread[field.spread || 1]} truncate`} title={option.label || option.value} key={option.value}>
        <input
          id={`${field.name}_${idx}_`}
          type='checkbox'
          value={option.value}
          defaultChecked={field.defaultValue?.includes(option.value)}
          {...register(`${field.name}[]`, field.registerOptions)}
          onChange={e => field.onChange && field.onChange({ value: e.target.value, checked: e.target.checked })}
        />
        <label htmlFor={`${field.name}_${idx}_`} className="ml-2 text-sm text-gray-900">{option.label || option.value}</label>
      </div>
      )
    }
    {
      (field.options.length === 1) && ( // force value as array, else returns a simple string
        <input
          type='checkbox'
          value=''
          className='hidden'
          {...register(`${field.name}[]`, field.registerOptions)}
        />
      )
    }
  </div>
);
