import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AccountContext } from '../../contexts/account.context';
import { showToast } from '../show-toast';
import { Breadcrumb } from './components/Breadcrumb';
import { Notifications } from './components/Notifications';
import { Navigation } from './components/Navigation';
import { MenuButton } from './components/MenuButton';

export const Header = () => {
  const { account } = useContext(AccountContext);

  useEffect(() => {
    function refresh() {
      if (account.passwordToChange) {
        showToast({
          type: 'warn',
          title: 'Problème de sécurité',
          content: (
            <div>
              <p className="whitespace-nowrap">Changement de mot de passe requis</p>
              <p className="flex justify-center mt-2 mr-3"><Link to={`/users/${account.id}/credentials`} className="text-gray-400 text-sm btn">Modifier</Link></p>
            </div>
          ) });
      }
    }

    refresh();
    const timer = setInterval(refresh, 10 * 60 * 1000);

    return () => clearInterval(timer);
  }, [account.passwordToChange]);

  return (
    <div className="fixed max-w-none mx-auto border-l border-r border-gray-200 inset-x-0 top-0 z-10">
      <nav className="bg-primaryPandaColor block" aria-label="Global">
        <div className="max-w-full mx-auto px-0 md:px-2 lg:px-4">
          <div className="flex md:justify-between h-16">
            <Navigation />
            <Notifications />
            <MenuButton />
          </div>
        </div>
      </nav>
      <Breadcrumb />
    </div>
  );
};
