import clsx from 'clsx';

const fileTypes: {[ext: string]: string} = {
  pdf: 'fa-file-pdf',
  doc: 'fa-file-word',
  docx: 'fa-file-word',
  zip: 'fa-file-archive',
  mp4: 'fa-file-video',
  ppt: 'fa-file-powerpoint',
  ppx: 'fa-file-powerpoint',
  xls: 'fa-file-excel',
  xlsx: 'fa-file-excel',
  mp3: 'fa-file-audio',
  wav: 'fa-file-audio',
  jpg: 'fa-file-image',
  jpeg: 'fa-file-image',
  png: 'fa-file-image',
  gif: 'fa-file-image',
  bmp: 'fa-file-image',
  csv: 'fa-file-csv',
};

type Props = {
  filename: string;
  className?: string;
}

export const FileIcon = ({ filename, className }: Props) => {
  const extension = (filename.split('.').pop() || '').toLowerCase();
  const hasSize = (className || '').match(/(^|\s)text-[0-9]?(xs|sm|lg|xl|base)(\s|$)/) || (className || '').match(/(^|\s)fa-\dx(\s|$)/);
  return <i className={clsx('fas', className, fileTypes[extension] || 'fa-file', !hasSize && 'text-3xl')} />;
};
