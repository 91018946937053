import { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { addYears, subYears } from 'date-fns';

import { AccountContext } from '../../contexts/account.context';
import { BoxForm } from '../../general/BoxForm';
import { FormField } from '../../general/form/Form';
import { getProjectCategoryLabel, getProjectCategoryList } from '../../../enums/project-category.enum';
import { projectService } from '../../../services/project.service';
import { useUsers } from '../../../hooks/use-users';
import { Spinner } from '../../general/Spinner';
import { UserRoleProjectManagerList } from '../../../enums/user-role.enum';
import { setFormFieldsDefaultValues } from '../../../utils/set-form-fields-default-values';
import { Subject } from '../../../casl/enums';
import { IProject } from '../../../interfaces/project.interface';
import { fromYMD } from '../../../utils/timeslot-dates';
import { IUser } from '../../../interfaces/user.interface';

function getFields(users: IUser[], parent?: IProject, project?: IProject) {
  let fields: FormField[] = [];

  if (project) {
    fields.push({
      name: 'id',
      type: 'hidden',
      defaultValue: project.id,
    });
  }

  if (parent) {
    fields.push({
      name: 'parentId',
      type: 'hidden',
      defaultValue: parent.id,
    });
  }

  fields.push(
    {
      name: 'category',
      type: 'select',
      label: 'Catégorie',
      registerOptions: { required: true },
      options: getProjectCategoryList().map(category => ({ value: category, label: getProjectCategoryLabel(category) })),
      defaultValue: parent?.category
    },
    {
      name: 'managerId',
      type: 'select',
      label: 'Responsable',
      registerOptions: { required: true },
      options: users
        .filter(user => UserRoleProjectManagerList.includes(user.role))
        .map(user => ({
          value: user.id,
          label: `${user.lastname.toUpperCase()} ${user.firstname}`
        })),
      defaultValue: parent?.managerId
    },
    {
      name: 'name',
      label: 'Nom',
      registerOptions: { required: true }
    },
    {
      name: 'description',
      label: 'Description'
    },
    {
      name: 'start',
      label: 'Date de début',
      type: 'date',
      minDate: parent ? fromYMD(parent.start) : subYears(new Date(), 2),
      maxDate: parent && parent.end ? fromYMD(parent.end) : addYears(new Date(), 2),
      registerOptions: { required: true }
    },
    {
      name: 'end',
      label: 'Date de fin',
      type: 'date',
      minDate: parent ? fromYMD(parent.start) : subYears(new Date(), 2),
      maxDate: parent && parent.end ? fromYMD(parent.end) : addYears(new Date(), 2),
    }
  );

  if (!parent && !project?.parentId) {
    fields.push({
      name: 'userIds',
      label: 'Équipe',
      type: 'checkboxes',
      options: users.map(user => ({ label: `${user.lastname} ${user.firstname}`, value: user.id })).sort( (a, b) => a.label.localeCompare(b.label, 'fr', { ignorePunctuation: true, sensitivity: 'base' })),
      cols: 8,
      spread: 4
    });
  }

  if (project) {
    fields = setFormFieldsDefaultValues(fields, project, Subject.Project);
  }

  return fields;
}

type Props = {
  project?: IProject;
  parent?: IProject;
  isTask: boolean;
}

export const ProjectForm = ({ project, parent, isTask }: Props) => {
  const history = useHistory();
  const { getUser } = useContext(AccountContext);
  const { users } = useUsers(getUser().clientId);
  // while loading users, return an empty array
  const fields = useMemo(() => users ? getFields(users, parent, project) : [], [users, parent, project]);

  const submit = useCallback((data: any) => {
    if (!project) {
      data = {
        ...data,
        isTask
      };
    }
    return projectService
      .upsert(data)
      .then(project => history.push(`/projects/${project.id}`));
  }, [project, history, isTask]);

  if (!users) {
    return <Spinner />;
  }

  let title: string;
  if (project) {
    if (project.isTask) {
      title = 'Modification d\'une tâche';
    } else if (project.parentId) {
      title = 'Modification d\'un sous-projet';
    } else {
      title = 'Modification d\'un projet';
    }
  } else {
    if (isTask) {
      title = 'Création d\'une tâche';
    } else if (parent) {
      title = 'Création d\'un sous-projet';
    } else {
      title = 'Création d\'un projet';
    }
  }

  return (
    <BoxForm
      title={title}
      labelOk={ project ? 'Modifier' : 'Créer' }
      className="md:max-w-none md:mx-5"
      fields={fields} submit={submit} backOnCancel />
  );
};
