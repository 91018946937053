import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AccountContext } from '../contexts/account.context';
import { ITimeslotSummary, IUserTimeslotSummary } from '../../services/timeslot.service';
import { useUser } from '../../hooks/use-user';
import { getWeekNumber } from '../../utils/timeslot-dates';
import { CardAvatarName } from './CardAvatarName';
import { SpinIcon } from './SpinIcon';

type Props = {
  clientId?: string;
  team: IUserTimeslotSummary[];
}

const TimeslotSummary = ({ clientId, userId, summary }: { clientId?: string; userId: string; summary: ITimeslotSummary }) => {
  const { isAdmin } = useContext(AccountContext);
  const colors = {
    red: 'bg-red-100 border-red-300 hover:bg-red-200',
    blue: 'bg-blue-100 border-blue-300 hover:bg-blue-200',
    green: 'bg-green-100 border-green-300 hover:bg-green-200',
    yellow: 'bg-yellow-100 border-yellow-300 hover:bg-yellow-200',
    gray: 'bg-gray-100 border-gray-300 hover:bg-gray-200',
  };

  let cls: string;
  let status: string;

  if (summary.free) {
    cls = colors.gray;
    status = 'HORS PROJET';
  } else if (summary.incomplete) {
    cls = colors.red;
    status = 'INCOMPLETE';
  } else if (summary.draft) {
    cls = colors.yellow;
    status = 'BROUILLON';
  } else if (summary.submitted) {
    cls = colors.blue;
    status = 'EN ATTENTE DE VALIDATION';
  } else {
    cls = colors.green;
    status = 'VALIDÉE';
  }

  return <Link to={`${isAdmin ? `/clients/${clientId}` : ''}/users/${userId}/timeslots?date=${summary.from}`} className={`p-0 mr-1 border rounded-sm ${cls}`} style={{ width: '15px', height: '15px' }} title={`SEMAINE ${getWeekNumber(summary.from)} ${status}`} />;
};

const GlobalTimeslotStatus = ({ summaries }: {summaries: ITimeslotSummary[]}) => {
  let icon: string;
  let color: string;
  let text: string;

  if (summaries.some(summary => summary.incomplete)) {
    icon = 'fa-exclamation-triangle';
    color = 'text-red-600';
    text = 'Fiches incomplètes';
  } else if (summaries.some(timeslot => timeslot.draft)) {
    icon = 'fa-exclamation-triangle';
    color = 'text-orange-500';
    text = 'Fiches non soumises';
  } else if (summaries.some(timeslot => timeslot.submitted)) {
    icon = 'fa-cog';
    color = 'text-orange-500';
    text = 'Fiches à valider';
  } else {
    icon = 'fa-check';
    color = 'text-green-500';
    text = 'A jour';
  }

  return (
    <span className={color}>
      <i className={`fas fas fa-1x mr-1 ${icon}`} />
      <span>{text}</span>
    </span>
  );
};

const UserTimeslotSummary = ({ clientId, userId, summaries }: { clientId?: string; userId: string; summaries: ITimeslotSummary[] }) => {
  const { isAdmin } = useContext(AccountContext);
  const { user } = useUser(userId);
  const summary = summaries.length ? summaries.find(summary => !summary.validated && !summary.free) || summaries[summaries.length - 1] : undefined;
  const link = (user && summary) ? `${isAdmin ? `/clients/${clientId}` : ''}/users/${user.id}/timeslots?date=${summary.from}` : undefined;

  return (
    <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0 hover:bg-blue-50">
      <div className="min-w-0 flex-1 flex items-center">
        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
            { user ? <CardAvatarName user={user} color='purple' showJob={true} link={link} badge={true} /> : <SpinIcon className={'text-gray-400'} /> }
          </div>
          <div>
            <p className="text-sm text-gray-900">
              <GlobalTimeslotStatus summaries={summaries} />
            </p>
            <p className="mt-2 flex items-center text-sm text-gray-500">
              {
                summaries.map(summary => <TimeslotSummary key={summary.from} clientId={clientId} userId={userId} summary={summary} />)
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TeamTimeslotSummary = ({ clientId, team }: Props) => {
  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 bg-white">
      <ul className="border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
        {
          team.map(item => <li key={item.userId}><UserTimeslotSummary clientId={clientId} {...item} /></li>)
        }
      </ul>
    </div>
  );
};
