import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';
import { AvatarProfil } from '../../general/AvatarProfil';
import { Box } from '../../general/Box';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { EditButton } from '../../general/buttons/EditButton';
import { SettingButton } from '../../general/buttons/SettingButton';
import { Page } from '../../general/Page';
import { UserProfile } from '../../general/UserProfile';
import { useUser } from '../../../hooks/use-user';
import { NotFound } from '../../general/NotFound';
import { Spinner } from '../../general/Spinner';

type Props = {
  userId: string;
  clientId: string;
};
export const UserProfilePage = ({ userId, clientId }: Props) => {
  const { user, loading } = useUser(userId);
  if (loading) {
    return <Spinner />;
  }
  if (!user) {
    return <NotFound title="Utilisateur introuvable" />;
  }
  return (
    <Page>
      <Box title="Profil utilisateur">
        <ButtonBar>
          <Can I={Action.Update} this={user} as={Subject.User}>
            <EditButton to={`/clients/${clientId}/users/${userId}/edit`}/>
            <SettingButton to={`/clients/${clientId}/users/${userId}/credentials`} label="Identifiants" />
          </Can>
        </ButtonBar>
        <AvatarProfil user={user}/>
      </Box>
      <UserProfile user={user}/>
    </Page>
  );
};
