import { Box } from '../../general/Box';
import { Page } from '../../general/Page';
import { InvoiceList } from '../components/InvoiceList';

export  const InvoiceListPage = () => {
  return <Page>
    <Box title="Factures non facturable" transparent>
      <InvoiceList ready={false} pageSize={10} emptyMsg="Aucune facture n'est à envoyer."/>
    </Box>
    <Box title="Factures à envoyer" transparent>
      <InvoiceList ready={true} haveBillingDate={false} pageSize={10} emptyMsg="Aucune facture n'est à envoyer."/>
    </Box>
    <Box title="Factures en attente de paiement" transparent>
      <InvoiceList ready={true} haveBillingDate={true} havePaidDate={false} pageSize={10} emptyMsg="Aucune facture n'est en attente du paiment."/>
    </Box>
    <Box title="Factures payées" transparent>
      <InvoiceList havePaidDate={true} pageSize={10} emptyMsg="Aucune facture payée." />
    </Box>
  </Page>;
};
