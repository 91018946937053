import { getRoleLabel } from '../../casl/roles-label';
import { useAdmin } from '../../hooks/use-admin';
import { Box } from './Box';
import { Grid } from './grid/Grid';
import { Item } from './grid/Item';
import { NotFound } from './NotFound';
import { Spinner } from './Spinner';
import { formatPhone } from '../../utils/format-phone-number';

type Props = {
    adminId: string;
};

export const AdminProfile = ({ adminId }: Props) => {
  const { loading, admin } = useAdmin(adminId);

  if (loading) {
    return <Spinner />;
  }

  if (!admin) {
    return <NotFound title="Administrateur introuvable" />;
  }

  return <>
    <Box title="Renseignements personnels">
      <Grid>
        <Item label='Nom'>{admin.lastname}</Item>
        <Item label='Prénom'>{admin.firstname}</Item>
        <Item label='Email'>{admin.email}</Item>
        <Item label='Téléphone'>{formatPhone(admin.phone)}</Item>
      </Grid>
    </Box>
    <Box title="Renseignements professionnels">
      <Grid>
        <Item label="Type">{getRoleLabel(admin.role)}</Item>
        <Item label="Fonction">{admin.job}</Item>
      </Grid>
    </Box>
  </>;
};
