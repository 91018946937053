import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import clsx from 'clsx';

import {
  ClientFunction,
  ClientFunctionList,
  getClientFunctionLabel,
  MissionFunctionTeamList
} from '../../../../../enums/client-function.enum';
import { IClientContact } from '../../../../../interfaces/client-contact.interface';
import { isEmail } from '../../../../../utils/is-email';
import { updateValidators } from '../../../../../utils/update-validators';
import { Box } from '../../../../general/Box';
import { ButtonBar } from '../../../../general/buttons/ButtonBar';
import { FormErrorsHandler } from '../../../../general/form/components/FormErrorsHandler';
import { NoData } from '../../../../general/NoData';

type TeamInputType = 'mission' | 'client';

type Props = {
  name: string;
  title: string;
  type: TeamInputType;
  error: any;
  control: Control<any>;
  register: UseFormRegister<any>;
}

export const TeamInput = ({ name, title, type, error, control, register } : Props) => {
  const { fields, append, remove } = useFieldArray({ control, name });
  const defaultValues: IClientContact = {
   role: ClientFunction.Another,
   lastname: '',
   firstname: '',
   email: '',
   phone: '',
  };

  return (
    <Box title={title} className="mt-6">
      <div className="ml-4 px-6 grid grid-cols-12 gap-3">
        <div className = "col-span-2 text-left text-xs font-medium text-gray-500">Fonction<span className="text-sm text-red-600"> *</span></div>
        <div className = "col-span-2 text-left text-xs font-medium text-gray-500">Nom<span className="text-sm text-red-600"> *</span></div>
        <div className = "col-span-2 text-left text-xs font-medium text-gray-500">Prénom<span className="text-sm text-red-600"> *</span></div>
        <div className = "col-span-3 text-left text-xs font-medium text-gray-500">Email</div>
        <div className = "col-span-2 text-left text-xs font-medium text-gray-500">Téléphone</div>
        <div className = "col-span-1 text-left text-xs font-medium text-gray-500"><span className="sr-only">Supprimer</span></div>
      </div>

      <div className="grid grid-flow-row auto-rows-auto gap-4">
        {fields.map((field, index) => {
          const data = field as any as IClientContact & Record<'id', string>;
          const fieldErrors = error?.[index] || undefined;
          return (
            <div key={data.id} className={clsx('ml-4 px-6 grid grid-cols-12 gap-3', index % 2 ? 'bg-gray-50' : 'bg-white')}>
              <div className="col-span-2">
                <select {...register(`${name}.${index}.role` as const, { required: true })} defaultValue={data.role} >
                  { type==='mission' && MissionFunctionTeamList.map(role => <option key={role} value={role}>{getClientFunctionLabel(role)}</option> ) }
                  { type==='client' && ClientFunctionList.map(role => <option key={role} value={role}>{getClientFunctionLabel(role)}</option> ) }
                </select>
              </div>
              <div className="col-span-2">
                <input
                  type="text"
                  {...register(`${name}.${index}.lastname` as const, { required: true })}
                  autoComplete="lastname"
                  defaultValue={data.lastname}
                />
                <FormErrorsHandler error={fieldErrors?.lastname} />
              </div>
              <div className="col-span-2">
                <input
                  type="text"
                 {...register(`${name}.${index}.firstname` as const, { required: true })}
                  autoComplete="firstname"
                 defaultValue={data.firstname}
                />
                <FormErrorsHandler error={fieldErrors?.firstname} />
              </div>
              <div className="col-span-3">
                <input
                  type="email"
                  {...register(`${name}.${index}.email` as const, { validate: {
                    Email: (value: string) => value !== '' ? isEmail(value) : undefined
                  } })}
                  autoComplete="email"
                  defaultValue={data.email}
                />
                <FormErrorsHandler error={fieldErrors?.email} />
              </div>
              <div className="col-span-2">
                <input
                  type="tel"
                  minLength={10}
                  maxLength={14}
                  {...register(`${name}.${index}.phone` as const, { validate:
                    updateValidators(false, { Number1014: (value) => value.length > 0 ? /^[0-9]{10,14}$/.test(value) : true
                  }) })}
                  autoComplete="phone"
                  defaultValue={data.phone}
                />
                <FormErrorsHandler error={fieldErrors?.phone} />
              </div>
              <div className="col-span-1">
                <p className="inline-flex items-center px-2 py-1.5 md:px-12 md:py-2 text-sm font-medium text-red-700 cursor-pointer" onClick={() => remove(index)}><i className="fas fa-trash-alt fa-1x"/></p>
              </div>
            </div>
          );
        })}
        { fields.length === 0 && <NoData>Aucun contact</NoData> }
      </div>
      <ButtonBar>
        <button className="btn" type="button" onClick={() => append({ ...defaultValues })}>
          <i className="fas fa-plus fa-1x"/>
          <span className="hidden md:flex pl-0.5 text-xs">Ajouter un contact</span>
        </button>
      </ButtonBar>
    </Box>
  );
};
