import { FormFieldBase, RegisterFunction } from './types';

export type FormFieldTextarea = FormFieldBase & {
  placeholder?: string;
  type: 'textarea';
}

export const Textarea = ({ field, register } : { field: FormFieldTextarea, register: RegisterFunction }) => (
  <textarea
    id={field.id || field.name}
    defaultValue={field.defaultValue}
    placeholder={field.placeholder || field.label}
    {...register(field.name, field.registerOptions)}
  />
);
