import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

function getCroppedImg(image: HTMLImageElement, crop: {x: number, y: number, width: number, height: number}) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('getContext 2d unsupported');
  }

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return canvas.toDataURL('image/jpg', 1);

  // As a blob
  /*
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      resolve(blob);
    }, 'image/jpeg', 1);
  });
  /**/
}

export const Crop = ({ data, onCrop }: { data: string, onCrop?: (dataURL: string) => void }) => {
  const [crop, setCrop] = useState<ReactCrop.Crop>({ aspect: 1, width: 100, unit: '%' });
  const [completedCrop, setCompletedCrop] = useState<ReactCrop.Crop>();
  const imgRef = useRef<HTMLImageElement|null>(null);

  useEffect(() => {
    const image = imgRef.current;
    if (completedCrop && image && onCrop) {
      const dataURL = getCroppedImg(image, completedCrop as any);
      onCrop(dataURL);
    }
  }, [completedCrop, onCrop]);

  const onImageLoaded = useCallback((img) => imgRef.current = img, []);

  return (
    <ReactCrop
      style={{ maxWidth: 400 }}
      src={data}
      crop={crop}
      onChange={setCrop}
      onComplete={setCompletedCrop}
      onImageLoaded={onImageLoaded}
      keepSelection={true}
    />
  );
};
