import { getProjectCategoryLabel } from '../../enums/project-category.enum';
import { useUser } from '../../hooks/use-user';
import { IProject } from '../../interfaces/project.interface';
import { formatToShortFrench } from '../../utils/timeslot-dates';

type Props = {
  project: IProject;
}
export const ProjectHeader = ({ project }: Props) => {
  const { user } = useUser(project?.managerId);
  return (
    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
      <div className="mt-2 flex items-center text-sm text-gray-500" title="Catégorie du projet">
        <i className="far fa-clipboard"/>&nbsp;<span>{getProjectCategoryLabel(project.category)}</span>
      </div>
      <div className="mt-2 flex items-center text-sm text-gray-500" title="Responsable du projet">
        <i className="fas fa-user-cog"/>&nbsp;{user?.firstname} {user?.lastname.toUpperCase()}
      </div>
      <div className="mt-2 flex items-center text-gray-500" title="Dates du projet">
        <i className="far fa-calendar text-sm"/>
        <span className={project.end ? 'text-xs' : 'text-sm'}>
          &nbsp;
          {formatToShortFrench(project.start)}
          &nbsp;➟&nbsp;
          { project.end ? formatToShortFrench(project.end!) : <>∞</> }
        </span>
      </div>
    </div>
  );
};
