import { NotFound } from '../../general/NotFound';
import { Spinner } from '../../general/Spinner';
import { useApi } from '../../../hooks/use-api';
import { timeslotService } from '../../../services/timeslot.service';
import { TeamTimeslotSummary } from '../../general/TeamTimeslotSummary';

type Props = {
  clientId: string;
};

export const TeamTimeslotStatus = ({ clientId }: Props) => {
  const { loading, result: status } = useApi(timeslotService.getClientStatus, clientId);

  if (loading || !status) {
    return <Spinner />;
  }

  if (!status) {
    return <NotFound title="Une erreur s'est produite" />;
  }

  return <TeamTimeslotSummary clientId={clientId} team={status.team} />;
};
