
export enum UserRole {
  AdminRoot = 'admin_root',
  AdminExpert = 'admin_expert',
  AdminPresta = 'admin_prestataire',
  AdminBilling = 'admin_facturation',

  ClientRoot = 'client_root',
  ClientLevel1 = 'client_lvl_1',
  ClientLevel2 = 'client_lvl_2',
  ClientLevel3 = 'client_lvl_3',
  ClientNonRD = 'client_non_rd',
  ClientRH = 'client_rh',
}

export type UserRoleAdmin = UserRole.AdminRoot | UserRole.AdminExpert | UserRole.AdminPresta | UserRole.AdminBilling;

export const UserRoleAdminList: UserRoleAdmin[] = [UserRole.AdminRoot, UserRole.AdminExpert, UserRole.AdminPresta, UserRole.AdminBilling];

export type UserRoleClient =
  UserRole.ClientRoot |
  UserRole.ClientLevel1 |
  UserRole.ClientLevel2 |
  UserRole.ClientLevel3 |
  UserRole.ClientNonRD |
  UserRole.ClientRH;

export const UserRoleClientList: UserRoleClient[] = [
  UserRole.ClientRoot,
  UserRole.ClientLevel1,
  UserRole.ClientLevel2,
  UserRole.ClientLevel3,
  UserRole.ClientNonRD,
  UserRole.ClientRH,
];

// Role allowed to manage projects (todo / move to server)
export const UserRoleProjectManagerList: UserRoleClient[] = [
  UserRole.ClientRoot,
  UserRole.ClientLevel1,
];
