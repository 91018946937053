import { ReactNode, useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { getProjectCategoryLabel } from '../../../enums/project-category.enum';
import { useUser } from '../../../hooks/use-user';
import { adminProjectService } from '../../../services/admin.project.service';
import { adminService } from '../../../services/admin.service';
import { Spinner } from '../../general/Spinner';
import { Table, TableCell, TableColumn } from '../../general/Table';
import { IAdmin } from '../../../interfaces/admin.interface';
import { IAdminProject } from '../../../interfaces/admin-project.interface';
import { TextLink } from '../../general/TextLink';
import { formatToLongFrench } from '../../../utils/timeslot-dates';
import { useClient } from '../../../hooks/use-client';
import { showToast } from '../../general/show-toast';
import { EmptyMsg } from '../../general/EmptyMsg';
import { OpenIconLink } from '../../general/OpenIconLink';

type Props = {
  className?: string;
  clientId?: string;
  expertId?: string;
  needExpertOnly?: true;
  emptyMsg?: string | ReactNode;
}

function ManagerCell( value: string) {
  const { user } = useUser(value);
  return user ? <span>{user.lastname} {user.firstname}</span> : '';
}

function ExpertCell( ids: string[]) {
  const [experts, setExperts] = useState<IAdmin[]>([]);

  useEffect(() => {
    Promise
      .all(ids.map(adminService.get))
      .then(setExperts)
      .catch(error => showToast({ error }));
  }, [ids]);

  return <ul>{experts.map(expert => <li key={expert.id}>{expert.lastname} {expert.firstname}</li>)}</ul>;
}

function ClientCell( value: string) {
  const { client } = useClient(value);
  return client ? <TextLink to={`/clients/${client.id}`} className='text-sm font-medium'>{ client.name }</TextLink> : '';
}

export const ProjectList = ({ className, clientId, expertId, needExpertOnly, emptyMsg }: Props) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<IAdminProject[]>([]);

  useEffect(() => {
    adminProjectService
      .getList({ rootOnly: true, clientId, expertId, needExpertOnly })
      .then(setProjects)
      .then(() => setLoading(false));
  }, [clientId, expertId, needExpertOnly]);

  const columns = useMemo<Array<TableColumn<IAdminProject>>>(
    () => [
      ...(clientId ? [] as any : [{
        Header: 'Client',
        accessor: 'clientId',
        disableSortBy: true,
        Cell: ({ value }) => ClientCell(value),
      },
      ] as Array<TableColumn<IAdminProject>>),
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: ({ row: { original: adminProject } }: TableCell<IAdminProject> ) => (
          <TextLink to={`/clients/${adminProject.clientId}/projects/${adminProject.id}`} className='text-sm font-medium'>{ adminProject.name }</TextLink>
        )
      },
      {
        Header: 'Catégorie',
        accessor: adminProject => getProjectCategoryLabel(adminProject.category),
      },
      {
        Header: 'Responsable',
        accessor: 'managerId',
        disableSortBy: true,
        Cell: ({ value }) => ManagerCell(value),
      },
      {
        Header: 'Expert',
        accessor: 'expertIds',
        disableSortBy: true,
        Cell: ({ value }) => ExpertCell(value),
      },
      {
        Header: 'Date de début',
        accessor: 'start',
        Cell: ({ value }) => formatToLongFrench(value)
      },
      {
        Header: 'Créé par',
        disableSortBy: true,
        accessor: 'creatorId',
        Cell: ({ value }) => ManagerCell(value),
      },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({ row: { original: adminProject } }: CellProps<IAdminProject>) => <OpenIconLink to={`/clients/${adminProject.clientId}/projects/${adminProject.id}`} title="Ouvrir le projet" />
      },
    ],
    [clientId]
  );

  if (loading) {
    return <Spinner />;
  }

  if (!projects.length && emptyMsg) {
    return  <EmptyMsg>{emptyMsg}</EmptyMsg>;
  }

  return <Table columns={columns} data={projects} className={className} />;
};
