import { useContext } from 'react';
import { useAdmin } from '../../../hooks/use-admin';

import { AccountContext } from '../../contexts/account.context';
import { Credential } from '../../general/Credential';
import { NotFound } from '../../general/NotFound';
import { Page } from '../../general/Page';
import { Spinner } from '../../general/Spinner';

type Props = {
  adminId: string;
}

export const AdminCredentialPage = ({ adminId }: Props) => {
  const { account } = useContext(AccountContext);
  const { admin, loading } = useAdmin(adminId);
  const isMe: boolean = adminId === account.id;
  if (loading) {
    return <Spinner />;
  }
  if (!admin) {
    return <NotFound title="Utilisateur introuvable" />;
  }
  return (
    <Page>
      <Credential user={admin} isMe={isMe}/>
    </Page>
  );
};
