import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';
import { AdminProfile } from '../../general/AdminProfile';
import { Box } from '../../general/Box';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { EditButton } from '../../general/buttons/EditButton';
import { SettingButton } from '../../general/buttons/SettingButton';
import { Page } from '../../general/Page';
import { useAdmin } from '../../../hooks/use-admin';
import { Spinner } from '../../general/Spinner';
import { NotFound } from '../../general/NotFound';
import { AvatarProfil } from '../../general/AvatarProfil';

type Props = {
  adminId: string;
};

export const AdminProfilePage = ({ adminId }: Props) => {
  const { admin, loading } = useAdmin(adminId);
  if (loading) {
    return <Spinner />;
  }
  if (!admin) {
    return <NotFound title="Utilisateur introuvable" />;
  }
  return (
    <Page>
      <Box title="Profil administrateur">
        <ButtonBar>
          <Can I={Action.Update} this={admin} as={Subject.User}>
            <EditButton to={`/admins/${adminId}/edit`} />
            <SettingButton to={`/admins/${adminId}/credentials`} label="Identifiants" />
          </Can>
        </ButtonBar>
          <AvatarProfil user={admin} />
      </Box>
        <AdminProfile adminId={adminId} />
    </Page>
  );
};
