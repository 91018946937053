import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';
import { useAdminProject } from '../../../hooks/use-project';
import { useWorklog } from '../../../hooks/use-worklog';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { EditButton } from '../../general/buttons/EditButton';
import { NotFound } from '../../general/NotFound';
import { Aside, Page } from '../../general/Page';
import { Spinner } from '../../general/Spinner';
import { ProjectUserList } from '../../general/ProjectUserList';
import { Worklog } from '../../general/Worklog';
import { ProjectExpertList } from '../components/ProjectExpertList';
import { ProjectTreeBox } from '../../general/ProjectTreeBox';
import { Box, BoxHeader } from '../../general/Box';
import { ProjectHeader } from '../../general/ProjectHeader';
import { ExternalDownloadButton } from '../../general/buttons/ExternalDownloadButton';

type Props = {
  clientId: string;
  projectId: string;
}

export const ProjectPage = ({ clientId, projectId }: Props) => {
  const { project, loading } = useAdminProject(projectId);
  const { loading: loadingWorklog, worklog } = useWorklog(projectId);

  if (loading || loadingWorklog) {
    return <Spinner />;
  }

  if (!project) {
    return <NotFound title="Projet introuvable" />;
  }

  return (
    <Page>
      <Aside>
        <ProjectTreeBox project={project} />
      </Aside>

      <Box title={project.name} >
        <BoxHeader>
          <ProjectHeader project={project} />
        </BoxHeader>
        <ButtonBar className="mb-6">
          { project.downloadWorklogs && <ExternalDownloadButton href={project.downloadWorklogs} filename='worklogs.zip' title="Télécharger les cahiers de suivi" /> }
          <Can I={Action.Update} a={project} as={Subject.Project}>
            <EditButton to={`/clients/${clientId}/projects/${project.id}/edit`} />
          </Can>
        </ButtonBar>

        { project.description && (
          <div className="mb-6">
            <dt className="text-sm font-medium text-gray-500">Description</dt>
            <p className="mt-1 text-sm text-gray-900">{ project.description }</p>
          </div>
        )}
      </Box>

      <Box title="Experts en charge du projet" transparent>
        <ProjectExpertList project={project} />
      </Box>

      <Box title="Équipe" transparent>
        <ProjectUserList project={project} />
      </Box>

      <Box title="Cahier de suivi">
        <ButtonBar>
          { worklog?.download && <ExternalDownloadButton href={worklog.download} filename='worklog.docx' title='Télécharger au format Word' /> }
        </ButtonBar>
        <Worklog project={project} worklog={worklog}/>
      </Box>
    </Page>
  );
};
