import { useState } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import fr from 'date-fns/locale/fr';
import clsx from 'clsx';

import { INotif } from '../../interfaces/notif.interface';
import { NotifPayload } from './NotifPayload';
import { Spinner } from './Spinner';
import { Tab, Tabs } from './Tabs';

type Props = {
  notifs: INotif[];
  onClick?: () => void;
}

const DisplayNotifs = ({ notifs, emptyNotif }: { notifs: INotif[], emptyNotif: string }) => {

  function prettyDateDelay(created: Date) {
    const delay = formatDistanceToNowStrict(new Date(created), { locale: fr });
    return delay.replace(/heures?/, 'h.')
      .replace(/minutes?/,'min.')
      .replace(/secondes?/, 's.');
  }

  return (
    <ul className="divide-y divide-gray-200 overflow-y-auto overscroll-x-none">
      {
        notifs.length === 0 ? <li className="px-3 py-5 relative">{ emptyNotif }</li> :
          notifs.map(notif => (
            <li key={notif.id} className="px-3 py-5 relative">
              <div className={clsx('group flex justify-between items-center text-xs', notif.isRead ? 'text-gray-600 hover:text-gray-900' : 'text-gray-900 hover:text-gray-700')}>
                {!notif.isRead && <i className="fas fa-circle fa-xs mr-2 text-blue-400 z-10"/>}
                <NotifPayload payload={notif.payload}>
                  <div className={`absolute inset-0 group-hover:bg-gray-50 ${!notif.isRead ? 'bg-blue-50' : 'bg-transparent'}`} aria-hidden="true" />
                  <div className="flex-none flex items-center min-w-0 relative">
                          <span className="flex-shrink-0 w-2/12 inline-block relative text-xs">
                            {prettyDateDelay(notif.createdAt)}
                          </span>
                    <div className="ml-4">
                      <p className="text-sm font-medium">{notif.text}</p>
                      <p className="text-xs font-extralight">{notif.client}</p>

                    </div>
                  </div>
                </NotifPayload>
              </div>
            </li>
          ))
      }
    </ul>
  );
};

export const NotifsList = ({ onClick, notifs }: Props) => {
  const [classAnimation, setClassAnimation] = useState<string>('notificationOpen');
  if(!notifs) {
    return <Spinner/>;
  }

  const handleClick = () => {
    setClassAnimation('notificationClose');
    setTimeout(function () {
      if(onClick) { onClick(); }
    }, 1500);
  };

  return (
    <div className={`fixed inset-y-0 right-0 pl-10 max-w-screen-xl flex z-20 ${classAnimation}`}>
      <div className="w-screen max-w-xs">
        <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
          <div className="px-3">
            <div className="flex items-start justify-between">
              <span className="text-lg font-medium text-gray-900">Notifications</span>
              <div className="ml-3 flex items-center">
                <button onClick={handleClick} className="bg-white text-gray-400 hover:text-black hover:bg-transparent">
                  <span className="sr-only">Close panel</span>
                  <i className="fas fa-times" />
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="px-3">
              <Tabs>
                <Tab title="Toutes" name='all'>
                  <DisplayNotifs notifs={notifs} emptyNotif='aucune notification'/>
                </Tab>
                <Tab title="Non lus" name='unread'>
                  <DisplayNotifs notifs={notifs.filter(notif => !notif.isRead)} emptyNotif='aucune nouvelle notification' />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
