import { ReactNode, useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { IInvoice } from '../../../interfaces/invoice.interface';
import { Table, TableColumn } from '../../general/Table';
import { Spinner } from '../../general/Spinner';
import { invoiceService } from '../../../services/invoice.service';
import { EmptyMsg } from '../../general/EmptyMsg';
import { unique } from '../../../tools/unique';
import { clientService } from '../../../services/client.service';
import { IClient } from '../../../interfaces/client.interface';
import { TextLink } from '../../general/TextLink';
import { IMission } from '../../../interfaces/mission.interface';
import { missionService } from '../../../services/mission.service';
import { formatToLongFrench } from '../../../utils/timeslot-dates';
import { formatCurrency } from '../../../tools/format-currency';
import { OpenIconLink } from '../../general/OpenIconLink';

type Props = {
  className?: string;
  pageSize?: number;
  emptyMsg?: string | ReactNode;
  clientId?: string;
  missionId?: string;
  ready?: boolean;
  haveBillingDate?: boolean;
  havePaidDate?: boolean;
}

type DataState = {
  loading?: boolean;
  invoices?: IInvoice[];
  missions?: IMission[];
  clients?: IClient[];
}
export const InvoiceList = ({ className, pageSize, emptyMsg, clientId, missionId, ready, haveBillingDate, havePaidDate }: Props) => {
  const [{ loading, missions, invoices, clients }, setData] = useState<DataState>({ loading: true });

  const columns = useMemo<Array<TableColumn<IInvoice>>>(
    () => [
      ...( clientId ? [] : [
      {
        Header: 'Client',
        accessor: (invoice: IInvoice) => clients?.find(client => client.id === invoice.clientId)?.name,
        Cell: ({ value, row: { original: invoice } }: CellProps<IInvoice, string>) => <TextLink to={`/clients/${invoice.clientId}`} className='text-sm font-medium'>{ value }</TextLink>,
        sortType: 'basic'
      }]),
      ...( missionId ? [] : [
      {
        Header: 'Mission',
        accessor: (invoice: IInvoice) => missions?.find(mission => mission.id === invoice.missionId)?.name,
        Cell: ({ value, row: { original: invoice } }: CellProps<IInvoice, string>) => <TextLink to={`/clients/${invoice.clientId}/missions/${invoice.missionId}`} className='text-sm font-medium'>{ value }</TextLink>,
        sortType: 'basic'
      }]),
      {
        Header: 'Facturable',
        accessor: invoice => invoice.ready ? 'Oui' : 'Non'
      },
      {
        Header: 'Date prévisionnelle',
        accessor: 'provisionalDate',
        Cell: ({ value }) => value ? formatToLongFrench(value) : ''
      },
      {
        Header: 'Montant H.T.',
        accessor: 'amountCts',
        Cell: ({ value }) => typeof value === 'number' ? formatCurrency(value / 100) : '',
        disableSortBy: true
      },
      {
        Header: 'Échéance',
        accessor: 'deadline',
        Cell: ({ value }) => value ? formatToLongFrench(value) : ''
      },
      {
        Header: 'Paiement',
        accessor: 'paidDate',
        Cell: ({ value }) => value ? 'Effectué' : 'En cours'
      },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({ row: { original: invoice } }: CellProps<IInvoice>) => <OpenIconLink to={`/clients/${invoice.clientId}/invoices/${invoice.id}`} title="Ouvrir la facture"/>
      },
    ],
    [clients, clientId, missionId]
  );

  useEffect(() => {
    setData({ loading: true });
    invoiceService
      .getList({ clientId, missionId, ready, haveBillingDate, havePaidDate })
      .then(invoices => {
        const clientIds = unique(invoices.map(invoice => invoice.clientId));
        const missionIds = unique(invoices.map(invoice => invoice.missionId));
        return Promise.all([
          invoices,
          Promise.all(clientIds.map(clientId => clientService.get(clientId))),
          Promise.all(missionIds.map(missionId => missionService.get(missionId))),
        ]);
      })
      .then(([invoices, clients, missions]) => {
        setData({
          invoices,
          clients,
          missions
        });
      });
  }, [clientId, missionId, ready, haveBillingDate, havePaidDate]);

  if (loading) {
    return <Spinner />;
  }

  if (!invoices!.length && emptyMsg) {
    return <EmptyMsg>{emptyMsg}</EmptyMsg>;
  }

  return <Table columns={columns} data={invoices!} className={className} pageSize={pageSize} />;

};
