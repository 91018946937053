/**
 * This class allows to debounce multiple http call of the same ressource
 * => it is used to avoid multiple call to refresh_token api on the "same time"
 */
export class PromiseSyncer<T> {
  private running = false;
  private stack: Array<{ resolve: (value: T) => void, reject: (reason?: any) => void }> = [];

  private add() {
    return new Promise<T>((resolve, reject) => this.stack.push({ resolve, reject }));
  }

  private resolve(value: T) {
    this.stack.forEach(handler => handler.resolve(value));
    this.stack = [];
    return value;
  }

  private reject(reason?: any) {
    this.stack.forEach(handler => handler.reject(reason));
    this.stack = [];
  }

  exec(fn: () => Promise<T>): Promise<T> {
    const promise = this.add();

    if (!this.running) {
      this.running = true;
      fn().then(value => {
        this.running = false;
        this.resolve(value);
      }).catch(err => {
        this.running = false;
        this.reject(err);
      });
    }

    return promise;
  }

  static sync<T>(fn: () => Promise<T>): () => Promise<T> {
    const syncer = new PromiseSyncer<T>();
    return () => syncer.exec(fn);
  }
}
