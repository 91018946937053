import { useContext } from 'react';
import { Routes as AdminRoutes } from './components/admin/Routes';
import { Routes as NonAdminRoutes } from './components/non-admin/Routes';
import { AccountContext } from './components/contexts/account.context';
import { isAdmin } from './type-guards/is-admin.type-guard';

export const Routes = () => {
  const { account } = useContext(AccountContext);
  return (
    isAdmin(account) ? <AdminRoutes /> : <NonAdminRoutes />
  );
};
