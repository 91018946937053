import { FormFieldBase, RegisterFunction } from './types';

export type FormFieldCheckbox = FormFieldBase & {
  type: 'checkbox';
}

export const Checkbox = ({ field, register } : { field: FormFieldCheckbox, register: RegisterFunction }) => (
  <input type='checkbox'
    id={field.id || field.name}
    defaultChecked={field.defaultValue}
    {...register(field.name, field.registerOptions)}
  />
);
