import { useEffect, useState } from 'react';

import { adminService } from '../../../services/admin.service';
import { IAdminLight } from '../../../interfaces/admin.interface';
import { showToast } from '../../general/show-toast';
import { Spinner } from '../../general/Spinner';
import { Page } from '../../general/Page';
import { AdminLightList } from '../components/AdminLightList';
import { Box } from '../../general/Box';
import { TimeslotSummaryWidget } from '../components/TimeslotSummaryWidget';

export const Dashboard = () => {
  const [experts, setExperts] = useState<IAdminLight[]>();

  useEffect(() => {
    adminService
      .getExpertList()
      .then(setExperts)
      .catch(error => {
        setExperts([]);
        showToast({ error });
      });
  }, []);

  if (!experts) {
    return <Spinner />;
  }

  return (
    <Page>

      <Box title="Vue d'ensemble" transparent>
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <TimeslotSummaryWidget />
        </div>
      </Box>

      <Box title="Vos experts" transparent>
        <AdminLightList admins={experts} />
      </Box>
    </Page>
  );
};
