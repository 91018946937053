const tokenStorageKey = 'token';

const hasToken = () => Boolean(getToken());

function getToken(): string {
  return sessionStorage.getItem(tokenStorageKey) || localStorage.getItem(tokenStorageKey) || '';
}

function replaceToken(token: string) : void {
  sessionStorage.setItem(tokenStorageKey, token);
  // remember?
  if (localStorage.getItem(tokenStorageKey)) {
    localStorage.setItem(tokenStorageKey, token);
  }
}

function setToken(token: string, remember: boolean): void {
  sessionStorage.setItem(tokenStorageKey, token);
  if (remember) {
    localStorage.setItem(tokenStorageKey, token);
  } else {
    localStorage.removeItem(tokenStorageKey);
  }
  doOnChange();
}

function clearToken(): void {
  sessionStorage.removeItem(tokenStorageKey);
  localStorage.removeItem(tokenStorageKey);
  doOnChange();
}

type OnChangeHandler = () => void;

const listeners: OnChangeHandler[] = [];

function doOnChange() {
  for (const listener of listeners) {
    listener();
  }
}

function onChange(handler: OnChangeHandler): () => void {
  listeners.push(handler);
  return () => {
    const index = listeners.indexOf(handler);
    if (index >= 0) {
      listeners.splice(index, 1);
    }
  };
}

export const authenticationService = {
  hasToken,
  getToken,
  setToken,
  replaceToken,
  clearToken,
  onChange,
};
