import { useContext } from 'react';

import { AccountContext } from '../../contexts/account.context';
import { Page } from '../../general/Page';
import { Box } from '../../general/Box';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { AddButton } from '../../general/buttons/AddButton';
import { UserList } from '../../general/UserList';
import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';

export const UserListPage = () => {
  const { getUser } = useContext(AccountContext);
  const user = getUser();
  return (
    <Page>
      <Box title="Liste des comptes utilisateurs" transparent>
        <ButtonBar>
          <Can I={Action.Create} a={Subject.User}>
            <AddButton to="/users/create" />
          </Can>
        </ButtonBar>
        <UserList clientId={user.clientId} />
      </Box>
    </Page>
  );
};
