import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { Spinner } from '../general/Spinner';
import { IClient } from '../../interfaces/client.interface';
import { clientService } from '../../services/client.service';
import { Table, TableColumn } from '../general/Table';
import { TextLink } from '../general/TextLink';
import { OpenIconLink } from '../general/OpenIconLink';
import { formatAccountingPeriod } from '../../utils/timeslot-dates';

type Props = {
  className?: string;
}

export const ClientList = ({ className }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<IClient[]>([]);

  const columns = useMemo<Array<TableColumn<IClient>>>(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: ({ value, row: { original: client } }) => <TextLink to={`/clients/${client.id}`} className='text-sm font-medium'>{ value }</TextLink>,
      },
      {
        Header: 'SIREN',
        accessor: 'siren',
        disableSortBy: true,
      },
      {
        Header: 'Ville',
        accessor: 'city',
        disableSortBy: true,
      },
      {
        Header: "Date de clôture de l'exercice",
        accessor: 'accountingPeriod',
        Cell: ({ value }) => value ? formatAccountingPeriod(value) : '',
      },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({ row: { original: client } }: CellProps<IClient>) => <OpenIconLink to={`/clients/${client.id}`} title="Voir le client"/>
      },
    ],
    []
  );

  useEffect(() => {
    clientService
      .getList()
      .then(setClients)
      .then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return <Table columns={columns} data={clients} className={className} />;
};
