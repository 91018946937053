import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { TimeSelect, toHMString } from './TimeSelect';
import { Day, getNow } from '../../utils/timeslot-dates';
import { ICreateTimeslot, ITimeslot } from '../../interfaces/timeslot.interface';
import { getTimeSlotOffTypeLabel, TimeslotOffType, TimeslotOffTypeLabels } from '../../enums/timeslot-off-type.enum';
import { IProject } from '../../interfaces/project.interface';
import { IUser } from '../../interfaces/user.interface';

type ProjectLeaf = {
  project: IProject;
  deep: number;
  children: Set<IProject>;
}

type ProjectTree = {
  [id: string]: ProjectLeaf
}

function buildProjectTree(projects: IProject[]): ProjectTree {
  const tree: ProjectTree = {};

  // setup
  for(const project of projects) {
    tree[project.id] = {
      project,
      deep: 0,
      children: new Set<IProject>(),
    };
  }

  // calculate deep / final position
  for(const project of projects) {
    let deep = 0;
    let parentId = project.parentId;
    while (parentId && tree[parentId]) {
      const parent = tree[parentId];
      parent.children.add(project);
      parentId = parent.project.parentId;
      deep += 1;
    }
    tree[project.id].deep = deep;
  }

  return tree;
}

type TimeGrid = {
  [key: string]: ICreateTimeslot;
}

export type TimeslotTableHandle = {
  isDirty: boolean;
  duration: number;
  reset: () => void;
  getValues: () => ICreateTimeslot[];
}

export const useTimeslotTable = () => {
  const ref = useRef<TimeslotTableHandle>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsDirty(ref.current?.isDirty || false);
      setDuration(ref.current?.duration || 0);
    }, 100);
    return () => clearInterval(timer);
  }, []);

  return {
    ref,
    isDirty,
    duration,
  };
};

function forgeGridKey(date: string, projectId: string) {
  return `${date}-${projectId}`;
}

function forgeGrid(timeslots: ITimeslot[]) {
  const grid: TimeGrid = {};
  for (const timeslot of timeslots) {
    const key = forgeGridKey(timeslot.date, timeslot.projectId || '');
    grid[key] = {
      date: timeslot.date,
      projectId: timeslot.projectId,
      offType: timeslot.offType,
      duration: timeslot.duration,
    };
  }
  return grid;
}

function filterTimeslotsWithUnknownProjects(projects: IProject[], timeslots: ITimeslot[]): ITimeslot[] {
  const ids: { [id: string]: true } = {};
  for (const project of projects) {
    ids[project.id] = true;
  }
  return timeslots.filter(timeslot => timeslot.projectId && !ids[timeslot.projectId]);
}

type Props = {
  user: IUser;
  days: Day[];
  projects: IProject[];
  timeslots: ITimeslot[];
  disabled?: boolean;
  weekEndVisible?: boolean;
}

/**
 * data must be memoized
 */
export const TimeslotTable = forwardRef<TimeslotTableHandle, Props>(function TimeslotTable({ user, days, projects, timeslots, disabled, weekEndVisible }, ref) {
  const [grid, setGrid] = useState<TimeGrid>(() => forgeGrid(timeslots));
  const [isDirty, setIsDirty] = useState(false);
  const tree = useMemo(() => buildProjectTree(projects), [projects]);
  const weekend = useMemo(() => days.slice(-2).map(day => day.date), [days]);
  const unknownProjectTimeslots = useMemo(() => filterTimeslotsWithUnknownProjects(projects, timeslots), [projects, timeslots]);

  useImperativeHandle(ref, () => {
    return {
      isDirty,
      duration: Object.values(grid).reduce((acc, timeslot) => acc + timeslot.duration, 0),
      reset: () => {
        setGrid(forgeGrid(timeslots));
        setIsDirty(false);
      },
      getValues: () => {
        return Object
          .values(grid)
          .map(timeslot => ({ ...timeslot })); // duplicate object
      }
    };
  },
  [isDirty, grid, timeslots]);

  useEffect(() => {
    setGrid(forgeGrid(timeslots));
    setIsDirty(false);
  }, [days, timeslots]);

  const getValue = (date: string, projectId: string | undefined) => grid[forgeGridKey(date, projectId || '')]?.duration || 0;
  const getUnknownProjectValue = (date: string) => unknownProjectTimeslots.reduce((sum, timeslot) => sum + (timeslot.date === date ? timeslot.duration : 0), 0);

  const setValue = (date: string, projectId: string | undefined, duration: number) => {
    const key = forgeGridKey(date, projectId || '');
    if ((grid[key]?.duration || 0) !== duration) {
      setIsDirty(true);
      setGrid(grid => {
        const copy: TimeGrid = { ...grid };
        if (duration) {
          copy[key] = {
            date,
            projectId,
            duration,
            offType: projectId ? undefined : getOffType(date)
          };
        } else {
          delete copy[key];
        }
        return copy;
      });
    }
  };

  const getOffType = (date: string) => grid[forgeGridKey(date, '')]?.offType || TimeslotOffType.RTT;

  const setOffType = (date: string, offType: TimeslotOffType) => {
    const key = forgeGridKey(date, '');
    const timeslot = grid[key];
    if (timeslot) {
      setIsDirty(true);
      setGrid(grid => {
        const copy: TimeGrid = { ...grid };
        copy[key] = {
          date,
          offType,
          duration: timeslot.duration,
        };
        return copy;
      });
    }
  };

  const now = getNow();

  function isDayHidden(day: Day) {
    return !weekEndVisible && weekend.includes(day.date);
  }

  function isDayCompatible(project: IProject, day: Day) {
    if (user.startDate && user.startDate > day.date) {
      return false;
    }
    if (user.endDate && user.endDate < day.date) {
      return false;
    }
    return (day.date <= now) && (project.start <= day.date) && (!project.end || day.date <= project.end);
  }

  function isProjectCompatible(project: IProject) {
    if (project.isTask) {
      return days.some(day => isDayCompatible(project, day));
    }
    return Array.from(tree[project.id].children).some(isProjectCompatible);
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="w-full divide-y divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="w-1/5" />
                  {
                    days.map(day => <th key={day.date} scope="col" className={clsx('px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider', isDayHidden(day) && 'hidden')}>{day.label}</th>)
                  }
                </tr>
              </thead>
              <tbody>
                {
                  projects
                    .filter(isProjectCompatible)
                    .map(project => {
                      const { deep } = tree[project.id];
                      let bgColor = 'bg-white';
                      if (!project.isTask) {
                        const parentBgColors = ['bg-blue-500', 'bg-blue-400', 'bg-blue-300', 'bg-blue-200'];
                        bgColor = parentBgColors[deep] || 'bg-blue-200';
                      }

                      return (
                        <tr key={ project.id } className={ `${bgColor} ${project.isTask ? 'text-gray-500' : 'text-white' }` }>
                          <td className="p-3 whitespace-nowrap text-sm font-medium truncate"><span style={{ paddingLeft: `${deep * 12}px` }} title={project.name}>{project.name}</span></td>
                          {
                            project.isTask ?
                              days.map(day => (
                                <td key={day.date} className={clsx('px-6 py-3 whitespace-nowrap text-sm', isDayHidden(day) && 'hidden')}>
                                  <TimeSelect disabled={disabled || !isDayCompatible(project, day)} onChange={(value: number) => setValue(day.date, project.id, value)} value={getValue(day.date, project.id)}/>
                                </td>
                              ))
                              :
                              <td colSpan={ weekEndVisible ? 7 : 5 } />
                          }
                        </tr>
                      );
                    })
                }
                {
                  unknownProjectTimeslots.length > 0 && (
                    <tr className='bg-white text-orange-500'>
                      <td className="p-3 whitespace-nowrap text-sm font-medium truncate"><span title='Projets non accessibles'>Autres projets</span></td>
                      {
                        days.map(day => (
                          <td key={day.date} className={clsx('px-6 py-3 whitespace-nowrap text-sm', isDayHidden(day) && 'hidden')}>
                            <TimeSelect disabled={true} onChange={() => 0} value={getUnknownProjectValue(day.date)}/>
                          </td>
                        ))
                      }
                    </tr>
                  )
                }
              
                <tr className="text-gray-500">
                  <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-center"><span>Autre</span></td>
                  {
                    days.map(day => {
                      const value = getValue(day.date, undefined);
                      const offType = getOffType(day.date);
                      return (
                        <td key={day.date} className={ clsx('px-4 py-3 whitespace-nowrap text-sm', isDayHidden(day) && 'hidden' )}>
                          {
                            projects.some(project => project.isTask && isDayCompatible(project, day)) && (
                              disabled ?
                                (
                                  value > 0 ?
                                    <div className="text-center">
                                      <span>{ toHMString(value) } <br /> { getTimeSlotOffTypeLabel(offType) }</span>
                                    </div>
                                    :
                                    null
                                )
                                :
                                <>
                                  <div className="px-2">
                                    <TimeSelect onChange={(value: number) => setValue(day.date, undefined, value)} value={value} />
                                  </div>
                                  <select className="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-5 sm:text-xs border-gray-300 disabled:text-gray-300 disabled:shadow-none rounded-md text-center mt-1"
                                    disabled={value === 0}
                                    value={offType}
                                    onChange={event => setOffType(day.date, event.target.value as TimeslotOffType)}
                                  >
                                    { Object.entries(TimeslotOffTypeLabels).map(([key, label]) => <option key={key} value={key}>{label}</option>) }
                                  </select>
                                </>
                            )
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});
