import { FC } from 'react';
import clsx from 'clsx';
import { intervalToDuration } from 'date-fns';

import { getMissionMonitoringLabel, MonitoringType } from '../../../../../enums/mission-monitoring.enum';
import { IMissionMonitoring } from '../../../../../interfaces/mission-monitoring.interface';
import { getMonitoringResponseLabel, MonitoringResponse } from '../../../../../enums/monitoring-response.enum';
import { formatToFeedFrench, formatToLongFrench } from '../../../../../utils/timeslot-dates';
import { ChildManager } from '../../../../../utils/child-manager';
import { MonitoringFinancingStatus } from '../../../../../enums/monitoring-financing-status.enum';

const MissionFeedIcon = ({ data }: {data: IMissionMonitoring}) => {
  if (data.incompatible) {
    return (
      <span className="rounded-full fa-stack bg-gray-200">
        <i className="fas fa-stack-1x fa-cog"/>
        <i className="fas fa-stack-1x fa-slash" style={{ color: 'Tomato' }}/>
      </span>
    );
  }
  if (data.statusFinancing === MonitoringFinancingStatus.Requested) {
    return <i className="fas fa-exclamation-circle fa-2x text-orange-400" />;
  }

  if (data.statusFinancing === MonitoringFinancingStatus.Received) {
    return <i className="fas fa-check-circle fa-2x text-yellow-400" />;
  }

  if (data.process || data.comment || data.amount) {
    return <i className="fas fa-info-circle fa-2x text-blue-400" />;
  }
  if (data.done || data.logic || data.statusFinancing === MonitoringFinancingStatus.Complete || (data.date && typeof data.done === 'undefined')) {
    return <i className={clsx('fas fa-check-circle fa-2x text-green-400', data.response === MonitoringResponse.Refused && 'text-red-400')} />;
  }
  return <i className="fas fa-times-circle fa-2x text-red-400" />;
};

function getIntervalLabel(val: number): string {
  const duration = intervalToDuration({ start: 0, end: val });
  const labels: string[] = [];
  if (duration.years) {
    labels.push(`${duration.years} année${ duration.years > 1 ? 's' : ''}`);
  }
  if (duration.months) {
    labels.push(`${duration.months} mois`);
  }
  if (!labels.length || duration.days) {
    labels.push(`${duration.days || 0} jour${ (duration.days ||0) > 1 ? 's' : ''}`);
  }
  return labels.join(', ');
}

export const MissionFeedItem: FC<{type: MonitoringType; monitoring: IMissionMonitoring[]}> = ({ type, monitoring }) => {
  const data = monitoring.find(item => item.type === type) || { type };

  return (
    <div className="relative flex space-x-3">
      <div>
        <span className="h-8 w-8 rounded-full bg-white flex items-center justify-center ring-8 ring-white">
          <MissionFeedIcon data={data} />
        </span>
      </div>
      <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
        <div>
          <p className="text-sm text-gray-500">{getMissionMonitoringLabel(type)}</p>
          {data.provisionalDate && <p className="text-sm text-gray-500">Date prévisionnelle : <strong>{formatToLongFrench(data.provisionalDate)}</strong></p>}
          {data.response && <p className="text-sm text-gray-500">Réponse : <strong>{getMonitoringResponseLabel(data.response)}</strong></p>}
          {data.incompatible && <p className="text-sm text-gray-500"><strong>Non applicable</strong></p>}
          {data.statusFinancing === MonitoringFinancingStatus.Requested && <p className="text-sm text-gray-500"><strong>Demandé</strong></p>}
          {data.statusFinancing === MonitoringFinancingStatus.Received && <p className="text-sm text-gray-500"><strong>Reçu</strong></p>}
          {(data.firstname || data.lastname) && <p className="text-sm text-gray-500 capitalize"><i className="fas fa-user mr-1.5" />{data.lastname} {data.firstname}</p>}
          {data.amount && <p className="text-sm text-gray-400"><strong>{(data.amount / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></p>}
          {data.process && <p className="text-sm text-gray-400"><strong>{getIntervalLabel(data.process)}</strong></p>}
          {data.comment && <p className="text-sm text-gray-400"><strong>{data.comment}</strong></p>}
        </div>
        <div className="text-right text-sm whitespace-nowrap text-gray-500">
          { data.date && <time dateTime={data.date}>{formatToFeedFrench(data.date)}</time> }
          { data.dateFinancing && <time dateTime={data.dateFinancing}>{formatToFeedFrench(data.dateFinancing)}</time> }
          { data.yearFinancing }
        </div>
      </div>
    </div>
  );
};

export const MissionFeed: FC = ({ children }) => {
  const manager = new ChildManager(children);
  const items = manager.filter(MissionFeedItem);
  return (
    <div className="max-w-xl mx-auto px-6">
      <div className="flow-root">
        <ul className="-mb-8">
          {
            items.map((item, index) => {
              return (
                <li key={index}>
                  <div className="relative pb-8">
                    { (index !== items.length - 1) && <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"/> }
                    { item }
                  </div>
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
};
