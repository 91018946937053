import { useCallback, useContext } from 'react';

import { isAdmin } from '../../../../type-guards/is-admin.type-guard';
import { AccountContext } from '../../../contexts/account.context';
import { getRoleLabel } from '../../../../casl/roles-label';
import { authenticationService } from '../../../../services/authentication.service';
import { SubMenuMobile } from './SubMenuMobile';
import { TextLink } from '../../TextLink';
import { userService } from '../../../../services/user.service';

type Props = {
  onClick?: () => void;
}

export const SubMenu = ({ onClick }: Props) => {
  const { account: me } = useContext(AccountContext);

  const logout = useCallback(async () => {
    await userService.logout();
    authenticationService.clearToken();
  }, []);

  return (
    <div className="origin-top-right absolute bottom-11 md:bottom-auto md:right-0 right-1.5 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu" onClick={onClick}>
      <TextLink to='/profile' className="block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem">
        {me.lastname.toUpperCase()}&nbsp;{me.firstname}
        <p className="text-xs italic font-thin lowercase">{getRoleLabel(me.role)}</p>
      </TextLink>
      <TextLink to={isAdmin(me) ? `/admins/${me.id}/credentials` : `/users/${me.id}/credentials`} className="block px-4 py-2 text-xs hover:bg-gray-100">
        Changer mes identifiants
      </TextLink>
      <SubMenuMobile className="sm:hidden"/>
      <button type="button" className="block w-full px-4 py-2 text-sm rounded-none border-0 border-t-2 border-gray-100 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem" onClick={logout}>
        Se déconnecter
      </button>
    </div>
  );
};
