import { FileIcon } from '../FileIcon';

type Props = {
  href: string;
  label?: string;
  title?: string;
  filename?: string;
}

export const ExternalDownloadButton = ({ href, label, title, filename }: Props) => (
  <a href={href} target='_blank' rel="noreferrer" className="btn" title={title || 'Télécharger'}>
    <FileIcon filename={filename || href.split('/').pop() || ''} className='fa-1x' />
    <span className="hidden md:flex pl-0.5">
      { label || 'Télécharger'}
    </span>
  </a>
);
