import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AccountContext, AccountContextValue } from './components/contexts/account.context';
import { AbilityContext } from './casl/can';
import { Header } from './components/general/header/Header';
import { abilityService } from './services/ability.service';
import { authenticationService } from './services/authentication.service';
import { Routes as AnonymousRoutes } from './components/anonymous/Routes';
import { Routes } from './Routes';
import { IUser } from './interfaces/user.interface';
import { IAdmin } from './interfaces/admin.interface';
import { apiService } from './services/api.service';
import { Spinner } from './components/general/Spinner';

import './tailwind.css';
import './assets/icons/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { ScrollToTop } from './components/general/ScrollToTop';

type DataState = {
  loading?: boolean;
  account?: IUser | IAdmin;
}

const App = () => {
  const [{ loading, account }, setData] = useState<DataState>({ loading: true });

  const setAccount = useCallback((account: IUser | IAdmin | undefined) => {
    setData({ account });
  }, []);

  const refreshAccount = useCallback(() => {
    const hasToken = authenticationService.hasToken();
    if (hasToken) {
      abilityService
        .refreshAbilities()
        .then(success => success ? apiService.get<IUser | IAdmin>('me') : undefined)
        .then(account => setData({ account }));
    } else {
      setData({});
    }
  }, []);

  useEffect(() => {
    return authenticationService.onChange(refreshAccount);
  }, [refreshAccount]);

  useEffect(refreshAccount, [refreshAccount]);

  if (loading) {
    return (
      <div className="h-screen w-screen flex justify-center align-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Router>
      <ScrollToTop>
        <ToastContainer limit={3}/>
        <div className="max-w-none mx-auto bg-white min-h-screen border-l border-r border-gray-200 flex flex-col">
          {
            account ?
              <AccountContext.Provider value={AccountContextValue({ account, setAccount })}>
                <AbilityContext.Provider value={ abilityService.getAbility() }>
                  <Header />
                  <div className="relative flex flex-1 top-24">
                    <Routes />
                  </div>
                </AbilityContext.Provider>
              </AccountContext.Provider>
              :
              <AnonymousRoutes />
          }
        </div>
      </ScrollToTop>
    </Router>
  );
};

export default App;
