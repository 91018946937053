
export enum ProjectCategory {
  OperationRD = 'operation_rd',
  ProjetInnovant = 'projet_innovant',
  Autre = 'autre',
}

export const getProjectCategoryList = () => Object.values(ProjectCategory);

export const ProjectCategoryLabels: {[category in ProjectCategory]: string} = {
  [ProjectCategory.OperationRD]: 'Opération R&D',
  [ProjectCategory.ProjetInnovant]: 'Projet Innovant',
  [ProjectCategory.Autre]: 'Autre',
};

export function getProjectCategoryLabel(category: ProjectCategory): string {
  return ProjectCategoryLabels[category];
}
