import { apiService } from './api.service';
import { ExportType } from '../enums/export-type.enum';
import { getMediaUrl } from '../utils/get-media-url';

type GenerateExportUrlDto = {
  type: ExportType;
  clientId?: string;
  year?: number;
}

async function getUrl(dto: GenerateExportUrlDto): Promise<string> {
  const { url } = await apiService.post<GenerateExportUrlDto, { url: string }>('exports', dto);
  return getMediaUrl(url);
}

export const exportService = {
  getUrl,
};
