import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { exportService } from '../../../services/export.service';
import { ExportType } from '../../../enums/export-type.enum';
import { showToast } from '../../general/show-toast';
import { SpinIcon } from '../../general/SpinIcon';
import { ExternalDownloadButton } from '../../general/buttons/ExternalDownloadButton';

type Props = {
  clientId: string;
}

function getAllYearsSince(year: number) {
  const now = new Date().getFullYear();
  const result: number[] = [];
  for (let y = year; y <= now; y++) {
    result.push(y);
  }
  return result;
}

type DataState = {
  loading?: boolean;
  url?: string;
};

export const ExportBox = ({ clientId }: Props) => {
  const [{ loading, url }, setData] = useState<DataState>({});
  const { register, handleSubmit } = useForm();
  const onSubmit = useCallback((data: any) => {
    setData({ loading: true });
    exportService
      .getUrl({
        type: ExportType.Timeslot,
        year: parseInt(data.year),
        clientId,
      })
      .then(url => {
        setData({ url });
      })
      .catch(error => {
        setData({});
        showToast({ error });
      });
  }, [clientId]);

  const years = getAllYearsSince(2021);

  return (
    <div className="bg-white sm:rounded-lg border border-gray-200">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Exporter les relevés d&apos;heures
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            Choisissez l&apos;année à exporter pour obtenir le lien de téléchargement
          </p>
        </div>
        <form className="mt-5 sm:flex sm:items-center" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full sm:max-w-xs">
            <label htmlFor="email" className="sr-only">Email</label>
            <select id="year" {...register('year')} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              { years.map(year => <option key={year}>{year}</option>) }
            </select>
          </div>
          <button type="submit" className="btn btn-primary ml-3 mr-8" disabled={loading}>
            { loading && <SpinIcon /> }
            Obtenir le lien
          </button>
          { url && <ExternalDownloadButton href={url} filename='export.xlsx' title='Télécharger au format Excel' /> }
        </form>

      </div>
    </div>
  );
};
