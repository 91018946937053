import { Link } from 'react-router-dom';

type Props = {
  to: string;
  label?: string;
}

export const SettingButton = ({ to, label } : Props) => (
  <Link to={to} className="btn">
    <i className="fas fa-user-cog fa-1x"/>
    <span className="hidden md:flex pl-0.5">
      { label || 'Réglage'}
    </span>
  </Link>
);
