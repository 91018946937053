import { NavLink } from '../general/header/components/NavLink';
import { Action, Subject } from '../../casl/enums';
import { Can } from '../../casl/can';

export const NavLinks = () => {
  return (
    <>
      <NavLink route="/"><span className="hidden md:flex">Tableau de bord</span><span className="md:hidden"><span className="fa-stack"><i className="fas fa-home fa-stack-2x"/></span></span></NavLink>
      <NavLink route="/clients"><span className="hidden md:flex">Clients</span><span className="md:hidden"><i className="fas fa-building fa-2x"/></span></NavLink>
      <Can I={Action.Create} a={Subject.Mission}>
        <NavLink route="/missions"><span className="hidden md:flex">Missions</span><span className="md:hidden"><i className="fas fa-handshake fa-2x"/></span></NavLink>
      </Can>
      <Can I={Action.Create} a={Subject.Invoice}>
        <NavLink route="/invoices"><span className="hidden md:flex">Factures</span><span className="md:hidden"><i className="fas fa-handshake fa-2x"/></span></NavLink>
      </Can>
      <NavLink route="/admins"><span className="hidden md:flex">Administrateurs</span><span className="md:hidden"><i className="fas fa-users-cog fa-2x"/></span></NavLink>
      <NavLink route="/advanced-search"><span className="hidden md:flex">Recherche avancée</span><span className="md:hidden"><i className="fas fa-search fa-2x"/></span></NavLink>
      <NavLink route="/time-entries"><span className="hidden md:flex">Pointages</span><span className="md:hidden"><i className="fas fa-search fa-2x"/></span></NavLink>
    </>
  );
};
