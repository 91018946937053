import { useHistory } from 'react-router-dom';

import { Page } from '../../general/Page';
import { UserForm } from '../../general/UserForm';
import { useUser } from '../../../hooks/use-user';
import { Spinner } from '../../general/Spinner';
import { NotFound } from '../../general/NotFound';

type Props = {
  clientId: string;
  userId?: string;
};

export const UserFormPage = ({ clientId , userId }: Props) => {
  const history = useHistory();
  const { loading, user } = useUser(userId);

  if (loading) {
    return <Spinner />;
  }

  if (userId && !user) {
    return <NotFound title="Utilisateur introuvable" />;
  }

  return (
    <Page>
      <UserForm clientId={clientId} user={user} onSave={user => history.push(`/clients/${clientId}/users/${user.id}`)} />
    </Page>
  );
};
