import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IAdmin } from '../../interfaces/admin.interface';
import { IUser } from '../../interfaces/user.interface';
import { authenticationService } from '../../services/authentication.service';
import { userService } from '../../services/user.service';
import { isEmail } from '../../utils/is-email';
import { updateValidators } from '../../utils/update-validators';
import { Box } from './Box';
import { BoxForm } from './BoxForm';
import { FormField } from './form/Form';
import { Page } from './Page';

function getFields(user: IUser | IAdmin, isMe: boolean, emailChecked: boolean ) {
  const fields: FormField[] = [
    {
      name: 'passwordForValidation',
      label: isMe ? 'Mon mot de passe actuel' : 'Votre mot de passe',
      placeholder: 'Votre mot de passe',
      type: 'password',
      registerOptions: { required: true }
    },
  ];
  if (emailChecked) {
    fields.push(
      {
        name: 'email',
        label: isMe ? 'Mon nouvel email' : `Nouvel email pour ${user.lastname} ${user.firstname}`,
        placeholder: 'Nouvel email',
        registerOptions: {
          required: true,
          validate: {
            Email: isEmail
          }
        },
      },
    );
  } else {
    fields.push(
      {
        name: 'password',
        label: isMe ? 'Mon nouveau mot de passe' : `Nouveau mot de passe pour ${user.lastname} ${user.firstname}`,
        placeholder: 'Nouveau mot de passe',
        type: 'password',
        registerOptions: {
        validate: updateValidators(true, {
          Length: (value) => value.length >= 12,
          Special: (value) => /[[\]^_!"#@$%&'()*+,\-./\\:;{}<>=|~?]/.test(value),
          Maj: (value) => /[A-Z]/.test(value),
          Min: (value) => /[a-z]/.test(value),
          Number: (value) => /[0-9]/.test(value)
          })
        },
      },
    );
  }
  return fields;
}

type Props = {
  user: IUser | IAdmin;
  isMe: boolean;
}

export const Credential = ({ user, isMe }: Props) => {
  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const history = useHistory();
  const fields = useMemo(() => user ? getFields(user, isMe, emailChecked) : [], [user, isMe, emailChecked]);

  const submit = useCallback((data: { passwordForValidation: string, password?: string, email?: string }) => {
    data.password = emailChecked ? undefined : data.password;
    data.email = emailChecked ? data.email : undefined;

    return userService
      .updateCredentials(user.id, {
        passwordForValidation: data.passwordForValidation,
        password: data.password,
        email: data.email,
      })
      .then(() => {
        if (!isMe || emailChecked) {
          history.goBack();
        } else {
          authenticationService.clearToken();
        }
      });
  },[user.id, emailChecked, history]);

  return (
    <Page>
      <Box title={isMe ? 'Modifier mes identifiants' : `Modifier les identifiants de ${user.lastname} ${user.firstname}`}>
        <fieldset>
          <legend className="sr-only">Identifiants</legend>
          <div className="bg-white rounded-md -space-y-px"  onChange={() => setEmailChecked(!emailChecked)}>
            <label className="border-gray-200 rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer">
              <input type="radio" name="credentials" value="password" aria-labelledby="credentials-0-label" aria-describedby="credentials-0-description" defaultChecked />
                <div className="ml-3 flex flex-col">
                  <span id="credentials-0-label" className="text-gray-900 block text-sm font-medium">Modifier {isMe ? 'mon' : 'son' } mot de passe</span>
                  <span id="credentials-0-description" className="text-gray-500 block text-sm">
                    La modification du mot de passe {isMe ? 'vous déconnectera' : "déclenchera une notification à l'utilisateur"}
                  </span>
                </div>
            </label>

            <label className="border-gray-200 relative border p-4 flex cursor-pointer">
              <input type="radio" name="credentials" value="email" aria-labelledby="credentials-1-label" aria-describedby="credentials-1-description" />
                <div className="ml-3 flex flex-col">
                  <span id="credentials-1-label" className="text-gray-900 block text-sm font-medium">Modifier {isMe ? 'mon' : 'son' } email</span>
                  <span id="credentials-1-description" className="text-gray-500 block text-sm">
                    {!isMe && "Après modification de l'email veuillez en informer l'utilisateur"}
                  </span>
                </div>
            </label>
          </div>
        </fieldset>
      </Box>
      <BoxForm
        title="Changement d'identifiants"
        labelOk={'Modifier'}
        fields={fields}
        submit={submit}
        backOnCancel />
    </Page>
  );
};
