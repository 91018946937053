import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { FormFieldBase } from './types';

export type FormFieldNumberInput = FormFieldBase & {
    placeholder?: string;
    type: 'currency';
}

export const NumberInput = ({ field, control } : { field: FormFieldNumberInput, control: any }) => {
  const [displayedValue, setDisplayedValue] = useState<string>(Number.isInteger(field.defaultValue) ? (field.defaultValue / 100).toString() : '');
  return (
     <div className="mt-1 flex rounded-md shadow-sm">
         <div className="relative flex items-stretch flex-grow focus-within:z-10">
           <Controller
             name={field.name}
             defaultValue={field.defaultValue ?? ''}
             rules={field.registerOptions}
             control={control}
             render={({ field: { onChange, value } }) => {
               return <input
                 type="text"
                 className="suffix"
                 value={displayedValue}
                 onChange={e => {
                   const value = parseFloat((e.target.value || '').replace(',', '.').trim());
                   onChange(isNaN(value) ? null : Math.floor(100 * value));
                   setDisplayedValue(e.target.value);
                 }}
                 onBlur={() => setDisplayedValue(!Number.isInteger(value) ? '' : (value / 100).toString())}
               />;
             }}
             />
           <div className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50">
             <i className="fas fa-euro-sign text-gray-400"/>
           </div>
         </div>
     </div>
 );
};
