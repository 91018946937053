import { FC } from 'react';
import clsx from 'clsx';

import { ButtonBar } from './buttons/ButtonBar';
import { ChildManager } from '../../utils/child-manager';

type Props = {
  title?: string;
  className?: string;
  transparent?: boolean;
  main?: boolean;
}

export const BoxHeader: FC = ({ children }) => <>{children}</>;

export const Box: FC<Props> = ({ title, className, transparent, main, children }) => {
  const manager = new ChildManager(children);
  const header = manager.findAndExtract(BoxHeader);
  const headerButtonBar = manager.extract(0, ButtonBar);
  const footerButtonBar = manager.extract(-1, ButtonBar);
  const hasChildren = manager.children().length > 0;
  const opaque = !transparent;

  return (
    <section className={className}>
      <div className={clsx('sm:overflow-hidden', opaque && 'border border-gray-200 sm:rounded-lg  bg-white')}>
        <div className={clsx(opaque && 'py-6 px-4 sm:p-6')}>
          <div className={clsx('flex justify-between items-center flex-wrap sm:flex-nowrap', title && hasChildren && opaque && 'border-b mb-6', headerButtonBar && 'pb-1')}>
            { title && (
              <div>
                {
                  main ?
                    <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">{title}</h1>
                    :
                    <h2 className="text-lg mb-2 font-medium text-gray-900">{title}</h2>
                }
                { header }
              </div>
            )}
            { headerButtonBar }
          </div>
          { hasChildren && <div>{ manager.children() }</div> }
        </div>
        {
          footerButtonBar && (
            <div className="px-4 py-3 bg-gray-50 sm:px-6 flex justify-end">
              {footerButtonBar}
            </div>
          )
        }
      </div>
    </section>
  );
};
