type ValidationError = {
  type: string; message?: string;
}

type Props = {
    id?: string;
    error: ValidationError | undefined;
}

function getMessage(error: ValidationError) {
  switch (error.type) {
    case 'required':
      return 'Ce champ est obligatoire';
    case 'Length':
      return '12 caractères minimum';
    case 'Number1014':
      return 'Entre 10 et 14 chiffres ([0033]0123456789)';
    case 'Siren':
      return 'SIREN invalide';
    case 'Special':
      return 'Au moins 1 caractère special';
    case 'Maj':
      return 'Au moins une majuscule';
    case 'Min':
      return 'Au moins une minuscule';
    case 'Number':
      return 'Au moins un nombre';
    case 'NumberOnly':
      return 'Uniquement des chiffres';
    case 'Email':
      return 'Email invalide';
    default:
      return error.message || 'Erreur inconnue';
  }
}

export const FormErrorsHandler = ({ error, id }: Props) => <>{ error && <span id={id} className="text-xs text-red-700">{getMessage(error)}</span> }</>;
