export enum ClientFunction {
  Executive = 'executive',
  Advisor = 'advisor',
  Billing = 'billing',
  Accountant = 'accountant',

  Technical = 'technical',
  Financial = 'financial',

  Another = 'another',
}

export type ClientFunctionContact = ClientFunction.Executive | ClientFunction.Advisor | ClientFunction.Billing | ClientFunction.Accountant | ClientFunction.Another;
export const ClientFunctionList: ClientFunctionContact[] = [ClientFunction.Executive, ClientFunction.Advisor, ClientFunction.Billing, ClientFunction.Accountant, ClientFunction.Another];

export type MissionFunctionTeam = ClientFunction.Technical | ClientFunction.Financial | ClientFunction.Another;
export const MissionFunctionTeamList: MissionFunctionTeam[] = [ClientFunction.Technical, ClientFunction.Financial, ClientFunction.Another];

export const ClientFunctionLabels: {[ role in ClientFunction]: string} = {
  [ClientFunction.Executive]: 'Dirigeant',
  [ClientFunction.Advisor]: 'Référent technique',
  [ClientFunction.Billing]: 'Facturation',
  [ClientFunction.Accountant]: 'Expert-comptable',

  [ClientFunction.Technical]: 'Technique',
  [ClientFunction.Financial]: 'Financier',

  [ClientFunction.Another]: 'Autre',
};

export function getClientFunctionLabel(role: ClientFunction): string {
  return ClientFunctionLabels[role];
}
