import { useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = {
  name: string;
  defaultValue?: Result;
  control: Control<any>;
}

type Result = {
  amount?: number;
}

export const InputFieldAmount = ({ name, defaultValue, control }: Props) => {
  const inputId = `${name}__input_`;
  const initialValue = useMemo(() => defaultValue || {}, [defaultValue]);
  const [displayedValue, setDisplayedValue] = useState<string>(initialValue.amount ? (initialValue.amount / 100).toString() : '');

  return (
    <Controller
      name={name}
      defaultValue={initialValue}
      control={control}
      render={({ field: { onChange, value: data } }) => (
        <div className="px-2 flex-1 rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              type='text'
              id={inputId}
              name={name}
              className='suffix'
              value={displayedValue}
              onChange={e => {
                const value = parseFloat((e.target.value || '').replace(',', '.').trim());
                onChange({
                  ...data,
                  amount: isNaN(value) ? null : Math.floor(100 * value)
                });
                setDisplayedValue(e.target.value);
              }}
              onBlur={() => setDisplayedValue(!Number.isInteger(data.amount) ? '' : (data.amount / 100).toString())}
            />
            <div className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50">
                <i className="fas fa-euro-sign text-gray-400"/>
            </div>
          </div>
        </div>
      )}
    />
  );
};
