export enum TimeslotOffType {
  RTT = 'rtt',
  CP = 'cp',
  Maladie = 'maladie',
  Autre = 'autre',
}

export const TimeslotOffTypeLabels: {[type in TimeslotOffType]: string} = {
  [TimeslotOffType.RTT]: 'RTT',
  [TimeslotOffType.CP]: 'Congés Payés',
  [TimeslotOffType.Maladie]: 'Maladie',
  [TimeslotOffType.Autre]: 'Autre',
};

export const getTimeslotOffTypeList = () => Object.values(TimeslotOffType);

export function getTimeSlotOffTypeLabel(type: TimeslotOffType): string {
  return TimeslotOffTypeLabels[type];
}
