import { apiService } from './api.service';
import { IUpdateProject } from '../interfaces/project.interface';
import { buildQueryString } from '../utils/build-query-string';
import { IAdminProject } from '../interfaces/admin-project.interface';
import { getMediaUrl } from '../utils/get-media-url';

function completeUrl<T extends (IAdminProject | IAdminProject[] | undefined)>(target: T): T {
  if (target) {
    if (Array.isArray(target)) {
      target.forEach(completeUrl);
    } else {
      if (target.downloadWorklogs) {
        target.downloadWorklogs = getMediaUrl(target.downloadWorklogs);
      }
    }
  }
  return target;
}

async function get(id: string): Promise<IAdminProject> {
  return completeUrl(await apiService.get<IAdminProject>(`admin-projects/${id}`));
}

async function getList(query: Partial<{ rootId: string, rootOnly: boolean, clientId: string, expertId: string, needExpertOnly: boolean }> = {}): Promise<IAdminProject[]> {
  const { projects } = await apiService.get<{projects: IAdminProject[]}>(`admin-projects?${buildQueryString(query)}`);
  return completeUrl(projects);
}

async function update(id: string, dto: IUpdateProject): Promise<IAdminProject> {
  return completeUrl(await apiService.put<IUpdateProject, IAdminProject>(`admin-projects/${id}`, dto));
}

export const adminProjectService = {
  get,
  getList,
  update,
};
