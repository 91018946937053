import { INotif } from '../interfaces/notif.interface';
import { apiService } from './api.service';

async function getList(): Promise<INotif[]> {
  const { notifs } = await apiService.get<{ notifs: INotif[] }>('notifs');
  return notifs;
}

async function tagAsReadBefore( lastId: string ): Promise<void> {
  await apiService.put('notifs/read', { lastId });
}

export const notifService = {
  getList,
  tagAsReadBefore,
};
