import { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  icon: string;
  link: string;
}
export const Widget: FC<Props> = ({ title, icon, link, children }) => (
  <div className="bg-white overflow-hidden border rounded-lg">
    <div className="p-5">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <i className={clsx('far text-xl text-gray-400', icon)} />
        </div>
        <div className="ml-5 w-0 flex-1">
          <dl>
            <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
            <dd>
              <div className="text-lg font-medium text-gray-900">{children}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div className="bg-gray-50 px-5 py-3">
      <div className="text-sm">
        <Link to={link} className="font-medium text-cyan-700 hover:text-cyan-900">Voir</Link>
      </div>
    </div>
  </div>
);
