import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { forwardRef, useEffect, useImperativeHandle, Fragment } from 'react';

import { FormErrorsHandler } from './components/FormErrorsHandler';
import { FormFieldHidden, Hidden } from './components/Hidden';
import { FormFieldInput, Input } from './components/Input';
import { FormFieldPassword, Password } from './components/Password';
import { FormFieldSelect, Select } from './components/Select';
import { DateInput, FormFieldDate } from './components/DateInput';
import { Checkbox, FormFieldCheckbox } from './components/Checkbox';
import { Checkboxes, FormFieldCheckboxes } from './components/Checkboxes';
import { Custom, FormFieldCustom } from './components/Custom';
import { FormFieldWysiwyg, WysiwygEditor } from './components/WysiwygEditor';
import { FormFieldPhoneNumber, PhoneNumber } from './components/PhoneNumber';
import { FormFieldTextarea, Textarea } from './components/Textarea';
import { FormFieldNumberInput, NumberInput } from './components/NumberInput';

export type FormState = { isValid: boolean, isDirty: boolean };

export type OnFormStateChange = (state: FormState) => void;

export type FormField = (FormFieldHidden | FormFieldInput | FormFieldTextarea | FormFieldPassword | FormFieldSelect | FormFieldDate | FormFieldCheckbox | FormFieldCheckboxes | FormFieldCustom | FormFieldWysiwyg | FormFieldPhoneNumber | FormFieldNumberInput) & {
  cols?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  emptyCols?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
};

type Props<FormData> = {
  fields: FormField[];
  submit: SubmitHandler<FormData>;
  onStateChange?: OnFormStateChange;
}

export type FormHandle = {
  submit: () => void;
  setValue: (name: string, value: any) => void;
}

export const Form = forwardRef<FormHandle, Props<any>>(({ fields, submit, onStateChange }, ref) => {
  const { register, control, setValue, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<any>({ mode: 'all' });

  useEffect(() => {
    if (onStateChange) {
      onStateChange({ isValid, isDirty });
    }
  }, [isValid, isDirty, onStateChange]);

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit(submit)();
    },
    setValue(name: string, value: any) {
      setValue(name, value, { shouldValidate: true });
    }
  }),
  [handleSubmit, submit, setValue]);

  const classByCols = {
    1: 'col-span-8 sm:col-span-1',
    2: 'col-span-8 sm:col-span-2',
    3: 'col-span-8 sm:col-span-3',
    4: 'col-span-8 sm:col-span-4',
    5: 'col-span-8 sm:col-span-5',
    6: 'col-span-8 sm:col-span-6',
    7: 'col-span-8 sm:col-span-7',
    8: 'col-span-8 sm:col-span-8',
  };

  const classFiller = {
    1: 'sm:col-span-1 hidden sm:block',
    2: 'sm:col-span-2 hidden sm:block',
    3: 'sm:col-span-3 hidden sm:block',
    4: 'sm:col-span-4 hidden sm:block',
    5: 'sm:col-span-5 hidden sm:block',
    6: 'sm:col-span-6 hidden sm:block',
    7: 'sm:col-span-7 hidden sm:block',
  };

  return (
    <form className="mt-3" onSubmit={handleSubmit(submit)}>
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-8 gap-6">
        {
          fields.map(field => (
            field.type === 'hidden' ?
              <Hidden field={field} register={register} key={field.name} />
              :
              <Fragment key={field.name}>
                <div className={classByCols[field.cols || 4]}>
                  {
                    field.type === 'checkbox' ?
                      <>
                        <Checkbox field={field} register={register} />
                        <label className="pl-2 text-sm font-medium text-gray-700" htmlFor={field.id || field.name}>{field.label}</label>
                      </>
                      :
                      <>
                        <label className="block text-sm font-medium text-gray-700" htmlFor={field.id || field.name}>
                          {field.label}
                          {
                            field.registerOptions?.required && <span className="text-sm text-red-600"> *</span>
                          }
                        </label>
                        <div className="mt-1 block w-full py-2 px-3">
                          {
                            (!field.type || field.type === 'text') && <Input field={field} register={register} />
                          }
                          {
                            (field.type === 'currency') && <NumberInput field={field} control={control} />
                          }
                          {
                            (field.type === 'textarea') && <Textarea field={field} register={register} />
                          }
                          {
                            (field.type === 'password') && <Password field={field} register={register} />
                          }
                          {
                            (field.type === 'select') && <Select field={field} register={register} />
                          }
                          {
                            (field.type === 'date') && <DateInput field={field} control={control} />
                          }
                          {
                            (field.type === 'checkboxes') && <Checkboxes field={field} register={register} />
                          }
                          {
                            (field.type === 'custom') && <Custom field={field} control={control} />
                          }
                          {
                            (field.type === 'wysiwyg') && <WysiwygEditor field={field} control={control} />
                          }
                          {
                            (field.type === 'phone') && <PhoneNumber field={field} register={register} />
                          }
                          <FormErrorsHandler error={((errors as any)[field.name])} />
                        </div>
                      </>
                  }
                </div>
                {
                  field.emptyCols && <div className={classFiller[field.emptyCols]} />
                }
              </Fragment>
          ))
        }
      </div>
    </form>
  );
});
