import React from 'react';

import { SpinIcon } from './SpinIcon';

export const Spinner = () => (
  <div className="flex-1 flex justify-center items-center">
    <button type="button" className="bg-gray-400 hover:bg-gray-400" disabled>
      <SpinIcon />
      <span className='ml-3'>Veuillez patienter...</span>
    </button>
  </div>
);
