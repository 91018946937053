import { Box } from '../../general/Box';
import { Page } from '../../general/Page';
import { MissionList } from '../components/MissionList';

export const MissionListPage = () => (
  <Page>
    <Box transparent>
      <MissionList />
    </Box>
  </Page>
);
