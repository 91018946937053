import { useContext } from 'react';

import { useUser } from '../../../hooks/use-user';
import { AccountContext } from '../../contexts/account.context';
import { Credential } from '../../general/Credential';
import { NotFound } from '../../general/NotFound';
import { Page } from '../../general/Page';
import { Spinner } from '../../general/Spinner';

type Props = {
  userId: string;
}

export const CredentialPage = ({ userId }: Props) => {
  const { account } = useContext(AccountContext);
  const { user, loading } = useUser(userId);
  const isMe: boolean = userId === account.id;
  if (loading) {
    return <Spinner />;
  }
  if (!user) {
    return <NotFound title="Utilisateur introuvable" />;
  }
  return (
    <Page>
      <Credential user={user} isMe={isMe}/>
    </Page>
  );
};
