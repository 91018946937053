import { FC } from 'react';
import clsx from 'clsx';

const colors = {
  red: 'bg-red-100 text-red-800 border-red-400',
  blue: 'bg-blue-100 text-blue-800 border-blue-400',
  green: 'bg-green-200 text-green-800 border-green-400',
  yellow: 'bg-yellow-200 text-yellow-800 border-yellow-400',
  gray: 'bg-gray-200 text-gray-800 border-gray-400',
  lightGray: 'bg-gray-50 text-gray-400 border-gray-200',
  purple: 'bg-purple-200 text-purple-700 border-purple-200',
};

type Props = {
  color: keyof typeof colors;
  className?: string;
}

export const Badge : FC<Props> = ({ children, color, className }) => {
  return <span className={clsx('inline-flex items-center px-2.5 py-0.5 border rounded-full text-xs font-medium mx-1', colors[color], className)}>{children}</span>;
};
