import Avatar from '../../assets/avatar.png';
import { IUser } from '../../interfaces/user.interface';
import { getMediaUrl } from '../../utils/get-media-url';
import { IAdmin } from '../../interfaces/admin.interface';

type Props = {
  user: IUser | IAdmin;
};

export const AvatarProfil = ({ user } : Props) => {
  const url = getMediaUrl(user.avatar?.url);
  return (
    <div className="flex justify-center">
      <img className="h-24 w-24 rounded-full" src={ url || Avatar } alt={`${user.firstname} ${user.lastname}`}/>
    </div>
  );
};
