import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Payload } from '../../interfaces/notif.interface';

type Props = {
  payload: Payload;
}

function createUrl(payload: Payload) {
  if ('type' in payload) {
    switch (payload.type) {
      case 'project':
        return payload.clientId ? { url: `/clients/${payload.clientId}/projects/${payload.projectId}`, title: 'aller au projet' } : { url: `/projects/${payload.projectId}`, title: 'Voir le projet' };
      case 'worklog':
        return payload.clientId ? { url: `/clients/${payload.clientId}/projects/${payload.projectId}`, title: 'voir le cahier de suivi' } : { url: `/projects/${payload.projectId}`, title: 'Voir le cahier de suivi' };
      case 'timeslot':
        return { url: `/users/${payload.userId}/timeslots?date=${payload.from}`, title: 'Voir la fiche de temps' };
      case 'invoice':
        return { url: `/clients/${payload.clientId}/invoices/${payload.invoiceId}`, title: 'Voir la facture' };
      case 'mission':
        return { url: `/clients/${payload.clientId}/missions/${payload.missionId}`, title: 'Voir la mission' };
    }
  }
  return { url: '', title: '' };
}

export const NotifPayload : FC<Props> = ({ children, ...props }) => {
  const { url, title } = createUrl(props.payload);
  return url ? <Link to={url} className="-m-1 p-1 block" title={title}>{children}</Link> : <div className="-m-1 p-1 block">{children}</div>;
};
