import { ReactNodeArray } from 'react';
import { Cell, Column, TableCellProps, useTable, useSortBy, usePagination } from 'react-table';
import clsx from 'clsx';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props<D extends object> = {
  columns: Array<TableColumn<D>>,
  data: D[],
  className?: string,
  pageSize?: number,
  getCellProps?: GetCellPropsFunction<D>
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableColumn<D extends object = {}> = Column<D>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableCell<D extends object = {}> = Cell<D>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type GetCellPropsFunction<D extends object = {}> = (cell: Cell<D>) => Partial<TableCellProps>;

const EmptyRows = ({ className, rowCount, colSpan }: { className: string; rowCount: number; colSpan: number }) => {
  const items: ReactNodeArray = [];
  for (let i = 0; i < rowCount; i++) {
    items.push(
      <tr key={i} className={className}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan={colSpan}>&nbsp;</td>
      </tr>
    );
  }
  return <>
    { items }
  </>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function Table<D extends object>({ columns, data, className, pageSize = 20, getCellProps = () => ({}) }: Props<D>) {
  const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0, pageSize } }, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  return <div className={clsx('flex flex-col', className)}>
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
          <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {
                headerGroups.map(headerGroup => (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      headerGroup.headers.map(column => (
                        // eslint-disable-next-line react/jsx-key
                        <th {...column.getHeaderProps([
                          { className: 'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider' },
                          column.getSortByToggleProps(),
                        ])} scope="col">
                          { column.render('Header') }
                          <span className="hidden sm:inline-flex ">
                            {column.canSort ? (<i className="fas fa-sort pl-1"/>) : ''}
                          </span>
                        </th>
                      ))}
                  </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                  // eslint-disable-next-line react/jsx-key
                    <tr {...row.getRowProps({ className: clsx(i % 2 ? 'bg-gray-50' : 'bg-white', 'hover:bg-blue-50') })}>
                      {
                        row.cells.map(cell => (
                          // eslint-disable-next-line react/jsx-key
                          <td {...cell.getCellProps([
                            { className: 'px-6 py-4 whitespace-nowrap text-sm text-gray-500' },
                            getCellProps(cell)
                          ])}>
                            { cell.render('Cell') }
                          </td>
                        ))}
                    </tr>
                  );
                })
              }
              {(pageIndex === pageCount-1) && <EmptyRows className={`${data.length % 2 ? 'bg-gray-50' : 'bg-white'}`}  colSpan={columns.length} rowCount={page.length % data.length} />}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    {data.length > 0 && pageCount > 1 && <div className="flex justify-end items-center space-x-0.5 mt-3 pagination">
      <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}><i className="fas fa-backward fa-1x"/></button>
      <button onClick={() => previousPage()} disabled={!canPreviousPage}><i className="fas fa-chevron-left fa-1x"/></button>
      <button onClick={() => nextPage()} disabled={!canNextPage}><i className="fas fa-chevron-right fa-1x"/></button>
      <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}><i className="fas fa-forward fa-1x"/></button>
      <span>Page&nbsp;{pageIndex + 1} sur {pageOptions.length}</span>
    </div>}
  </div>;
}
