import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { format, parse, addYears } from 'date-fns';
import fr from 'date-fns/locale/fr';
import clsx from 'clsx';

import calendarLogo from '../../../../assets/icons/calendar_icon.png';
import { FormFieldBase } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import MaskedInput from 'react-text-mask';

setDefaultLocale('fr');
registerLocale('fr', fr);

export type FormFieldDate = FormFieldBase & {
  type: 'date';
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  isNoYear?: boolean;
}

export const DateInput = ({ field, control } : { field: FormFieldDate, control: any }) => {
  const useFormat = field.isNoYear ? 'dd/MM' : 'dd/MM/yyyy';
  const formattedValue = field.isNoYear ? 'MM-dd' : 'yyyy-MM-dd';
  const customMask = field.isNoYear ? [/\d/, /\d/, '/', /\d/, /\d/] : [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  return (
    <div className="flex">
      <div className="flex-grow">
        <Controller
          name={field.name}
          defaultValue={field.defaultValue || ''}
          rules={field.registerOptions}
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              id={field.id || field.name}
              dateFormat={useFormat}
              selected={value ? parse(value, formattedValue, new Date()) : undefined}
              onChange={(value: Date | null) => onChange(value ? format(value, formattedValue) : null)}
              locale="fr"
              placeholderText="Entrer une date"
              isClearable
              minDate={field.minDate || addYears(new Date(), -100)}
              maxDate={field.maxDate || null}
              peekNextMonth={!field.isNoYear }
              showMonthDropdown={!field.isNoYear }
              showYearDropdown={!field.isNoYear }
              dateFormatCalendar={field.isNoYear ? 'LLLL' : 'LLLL yyyy' }
              dropdownMode="select"
              disabled={field?.disabled}
              customInput={
                  <MaskedInput mask={customMask} guide={false} type="text"/>
              }
            />
          )}
        />
      </div>
      <label className="flex-initial h-8 my-auto" htmlFor={field.id || field.name}>
        <img className={clsx('hidden sm:inline-block', field?.disabled ? 'filter grayscale' : 'cursor-pointer')} src={calendarLogo} alt="calendrier" style={{ width: 32 }}/>
      </label>
    </div>
  );
};
