export enum Action {
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  List = 'list',
}

export enum Subject {
  User = 'User',
  Client = 'Client',
  Project = 'Project',
  Timeslot = 'Timeslot',
  Worklog = 'Worklog',
  Mission = 'Mission',
  Invoice = 'Invoice',
}
