import { toVAT } from 'vat-siren-siret';

import { useClient } from '../../../hooks/use-client';
import { Box } from '../../general/Box';
import { ClientAgreementList } from '../../general/ClientAgreementList';
import { ClientContactList } from '../../general/ClientContactList';
import { Grid } from '../../general/grid/Grid';
import { Item } from '../../general/grid/Item';
import { NotFound } from '../../general/NotFound';
import { Spinner } from '../../general/Spinner';
import { formatAccountingPeriod } from '../../../utils/timeslot-dates';
import { Action, Subject } from '../../../casl/enums';
import { Can } from '../../../casl/can';

type Props = {
  clientId: string;
}

export const ClientDetails = ({ clientId }: Props) => {
  const { loading, client } = useClient(clientId);

  if (loading) {
    return <Spinner />;
  }

  if (!client) {
    return <NotFound title="Client introuvable" />;
  }

  return (
    <>
      <h2 className="text-lg mb-2 font-medium text-gray-900">Informations générales</h2>
      <Box>
        <Grid>
          <Item label='Nom' full-span>{client.name}</Item>
          <Item label='SIREN'>{client.siren}</Item>
          <Item label='Numéro TVA Intracommunautaire'>{toVAT(client.siren || '')}</Item>
          <Item label="Date de clôture de l'exercice" full-span>{client.accountingPeriod ? formatAccountingPeriod(client.accountingPeriod) : ''}</Item>
          <Item label='Adresse'>{client.address}</Item>
          <Item label="Complément d'adresse">{client.addressMore}</Item>
          <Item label='Code postal'>{client.zipcode}</Item>
          <Item label='Ville'>{client.city}</Item>
          <Item label='Pays'>{client.country}</Item>
        </Grid>
      </Box>
      <Box title="Contacts" className="mt-6" transparent>
        <ClientContactList client={client} />
      </Box>
      <Can I={Action.Read} a={Subject.Invoice} field='agreements'>
        <Box title="Lettres de mission" className="mt-6" transparent>
          <ClientAgreementList client={client} />
        </Box>
      </Can>
    </>
  );
};
