import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';
import { AdminList } from '../../general/AdminList';
import { Box } from '../../general/Box';
import { AddButton } from '../../general/buttons/AddButton';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { Page } from '../../general/Page';

export  const AdminListPage = () => {
  return <Page>
    <Box title="Liste des administrateurs" transparent>
      <ButtonBar>
        <Can I={Action.Create} a={Subject.User}>
          <AddButton to="/admins/create" />
        </Can>
      </ButtonBar>
      <AdminList />
    </Box>
  </Page>;
};
