import image from '../../assets/sign-image.jpg';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { userService } from '../../services/user.service';
import { authenticationService } from '../../services/authentication.service';
import { InformationPopup } from '../general/InformationPopup';
import { Spinner } from '../general/Spinner';
import { showToast } from '../general/show-toast';

interface FormData {
  email: string
  password: string
  remember: boolean
}

export const SignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isRevealPwd, setIsRevealPwd] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const { register, handleSubmit, formState: { isValid } } = useForm<FormData>({ mode: 'all' });
  /**
   * Submit credential and store token if asked by user
   * @param remember_me
   * @param email
   * @param password
   */

  async function submit ({ remember, email, password }: any) {
    setLoading(true);
    const credentials = await userService.login({ email, password });
    if (credentials && 'access_token' in credentials) {
      authenticationService.setToken(credentials.access_token, remember);
    } else if (credentials && 'ban' in credentials) {
      showToast({ type: 'error', title: 'Erreur de connexion', content: `Ce compte est désactivé pendant ${credentials.ban} secondes.` });
      setLoading(false);
    } else {
      showToast({ type: 'error', title: 'Erreur de connexion', content: 'Identifiant ou mot de passe erroné !' });
      setLoading(false);
    }
  }
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 z-10">
      <div className="relative m-8 w-full md:w-2/3 lg:w-3/5 xl:w-1/2 max-w-3xl bg-white rounded-xl overflow-hidden flex justify-end">
        <div className="w-full lg:w-3/5 p-8">
          <a href="https://panda-conseil.fr" target="_blank" rel="noreferrer"><h2 className="-mt-4 pb-6 text-center">Innoplan ® <sub className="text-sm text-gray-500 uppercase">Panda Conseil</sub></h2></a>
          <h2 className="text-3xl font-extrabold text-gray-900">Identifiez-vous</h2>
          <div className="mt-8">

            <div className="mt-6">
              <form className="space-y-6" onSubmit={handleSubmit(submit)}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Adresse e-mail</label>
                  <div className="mt-1">
                    <input id="email" type="email" autoComplete="email" className="mt-1 block w-full py-2 px-3" placeholder="Adresse e-mail" {...register('email', { required: true })} />
                  </div>
                </div>
                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">Mot de passe</label>
                  <div className=" mt-1 relative">
                    <input id="password" type={isRevealPwd ? 'text' : 'password'} autoComplete="current-password" className="mt-1 block w-full py-2 px-3" placeholder="Mot de passe" {...register('password', { required: true })} />
                    <div className="absolute right-2 top-2 w-6" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                      {isRevealPwd ? <i className="fas fa-eye-slash fa-lg"/> : <i className="fas fa-eye fa-lg"/>}
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input id="remember" type="checkbox" {...register('remember')} />
                    <label htmlFor="remember" className="ml-2 block text-xs md:text-sm text-gray-900">
                        Se souvenir de moi
                    </label>
                  </div>

                  <div className="text-xs md:text-sm">
                    <p className="button font-medium text-primaryPandaColor hover:text-secondaryPandaColor" onClick={() => setShowConfirmation(true)}>
                        Mot de passe oublié ?
                    </p>
                  </div>
                </div>
                <div>
                  { loading ? <Spinner/> : <button id="signin" type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primaryPandaColor hover:bg-secondaryPandaColor focus:outline-none" disabled={!isValid} >Se connecter</button> }
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="absolute inset-y-0 left-0 hidden lg:block w-2/5">
          <img className="absolute inset-0 h-full w-full object-cover" src={image} alt="innoPlan" />
        </div>
      </div>
      {showConfirmation && (
        <InformationPopup title="Information !" label="OK" onOk={() => setShowConfirmation(false)} >
          <p className="text-sm text-gray-500">Veuillez contacter votre <strong>superviseur</strong> (utilsateur du logiciel) <br /> afin q&#039;il réinitialise votre mot de passe.</p>
        </InformationPopup>
      )}
    </div>
  );
};
