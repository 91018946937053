import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { getProjectCategoryLabel } from '../../../enums/project-category.enum';
import { useUser } from '../../../hooks/use-user';
import { projectService } from '../../../services/project.service';
import { Spinner } from '../../general/Spinner';
import { Table, TableColumn } from '../../general/Table';
import { IProject } from '../../../interfaces/project.interface';
import { TextLink } from '../../general/TextLink';
import { formatToLongFrench } from '../../../utils/timeslot-dates';
import { IAdmin } from '../../../interfaces/admin.interface';
import { adminService } from '../../../services/admin.service';
import { showToast } from '../../general/show-toast';
import { OpenIconLink } from '../../general/OpenIconLink';

type Props = {
  className?: string;
}

function ManagerCell( value: string) {
  const { user } = useUser(value);
  return user ? <span>{user.lastname} {user.firstname}</span> : '';
}

function ExpertCell( ids: string[]) {
  const [experts, setExperts] = useState<IAdmin[]>([]);

  useEffect(() => {
    Promise
      .all(ids.map(adminService.get))
      .then(setExperts)
      .catch(error => showToast({ error }));
  }, [ids]);

  return <ul>{experts.map(expert => <li key={expert.id}>{expert.lastname} {expert.firstname}</li>)}</ul>;
}

export const ProjectList = ({ className }: Props) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<IProject[]>([]);

  const columns = useMemo<Array<TableColumn<IProject>>>(
    () => [
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: ({ row: { original: project } } ) => <TextLink to={`/projects/${project.id}`} className='text-sm font-medium'>{ project.name }</TextLink>
      },
      {
        Header: 'Catégorie',
        accessor: project => getProjectCategoryLabel(project.category),
      },
      {
        Header: 'Responsable',
        accessor: 'managerId',
        Cell: project => ManagerCell(project.value),
        disableSortBy: true
      },
      {
        Header: 'Expert',
        accessor: 'expertIds',
        disableSortBy: true,
        Cell: ({ value }) => ExpertCell(value),
      },
      {
        Header: 'Date de début',
        accessor: 'start',
        Cell: ({ value }) => formatToLongFrench(value)
      },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({ row: { original: project } }: CellProps<IProject>) => <OpenIconLink to={`/projects/${project.id}`} title="Ouvrir le projet" />
      },
    ],
    []
  );

  useEffect(() => {
    projectService
      .getList({ rootOnly: true })
      .then(setProjects)
      .then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return <Table columns={columns} data={projects} className={className} />;
};
