import { useState } from 'react';
import { FormFieldInput } from './Input';
import { RegisterFunction } from './types';

export type FormFieldPassword = Omit<FormFieldInput, 'type'> & {
  type: 'password';
}

export const Password = ({ field, register } : { field: FormFieldPassword, register: RegisterFunction }) => {
  const [isRevealPwd, setIsRevealPwd] = useState<boolean>(false);
  return (
    <div className="relative">
      <input type={isRevealPwd ? 'text' : 'password'}
        id={field.id || field.name}
        defaultValue={field.defaultValue}
        placeholder={field.placeholder || field.label}
        autoComplete={field.autoComplete || 'on'}
        {...register(field.name, field.registerOptions)}
      />
      <div className="absolute right-2 top-2 w-6" onClick={() => setIsRevealPwd(prevState => !prevState)}>
        {isRevealPwd ? <i className="fas fa-eye-slash fa-lg"/> : <i className="fas fa-eye fa-lg"/>}
      </div>
    </div>
  );
};
