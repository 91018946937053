import { subject } from '@casl/ability';

import { apiService } from './api.service';
import { ICreateProject, IProject, IUpdateProject } from '../interfaces/project.interface';
import { buildQueryString } from '../utils/build-query-string';
import { Action, Subject } from '../casl/enums';
import { abilityService } from './ability.service';

function get(id: string): Promise<IProject> {
  return apiService.get(`projects/${id}`);
}

type GetListQuery = {
    userId?: string;
    rootId?: string;
    rootOnly?: boolean;
    assignedOnly?: boolean;
}

async function getList(query: GetListQuery = {}): Promise<IProject[]> {
  const { projects } = await apiService.get<{ projects: IProject[] }>(`projects?${buildQueryString(query)}`);
  return projects;
}

function create(dto: ICreateProject): Promise<IProject> {
  return apiService.post('projects', dto);
}

function update(id: string, dto: IUpdateProject): Promise<IProject> {
  return apiService.put(`projects/${id}`, dto);
}

function upsert(dto: ICreateProject | (IUpdateProject & { id: string })): Promise<IProject> {
  if ('id' in dto && dto.id) {
    return update(dto.id, dto);
  }
  return create(dto as ICreateProject);
}

function can(action : Action, source?: Partial<IProject>, field?: keyof IProject): boolean {
  const ability = abilityService.getAbility();
  return ability.can(action, source ? subject(Subject.Project, source) : Subject.Project, field);
}

export const projectService = {
  can,
  get,
  getList,
  upsert,
};
