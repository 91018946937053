import { FormFieldBase, RegisterFunction } from './types';

export type FormFieldSelect = FormFieldBase & {
  type: 'select';
  onChange?: (value: string) => void;
  placeholder?: string;
  options: Array<{label?: string, value?: string}>
}

export const Select = ({ field, register } : { field: FormFieldSelect, register: RegisterFunction }) => (
  <select
    id={field.id || field.name}
    defaultValue={ field.placeholder ? '' : field.defaultValue }
    {...register(field.name, field.registerOptions)}
    onChange={e => field.onChange && field.onChange(e.target.value)}
  >
    { field.placeholder && <option disabled={true} value=''>{field.placeholder}</option> }
    {
      field.options.map(option => <option key={option.value} value={option.value}>{option.label || option.value}</option> )
    }
  </select>
);
