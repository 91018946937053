import { FileIcon } from './FileIcon';
import { IMedia } from '../../interfaces/media.interface';
import { getMediaUrl } from '../../utils/get-media-url';

const fileSize = (size: number) => {
  if (!size) {
    return '-';
  }
  const k = 1024;
  const sizes = ['o', 'Ko', 'Mo', 'Go'];
  const i = Math.floor(Math.log(size)/Math.log(k));
  return parseFloat((size/Math.pow(k,i)).toFixed(1)) + ' ' + sizes[i];
};

type Props = {
  media: IMedia;
  onDeleteRequest?: (media: IMedia) => void;
};

export const Media = ({ media, onDeleteRequest }: Props) => {
  const filename = decodeURIComponent(media.url.split('/').pop() || '');
  return (
    <div className="relative rounded-md overflow-hidden hover:bg-gray-100 group m-3">
      {
        onDeleteRequest && (
          <div className="hidden group-hover:block absolute top-0 right-0">
            <button type="button" className="rounded-md text-gray-300 hover:text-gray-500 hover:bg-transparent p-1" title="Supprimer ce fichier" onClick={() => onDeleteRequest(media)}>
              <i className="fas fa-times text-2xl" />
            </button>
          </div>
        )
      }
      <a className="block flex flex-col items-center w-24 p-3 text-gray-900" href={getMediaUrl(media.url)} title={filename} target="_blank" rel="noreferrer">
        <FileIcon filename={filename} />
        <p className="text-xs text-center w-full overflow-ellipsis overflow-hidden ...">{filename}</p>
        <span className="text-xs text-center text-gray-400">({fileSize(media.size)})</span>
      </a>
    </div>
  );
};
