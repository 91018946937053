import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';
import { useMission } from '../../../hooks/use-mission';
import { Box, BoxHeader } from '../../general/Box';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { EditButton } from '../../general/buttons/EditButton';
import { NotFound } from '../../general/NotFound';
import { Page } from '../../general/Page';
import { Spinner } from '../../general/Spinner';
import { MissionContactList } from '../components/MissionContactList';
import { MissionHeader } from '../components/MissionHeader';
import { MissionFeed, MissionFeedItem } from '../components/mission/components/MissionFeed';
import { getMissionMonitoringLabel, MonitoringType } from '../../../enums/mission-monitoring.enum';
import { MissionCategory } from '../../../enums/mission-category.enum';
import { MissionAdminList } from '../components/MissionAdminList';
import { InvoiceList } from '../components/InvoiceList';
import { AddButton } from '../../general/buttons/AddButton';
import { EmptyMsg } from '../../general/EmptyMsg';

type Props = {
  clientId: string;
  missionId?: string;
}
export const MissionPage = ({ clientId, missionId }: Props) => {
  const { mission, loading } = useMission(missionId);
  if (loading) {
    return <Spinner />;
  }
  if (!mission) {
    return <NotFound title="Mission introuvable" />;
  }

  const cir = mission.monitoring.find(item => item.type === MonitoringType.EligibleProjectCIR);
  const cii = mission.monitoring.find(item => item.type === MonitoringType.EligibleProjectCII);

  return (
    <Page>
      <Box title={mission.name}>
        <BoxHeader>
          <MissionHeader mission={mission} />
        </BoxHeader>
        <ButtonBar className="mb-6">
          <Can I={Action.Update} a={mission} as={Subject.Mission}>
            <EditButton to={`/clients/${clientId}/missions/${mission.id}/edit`} />
          </Can>
        </ButtonBar>
        <div>
          { cir?.logic && <p className='text-sm text-gray-700 mb-2'>{getMissionMonitoringLabel(MonitoringType.EligibleProjectCIR)}</p>}
          { cii?.logic && <p className='text-sm text-gray-700 mb-2'>{getMissionMonitoringLabel(MonitoringType.EligibleProjectCII)}</p>}
          <h2 className="text-base my-2 font-medium text-gray-900">Informations générales sur le client et la mission</h2>
          {
            mission.information?.trim().length ? (
            <p className="bg-white border rounded-md px-4 py-2 sm:px-6 text-sm text-gray-500 whitespace-pre-line">
              {mission.information}
            </p>
            ) : <EmptyMsg>Aucune information</EmptyMsg>
          }

          <Can I={Action.Read} a={mission} as={Subject.Mission} field='modality'>
            <h2 className="text-base mt-4 mb-2 font-medium text-gray-900">Modalités de la lettre de mission</h2>
            {
              mission.modality?.trim().length ? (
                <div className="bg-white border rounded-md px-4 py-2 sm:px-6 text-sm text-gray-500 whitespace-pre-line">
                  {mission.modality}
                </div>
              ) : <EmptyMsg>Aucune modalité</EmptyMsg>
            }
          </Can>

          <h2 className="text-base mt-4 mb-2 font-medium text-gray-900">Échanges clients</h2>
          {
            mission.exchange?.trim().length ? (
              <div className="bg-white border rounded-md px-4 py-2 sm:px-6 text-sm text-gray-500 whitespace-pre-line">
                <p>{mission.exchange}</p>
              </div>
            ) : <EmptyMsg>Aucun échange client</EmptyMsg>
          }

        </div>
      </Box>

      <Can I={Action.List} a={Subject.Invoice}>
        <Box title='Factures' transparent>
          <ButtonBar>
            <Can I={Action.Create} a={Subject.Invoice}>
              <AddButton to={`/clients/${clientId}/missions/${mission.id}/invoices/create`} />
            </Can>
          </ButtonBar>
          <InvoiceList clientId={clientId} missionId={missionId} emptyMsg="Aucune facture"/>
        </Box>
      </Can>

      <Box title="Équipe Panda" transparent>
        <MissionAdminList admins={mission.admins} />
      </Box>

      <Box title="Équipe Client" transparent>
        <MissionContactList  contacts={mission.clientContacts} />
      </Box>

      { mission.category === MissionCategory.CirCii &&
        <>
          <Box title="Suivi de mission">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.BriefClient} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.TimeslotTemplate} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.OperationTemplate} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.ProjectTemplate} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.AccountantRequest} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.TimeslotRequest} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.OperationRequest} monitoring={mission.monitoring}/>
            </MissionFeed>
          </Box>

          <Box title="Checking-list des éléments reçus">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.ChecklistTimeslot} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistOperation} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistDeliveredAtPlace} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistTechWatch} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPatent} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistSubcontractor} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPayslip} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPayroll} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistCV} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistDegree} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistApproval} monitoring={mission.monitoring} />
            </MissionFeed>
          </Box>

          <Box title="Rapport">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.ReportPrior} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ReportReview} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ReportSent} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ReportValidated} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.AmountClaimedCIR} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.AmountClaimedCII} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.AmountAwardedCIR} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.AmountAwardedCII} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.Cerfa} monitoring={mission.monitoring} />
            </MissionFeed>
          </Box>

          <Box title="Élaboration du dossier">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.PreparatoryMeeting} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.DraftFile} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.FinalFile} monitoring={mission.monitoring} />
            </MissionFeed>
          </Box>

          <Box title="Contrôle">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.ControlRequest} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ControlProofSent} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ControlResponse} monitoring={mission.monitoring} />
            </MissionFeed>
          </Box>

          <Box title="Paiement">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.PaymentTaxSettlement} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentRefund} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentClientReminder} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentDateCIR} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentBilling} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentLastReminder} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentDateInvoice} monitoring={mission.monitoring} />

              <MissionFeedItem type={MonitoringType.ProcessTimeCerfa} monitoring={mission.monitoring} />
              {/*<MissionFeedItem type={MonitoringType.ProcessTimeBilling} monitoring={mission.monitoring} />*/}
              {/*<MissionFeedItem type={MonitoringType.ProcessTimeInvoicing} monitoring={mission.monitoring} />*/}
            </MissionFeed>
          </Box>
        </>
      }
      { mission.category === MissionCategory.Finance &&
        <>
          <Box title="Suivi de mission">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.KickOff} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.Pitch} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FinancierSent} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FinancierReturn} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FinancierMeeting} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FileTechnical} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FileFinancial} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FileBusiness} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FinalValidation} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FinancierReSent} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.InformationRequest} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.InformationResponse} monitoring={mission.monitoring}/>
            </MissionFeed>
          </Box>

          <Box title="Suivi de la contractualisation">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.AgreementOral} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.AgreementWritten} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.Payment1} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.Payment2} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.Balance} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.BalanceSent} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.BalancePayment} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ArchivedNotification} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ArchivedConvention} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.AmountRequested} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.AmountGranted} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ExpensesDate} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ProgramEndDate} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ModalityConvention} monitoring={mission.monitoring} />
            </MissionFeed>
          </Box>

          <Box title="Checking-list des éléments reçus">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.ChecklistKbis} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistStatutes} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistRIB} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistTaxReturn1} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistTaxReturn2} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistTaxReturn3} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistRegularityFiscal} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistRegularitySocial} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistIdentificationLeader} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistFunding} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPlanPresentation} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPlanFinancial} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistActivities} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistSheetSigned} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistAnother} monitoring={mission.monitoring} />
            </MissionFeed>
          </Box>

          <Box title="Paiement client de la mission">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.PaymentBilling} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentLastReminder} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentDateInvoice} monitoring={mission.monitoring} />

              {/*<MissionFeedItem type={MonitoringType.ProcessTimeBilling} monitoring={mission.monitoring} />*/}
            </MissionFeed>
          </Box>
        </>
      }
      { mission.category === MissionCategory.Another &&
        <>
          <Box title="Suivi de mission">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.KickOff} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.CanevasSent} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.CanevasReturn} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.DraftSent} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.DraftReturn} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FinalFileSent} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.FinalValidation} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.OrganismSent} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.InformationRequest} monitoring={mission.monitoring}/>
              <MissionFeedItem type={MonitoringType.InformationResponse} monitoring={mission.monitoring}/>
            </MissionFeed>
          </Box>

          <Box title="Suivi de la contractualisation">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.AgreementOral} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.AgreementWritten} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ArchivedNotification} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ArchivedConvention} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ConventionStartDate} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ConventionEndDate} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ModalityConvention} monitoring={mission.monitoring} />
            </MissionFeed>
          </Box>

          <Box title="Checking-list des éléments reçus">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.ChecklistKbis} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistStatutes} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistRIB} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistTaxReturn1} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistTaxReturn2} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistTaxReturn3} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistRegularityFiscal} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistRegularitySocial} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistIdentificationLeader} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistFunding} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPlanPresentation} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPlanFinancial} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistActivities} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistSheetSigned} monitoring={mission.monitoring} />

              <MissionFeedItem type={MonitoringType.ChecklistOperation} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistDeliveredAtPlace} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistTechWatch} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPatent} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistSubcontractor} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPayslip} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistPayroll} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistCV} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistDegree} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.ChecklistApproval} monitoring={mission.monitoring} />

              <MissionFeedItem type={MonitoringType.ChecklistAnother} monitoring={mission.monitoring} />
            </MissionFeed>
          </Box>

          <Box title="Paiement client de la mission">
            <MissionFeed>
              <MissionFeedItem type={MonitoringType.PaymentBilling} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentLastReminder} monitoring={mission.monitoring} />
              <MissionFeedItem type={MonitoringType.PaymentDateInvoice} monitoring={mission.monitoring} />

              {/*<MissionFeedItem type={MonitoringType.ProcessTimeBilling} monitoring={mission.monitoring} />*/}
            </MissionFeed>
          </Box>
        </>
      }
    </Page>
  );
};
