// eslint-disable-next-line @typescript-eslint/ban-types
export function buildQueryString<T extends Object>(data: T): string {
  const params = [];
  for (const [key, value] of Object.entries(data)) {
    if (value !== undefined) {
      params.push(`${key}=${encodeURIComponent(value)}`);
    }
  }
  return params.join('&');
}
