export enum MonitoringType {
  //Mission CIR CII
  BriefClient = 'brief_client',
  TimeslotTemplate = 'envoi_canvas_temps',
  OperationTemplate = 'envoi_canvas_operation',
  ProjectTemplate = 'envoi_canvas_projet',
  AccountantRequest = 'demande_piece_comptable',
  TimeslotRequest = 'demande_feuille_de_temps',
  OperationRequest = 'demande_operation_recherche',
  ChecklistTimeslot = 'checklist_feuille_de_temps',
  ChecklistOperation = 'checklist_operation_recherche',
  ChecklistDeliveredAtPlace = 'checklist_dap',
  ChecklistTechWatch = 'checklist_veille_techno',
  ChecklistPatent = 'checklist_brevet',
  ChecklistSubcontractor = 'checklist_sous_traitant',
  ChecklistPayslip = 'checklist_fiche_de_paie',
  ChecklistPayroll = 'checklist_recapitulatif_de_paie',
  ChecklistCV = 'checklist_cv',
  ChecklistDegree = 'checklist_diplome',
  ChecklistApproval = 'checklist_agrement',
  EligibleProjectCIR = 'projet_eligible_cir',
  EligibleProjectCII = 'projet_eligible_cii',
  AmountClaimedCIR = 'montant_reclame_cir',
  AmountClaimedCII = 'montant_reclame_cii',
  AmountAwardedCIR = 'montant_accorde_cir',
  AmountAwardedCII = 'montant_accorde_cii',
  ReportPrior = 'rapport_prealable',
  ReportReview = 'relecture_du_rapport',
  ReportSent = 'envoi_du_rapport',
  ReportValidated = 'validation_du_rapport',
  Cerfa = 'cerfa',
  PreparatoryMeeting = 'rdv_preparatoire_dossier',
  DraftFile = 'dossier_justificatif_brouillon',
  FinalFile = 'dossier_justificatif_final',
  ControlRequest = 'demande_de_control',
  ControlProofSent = 'justificatif_envoye',
  ControlResponse = 'reponse_recue',
  PaymentTaxSettlement = 'imputation_is',
  PaymentRefund = 'demande_remboursement',
  PaymentClientReminder = 'relance_client',
  PaymentDateCIR = 'date_paiement_cir',
  PaymentBilling = 'facturation',
  PaymentLastReminder = 'derniere_relance',
  PaymentDateInvoice = 'date_paiement_facture',
  ProcessTimeCerfa = 'temps_cerfa_cir',
  ProcessTimeBilling = 'temps_facturation_paiement',
  ProcessTimeInvoicing = 'temps_brief_paiement',

  //Mission Financement
  KickOff = 'kick_off',
  Pitch = 'pitch',
  FinancierSent = 'envoi_au_financeur',
  FinancierReturn = 'retour_du_financeur',
  FinancierMeeting = 'rdv_financeur',
  FileTechnical = 'redaction_dossier_technique',
  FileFinancial = 'redaction_dossier_financier',
  FileBusiness = 'redaction_dossier_business',
  FinalValidation = 'validation_finale_client',
  FinancierReSent = 'reenvoi_au_financeur',
  InformationRequest = 'demande_informations_complementaires',
  InformationResponse = 'reponse_informations_complementaires',
  AgreementOral = 'accord_oral',
  AgreementWritten = 'accord_ecrit',
  Payment1 = '1er_versement',
  Payment2 = '2eme_versement',
  Balance = 'redaction_demande_solde',
  BalanceSent = 'envoi_demande_solde',
  BalancePayment = 'paiement_solde',
  ArchivedNotification = 'notification_archivee',
  ArchivedConvention = 'convention_archivee',
  AmountRequested = 'montant_demande',
  AmountGranted = 'montant_accorde',
  ExpensesDate = 'date_depenses',
  ProgramEndDate = 'date_fin_programme',
  ModalityConvention = 'modalite_convention',
  ChecklistKbis = 'checklist_kbis',
  ChecklistStatutes = 'checklist_statuts_conformes',
  ChecklistRIB = 'checklist_rib',
  ChecklistTaxReturn1 = 'checklist_liasse_fiscale_n1',
  ChecklistTaxReturn2 = 'checklist_liasse_fiscale_n2',
  ChecklistTaxReturn3 = 'checklist_liasse_fiscale_n3',
  ChecklistRegularityFiscal = 'checklist_regularite_fiscale',
  ChecklistRegularitySocial = 'checklist_regularite_sociale',
  ChecklistIdentificationLeader = 'checklist_pieces_identite_dirigeant',
  ChecklistFunding = 'checklist_table_capitalisation',
  ChecklistPlanPresentation = 'checklist_business_plan_presentation',
  ChecklistPlanFinancial = 'checklist_business_plan_financier',
  ChecklistActivities = 'checklist_activites',
  ChecklistSheetSigned = 'checklist_fiche_signee',
  ChecklistAnother = 'checklist_autre',

  //Mission Autre
  CanevasSent = 'envoi_canevas',
  CanevasReturn = 'retour_canevas',
  DraftSent = 'envoi_draft',
  DraftReturn = 'retour_draft',
  FinalFileSent = 'envoi_dossier_finalise',
  OrganismSent = 'envoi_financeur_ou_organisme',
  ConventionStartDate = 'date_debut_convention',
  ConventionEndDate = 'date_fin_convention',
}

export const MissionMonitoringLabels : {[type in MonitoringType]: string} = {
  //Mission CIR CII
  [MonitoringType.BriefClient]: 'Brief client avec mise en place de la méthodologie',
  [MonitoringType.TimeslotTemplate]: 'Envoi canevas des feuilles de temps ',
  [MonitoringType.OperationTemplate]: 'Envoi canevas fiche suivi opération de recherche',
  [MonitoringType.ProjectTemplate]: 'Envoi canevas fiche suivi projet innovation',
  [MonitoringType.AccountantRequest]: "Demande de pièces à l'expert-comptable",
  [MonitoringType.TimeslotRequest]: 'Demande feuilles de temps au client',
  [MonitoringType.OperationRequest]: 'Demande opération de recherche ou inno',
  [MonitoringType.ChecklistTimeslot]: 'Feuille de temps',
  [MonitoringType.ChecklistOperation]: 'Opération de recherche',
  [MonitoringType.ChecklistDeliveredAtPlace]: 'DAP',
  [MonitoringType.ChecklistTechWatch]: 'Veille technologique',
  [MonitoringType.ChecklistPatent]: 'Brevet',
  [MonitoringType.ChecklistSubcontractor]: 'Factures sous-traitants',
  [MonitoringType.ChecklistPayslip]: 'Fiche de paie',
  [MonitoringType.ChecklistPayroll]: 'Récapitulatif de paie',
  [MonitoringType.ChecklistCV]: 'CV',
  [MonitoringType.ChecklistDegree]: 'Diplômes',
  [MonitoringType.ChecklistApproval]: 'Agréments sous-traitants',
  [MonitoringType.EligibleProjectCIR]: 'Projet(s) éligible(s) CIR',
  [MonitoringType.EligibleProjectCII]: 'Projet(s) éligible(s) CII',
  [MonitoringType.ReportPrior]: 'Rédaction rapport préalable',
  [MonitoringType.ReportReview]: 'Relecture et validation du rapport préalable',
  [MonitoringType.ReportSent]: 'Envoi rapport préalable au client',
  [MonitoringType.ReportValidated]: 'Validation du rapport préalable par le client',
  [MonitoringType.AmountClaimedCIR]: 'Montant de CIR réclamé',
  [MonitoringType.AmountClaimedCII]: 'Montant de CII réclamé',
  [MonitoringType.AmountAwardedCIR]: 'Montant de CIR accordé',
  [MonitoringType.AmountAwardedCII]: 'Montant de CII accordé',
  [MonitoringType.Cerfa]: 'Édition des Cerfa',
  [MonitoringType.PreparatoryMeeting]: "RDV préparatoire à l'élaboration du dossier justificatif",
  [MonitoringType.DraftFile]: 'Ébauche du dossier justificatif',
  [MonitoringType.FinalFile]: 'Dossier justificatif final',
  [MonitoringType.ControlRequest]: 'Demande de contrôle du CIR/CII',
  [MonitoringType.ControlProofSent]: 'Justificatifs envoyés',
  [MonitoringType.ControlResponse]: 'Réponse reçue',
  [MonitoringType.PaymentTaxSettlement]: 'Imputation IS',
  [MonitoringType.PaymentRefund]: 'Demande de remboursement',
  [MonitoringType.PaymentClientReminder]: "Relance client (le client a t'il reçu le CIR?)",
  [MonitoringType.PaymentDateCIR]: 'Date paiement du CIR',
  [MonitoringType.PaymentBilling]: 'Facture',
  [MonitoringType.PaymentLastReminder]: 'Date de la dernière relance client',
  [MonitoringType.PaymentDateInvoice]: 'Date de paiement facture',
  [MonitoringType.ProcessTimeCerfa ]: 'Temps entre édition du Cerfa et paiement CIR',
  [MonitoringType.ProcessTimeBilling]: 'Temps entre édition de la facture et paiement',
  [MonitoringType.ProcessTimeInvoicing]: 'Temps entre brief client et paiement',

  //Mission Financement
  [MonitoringType.KickOff]: 'Kick-off de la mission',
  [MonitoringType.Pitch]: 'Rédaction du pitch',
  [MonitoringType.FinancierSent]: 'Envoi au financeur',
  [MonitoringType.FinancierReturn]: 'Retour du financeur',
  [MonitoringType.FinancierMeeting]: 'RDV avec le financeur',
  [MonitoringType.FileTechnical]: 'Rédaction dossier technique',
  [MonitoringType.FileFinancial]: 'Rédaction dossier financier',
  [MonitoringType.FileBusiness]: 'Rédaction dossier business',
  [MonitoringType.FinalValidation]: 'Validation finale client',
  [MonitoringType.FinancierReSent]: 'Envoi au financeur',
  [MonitoringType.InformationRequest]: 'Demande informations complémentaires',
  [MonitoringType.InformationResponse]: 'Réponse informations complémentaires',
  [MonitoringType.AgreementOral]: 'Accord oral',
  [MonitoringType.AgreementWritten]: 'Accord écrit',
  [MonitoringType.Payment1]: '1er versement',
  [MonitoringType.Payment2]: '2ème versement',
  [MonitoringType.Balance]: 'Rédaction de la demande de solde',
  [MonitoringType.BalanceSent]: 'Envoi de la demande de solde au financeur',
  [MonitoringType.BalancePayment]: 'Paiement du solde',
  [MonitoringType.ArchivedNotification]: 'Notification accord écrit archivée',
  [MonitoringType.ArchivedConvention]: 'Convention archivée',
  [MonitoringType.AmountRequested]: 'Montant demandé',
  [MonitoringType.AmountGranted]: 'Montant accordé',
  [MonitoringType.ExpensesDate]: 'Date de prise en compte des dépenses',
  [MonitoringType.ProgramEndDate]: 'Date de fin de programme',
  [MonitoringType.ModalityConvention]: 'Modalités de la convention',
  [MonitoringType.ChecklistKbis]: 'Kbis',
  [MonitoringType.ChecklistStatutes]: 'Statuts certifiés conformes',
  [MonitoringType.ChecklistRIB]: 'RIB',
  [MonitoringType.ChecklistTaxReturn1]: 'Liasse fiscale n-1',
  [MonitoringType.ChecklistTaxReturn2]: 'Liasse fiscale n-2',
  [MonitoringType.ChecklistTaxReturn3]: 'Liasse fiscale n-3',
  [MonitoringType.ChecklistRegularityFiscal]: 'Attestation de régularité fiscale',
  [MonitoringType.ChecklistRegularitySocial]: 'Attestation de régularité sociale',
  [MonitoringType.ChecklistIdentificationLeader]: "Pièces d'identité du dirigeant",
  [MonitoringType.ChecklistFunding]: 'Table de capitalisation',
  [MonitoringType.ChecklistPlanPresentation]: 'Business plan de présentation',
  [MonitoringType.ChecklistPlanFinancial]: 'Business plan financier',
  [MonitoringType.ChecklistActivities]: 'Questionnaire relatif aux activités',
  [MonitoringType.ChecklistSheetSigned]: 'Fiche de demande signée',
  [MonitoringType.ChecklistAnother]: 'Autres pièces non précisées ci-dessus',

  //Mission Autre
  [MonitoringType.CanevasSent]: 'Envoi du canevas au client',
  [MonitoringType.CanevasReturn]: 'Retour du client sur le canevas',
  [MonitoringType.DraftSent]: "Envoi d'un draft commenté au client",
  [MonitoringType.DraftReturn]: 'Retour du client sur le draft',
  [MonitoringType.FinalFileSent]: 'Envoi du dossier finalisé au client',
  [MonitoringType.OrganismSent]: 'Envoi au financeur/organisme',
  [MonitoringType.ConventionStartDate]: "Date d'entrée en vigueur de la convention",
  [MonitoringType.ConventionEndDate]: 'Date de fin de la convention',
};

export function getMissionMonitoringLabel(type: MonitoringType): string {
  return MissionMonitoringLabels[type];
}
