import { subject } from '@casl/ability';
import { IUpdateCredentials } from '../interfaces/credentials.interface';

import { apiService } from './api.service';
import { IAdmin, IAdminLight, ICreateAdmin, IUpdateAdmin } from '../interfaces/admin.interface';
import { Action, Subject } from '../casl/enums';
import { abilityService } from './ability.service';

function get(id: string): Promise<IAdmin> {
  return  apiService.get<IAdmin>(`admins/${id}`);
}

async function getExpertList(): Promise<IAdminLight[]> {
  const { admins } = await apiService.get<{ admins: IAdminLight[] }>('admins/experts');
  return admins;
}

// ids are over fetched to avoid building more backend for now (to do later)
async function getList(ids?: string[]): Promise<IAdmin[]> {
  const { admins } = await apiService.get<{ admins: IAdmin[] }>('admins');
  return ids ? admins.filter(admin => ids.includes(admin.id)) : admins;
}

function create(admin: ICreateAdmin): Promise<IAdmin> {
  return apiService.post<ICreateAdmin, IAdmin>('admins', admin);
}

function update( id: string, admin: IUpdateAdmin): Promise<IAdmin> {
  return  apiService.put<IUpdateAdmin, IAdmin>(`admins/${id}`, admin);
}

async function upsert(admin: ICreateAdmin | ( IUpdateAdmin & { id: string }) ): Promise<IAdmin> {
  if ('id' in admin && admin.id) {
    return update(admin.id, admin);
  }
  return create(admin as ICreateAdmin);
}

async function updateAvatar(adminId: string, dataURL: string) {
  // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript/36183085#36183085
  const res = await fetch(dataURL);
  const blob = await res.blob();
  return apiService.upload<IAdmin>(`admins/${adminId}/avatar`, blob, 'avatar.jpg');
}

function updateCredentials(id: string, credentials: Partial<IUpdateCredentials>): Promise<IAdmin> {
  return apiService.put<Partial<IUpdateCredentials>, IAdmin>(`users/${id}/credentials`, credentials);
}

function can(action : Action, source?: Partial<IAdmin>, field?: keyof IAdmin): boolean {
  const ability = abilityService.getAbility();
  return ability.can(action, source ? subject(Subject.User, source) : Subject.User, field);
}

export const adminService = {
  can,
  create,
  get,
  getExpertList,
  getList,
  update,
  updateAvatar,
  updateCredentials,
  upsert,
};
