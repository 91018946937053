import { MouseEvent } from 'react';
import clsx from 'clsx';

const colors = {
  red: 'btn-red',
};

type Props = {
  color?: keyof typeof colors;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  label?: string;
}

export const OkButton = ({ onClick, label, color } : Props) => (
  <button className={clsx('btn ml-3', color ? colors[color] : 'btn-primary')} onClick={onClick}>
    {label || 'Oui'}
  </button>
);
