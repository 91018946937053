export enum MonitoringResponse {
  Accepted = 'accepted',
  Refused = 'refused',
}

export const MonitoringResponseList: MonitoringResponse[] = Object.values(MonitoringResponse);

export const MonitoringResponseLabels : {[ status in MonitoringResponse ]: string} = {
  [MonitoringResponse.Accepted]: 'Accepté',
  [MonitoringResponse.Refused]: 'Refusé',
};

export function getMonitoringResponseLabel(response: MonitoringResponse): string {
  return MonitoringResponseLabels[response];
}
