import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { getProjectCategoryLabel, ProjectCategory } from '../../../enums/project-category.enum';
import { IWorklogStatus, worklogService } from '../../../services/worklog.service';
import { formatToShortFrench } from '../../../utils/timeslot-dates';
import { NotFound } from '../../general/NotFound';
import { Spinner } from '../../general/Spinner';
import { TextLink } from '../../general/TextLink';

type Props = {
  projectId: string;
};

type DataState = {
  loading?: boolean;
  worklogStatus?: IWorklogStatus;
};

const colors = {
  red: 'bg-red-100 text-red-800 border-red-400',
  blue: 'bg-blue-100 text-blue-800 border-blue-400',
  green: 'bg-green-200 text-green-800 border-green-400',
  yellow: 'bg-yellow-200 text-yellow-800 border-yellow-400',
  gray: 'bg-gray-200 text-gray-800 border-gray-400',
  lightGray: 'bg-gray-50 text-gray-400 border-gray-200',
};

function getBadgeColor(category: string) {
  let badgeColor = colors.gray;
  if (category === ProjectCategory.OperationRD) {
    badgeColor = colors.blue;
  } else if (category === ProjectCategory.ProjetInnovant) {
    badgeColor = colors.green;
  } else if (category === ProjectCategory.Autre) {
    badgeColor = colors.lightGray;
  }
  return badgeColor;
}

export const WorklogListStatus = ({ projectId }: Props) => {
  const [{ loading, worklogStatus }, setData] = useState<DataState>({ loading: true });

  useEffect(() => {
    setData({ loading: true });
    worklogService
      .getStatus(projectId)
      .then(worklogStatus => setData({ worklogStatus }));
  }, [projectId]);

  if (loading) {
    return <Spinner />;
  }

  if (!worklogStatus) {
    return <NotFound title="Cahier de suivi introuvable" />;
  }

  return (
    <li className="col-span-1 bg-white rounded-lg divide-y divide-gray-200 border border-gray-200">
      <div>
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1">
            <div className="flex items-center space-x-3">
              <TextLink to={`/clients/${worklogStatus.project.clientId}/projects/${worklogStatus.project.rootId}`} className="text-sm font-medium">
                {worklogStatus.project.name}
              </TextLink>
              <span className={clsx('flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium rounded-full', getBadgeColor(worklogStatus.project.category))}>
                {getProjectCategoryLabel(worklogStatus.project.category)}
              </span>
            </div>
            <p className="mt-1 text-gray-500 text-xs truncate">
              <i className="far fa-calendar text-sm"/>
              <span className="text-xs">&nbsp;
                {formatToShortFrench(worklogStatus.project.start)}
                 &nbsp;➟&nbsp;
                { worklogStatus.project.end ? formatToShortFrench(worklogStatus.project.end!) : <>∞</> }
              </span>
            </p>
          </div>
          <p className="flex text-xs font-medium text-gray-500">{worklogStatus.project.expertIds.length === 0 && <i title="pas d'expert affecté" className="fas fa-exclamation-triangle fa-2x text-orange-400"/>}</p>
        </div>
        <p className="text-xs text-red-500 text-center h-4 min-h-full pb-1">{worklogStatus.project.expertIds.length === 0 && 'Attention aucun expert affecté'}</p>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="-ml-px w-0 flex-1 flex">
            <p
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <i className="fas fa-pen-nib text-gray-400"/>
              <span
                className="ml-3">{worklogStatus.summary.dirty} {worklogStatus.summary.dirty > 1 ? 'brouillons' : 'brouillon'}</span>
            </p>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <p
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <i className="fas fa-book text-gray-400"/>
              <span
                className="ml-3">{worklogStatus.summary.partialValidated} {worklogStatus.summary.partialValidated > 1 ? 'partiels' : 'partiel'}</span>
            </p>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <p
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <i className="fas fa-thumbs-up text-gray-400"/>
              <span
                className="ml-3">{worklogStatus.summary.validated} {worklogStatus.summary.validated > 1 ? 'validés' : 'validé'}</span>
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};
