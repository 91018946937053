import { useCallback, useContext, useMemo, useState } from 'react';
import { addMonths } from 'date-fns';

import { AccountContext } from '../contexts/account.context';
import { BoxForm } from './BoxForm';
import { FormField } from './form/Form';
import { UserRoleClientList } from '../../enums/user-role.enum';
import { Action } from '../../casl/enums';
import { getRoleLabel } from '../../casl/roles-label';
import { userService } from '../../services/user.service';
import { IUser } from '../../interfaces/user.interface';
import { isEmail } from '../../utils/is-email';
import { updateValidators } from '../../utils/update-validators';
import { AvatarForm } from './AvatarForm';
import { UserDocumentsForm } from './UserDocumentsForm';

function getFields(clientId: string, user?: IUser) {
  const updating = Boolean(user);
  const roles = UserRoleClientList.filter(role => userService.can(Action.Create, { role, clientId }));

  let fields: FormField[] = [
    {
      name: 'id',
      type: 'hidden',
      defaultValue: user?.id,
    },
    {
      name: 'clientId',
      type: 'hidden',
      defaultValue: clientId,
    },
    {
      name: 'role',
      label: 'Type',
      registerOptions: { required: true },
      type: 'select',
      options: roles.map(role => ({ value: role, label: getRoleLabel(role) })),
      cols: 4,
      emptyCols: 4
    },
    {
      name: 'lastname',
      label: 'Nom',
      autoComplete: 'family-name',
      registerOptions: { required: true }
    },
    {
      name: 'firstname',
      label: 'Prénom',
      autoComplete: 'given-name',
      registerOptions: { required: true }
    },
    {
      name: 'phone',
      label: 'Téléphone',
      autoComplete: 'tel',
      type: 'phone',
      registerOptions: {
        validate: updateValidators(false, { Number1014: (value) => value.length > 0 ? /^[0-9]{10,14}$/.test(value) : true })
      },
    cols: updating ? 4 : undefined,
    emptyCols: updating ? 4 : undefined,
    },
  ];

  if (!updating) {
    fields.push(
      {
        name: 'email',
        label: 'Email',
        autoComplete: 'email',
        registerOptions: {
          required: true,
          validate: {
            Email: isEmail
          }
        }
      },
      {
        name: 'password',
        label: 'Mot de passe',
        autoComplete: 'new-password',
        placeholder: undefined,
        type: 'password',
        registerOptions: {
          required: !updating,
          validate: updateValidators(false, {
            Length: (value) => value.length >= 12,
            Special: (value) => /[[\]^_!"#@$%&'()*+,\-./\\:;{}<>=|~?]/.test(value),
            Maj: (value) => /[A-Z]/.test(value),
            Min: (value) => /[a-z]/.test(value),
            Number: (value) => /[0-9]/.test(value)
          })
        },
        cols: 4,
        emptyCols: 4
      },
    );
  }
  fields.push(
    {
      name: 'diploma',
      label: 'Diplôme',
      cols: 4,
    },
    {
      name: 'diplomaDate',
      label: 'Obtenu le',
      type: 'date',
      maxDate: new Date(),
      cols: 4,
    },
    {
      name: 'youngDoctor',
      label: 'Jeune docteur',
      type: 'checkbox',
      cols: 8,
    },
    {
      name: 'job',
      label: 'Fonction',
      autoComplete: 'organization-title',
      registerOptions: {
        required: true,
      },
      emptyCols: 4
    },
    {
      name: 'startDate',
      label: 'Date d\'embauche',
      type: 'date',
      maxDate: addMonths(new Date(), 3)
    },
    {
      name: 'endDate',
      label: 'Date de fin',
      type: 'date',
      maxDate: new Date()
    },
  );

  // filter form on modifiable fields only and set default value
  if (user) {
    fields = fields
      .filter(field => field.type === 'hidden' || userService.can(Action.Update, user, field.name as keyof IUser))
      .map(field => {
        const name = field.name as keyof IUser;
        return {
          ...field,
          defaultValue: user[name] || undefined
        };
      });
  }
  return fields;
}

type Props = {
  clientId: string;
  user?: IUser;
  onSave: (user: IUser) => void;
};

export const UserForm = ({ clientId , user, onSave }: Props) => {
  const [currentUser, setCurrentUser] = useState(user);
  const { account, setAccount } = useContext(AccountContext);
  const fields = useMemo(() => getFields(clientId , user), [clientId , user]);

  const updated = useCallback((user: IUser) => {
    if (account.id === user.id) {
      setAccount(user);
    }
    setCurrentUser(user);
    return user;
  }, [account.id, setAccount]);

  const submit = useCallback((data: any) => {
    return userService
      .upsert(data)
      .then(updated)
      .then(onSave);
  }, [updated, onSave]);

  return (
    <>
      { currentUser && <AvatarForm user={currentUser} onSave={updated} /> }
      { currentUser &&  <UserDocumentsForm user={currentUser} onSave={updated} />}
      <BoxForm
        title={user ? 'Données personnelles' : 'Création d\'un nouvel utilisateur'}
        labelOk={ user ? 'Modifier' : 'Créer' }
        fields={fields} submit={submit} backOnCancel />
    </>
  );
};
