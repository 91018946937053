import { apiService } from './api.service';
import { IMedia } from '../interfaces/media.interface';

function addImageToClient(clientId: string, blob: Blob, filename: string) {
  return apiService.upload<IMedia>(`medias/clients/${clientId}/images`, blob, filename);
}

function deleteMedia(media: IMedia) {
  return apiService.delete<{ success: boolean }>(media.url);
}

export const mediaService = {
  addImageToClient,
  deleteMedia,
};
