import { useMemo } from 'react';

import { NoData } from '../../general/NoData';
import { Table, TableColumn } from '../../general/Table';
import { IMissionAdmin } from '../../../interfaces/mission-admin.interface';
import { getMissionAdminFunctionLabel } from '../../../enums/mission-admin-function.enum';
import { useAdmins } from '../../../hooks/use-admins';
import { Spinner } from '../../general/Spinner';
import { getRoleLabel } from '../../../casl/roles-label';

type Props = {
  admins: IMissionAdmin[];
}
export const MissionAdminList = ({ admins: missionAdmins }: Props) => {
  const { admins } = useAdmins(missionAdmins.map(tuple => tuple.adminId));

  const columns = useMemo<Array<TableColumn<IMissionAdmin>>>(
    () => [
      { Header: 'Fonction', accessor: 'function', Cell : ({ value }) => getMissionAdminFunctionLabel(value) },
      {
        Header: 'Administrateur',
        accessor: tuple => {
          const admin = admins?.find(admin => admin.id === tuple.adminId);
          if (admin) {
            return `${admin.lastname} ${admin.firstname} (${getRoleLabel(admin.role)})`;
          }
          return 'inconnu';
        }
      },
    ],
    [admins]
  );

  if (!admins) {
    return <Spinner />;
  }

  if(missionAdmins.length === 0) {
    return <NoData>Aucun administrateur</NoData>;
  }
  return <Table columns={columns} data={missionAdmins} />;
};
