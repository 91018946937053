import { useMemo } from 'react';

import { IClientAgreement } from '../../interfaces/client-agreement.interface';
import { IClient } from '../../interfaces/client.interface';
import { NoData } from './NoData';
import { Table, TableColumn } from './Table';
import { formatToLongFrench } from '../../utils/timeslot-dates';

type Props = {
  client: IClient;
}

export const ClientAgreementList = ({ client }: Props) => {
  const columns = useMemo<Array<TableColumn<IClientAgreement>>>(
    () => [
      { Header: 'Nature de la lettre', accessor: 'name' },
      { Header: 'Date de signature', accessor: 'signed', Cell: ({ value }) => formatToLongFrench(value) },
      { Header: 'Engagement', accessor: 'duration', Cell: ({ value }) => `${value} mois` },
    ],
    []
  );

  if (!client.agreements) {
    return <NoData>Aucune lettre de mission</NoData>;
  }

  return <Table columns={columns} data={client.agreements} />;
};
