import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IProject } from '../../../interfaces/project.interface';
import { IWorklog } from '../../../interfaces/worklog.interface';
import { Badge } from '../../general/Badge';
import { WysiwygEditor } from '../../general/form/components/WysiwygEditor';
import { Box } from '../../general/Box';
import { Panel, Panels } from '../../general/Panels';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { mediaService } from '../../../services/media.service';
import { worklogService } from '../../../services/worklog.service';
import { ConfirmationPopup } from '../../general/ConfirmationPopup';
import { showToast } from '../../general/show-toast';
import { getValidationKey, getWorklogEntryTuples, WorklogEntries, WorklogEntryTuple } from '../../../enums/worklog.enum';
import { Action } from '../../../casl/enums';

type Props = {
  project: IProject;
  worklog: IWorklog | undefined;
}

type Inputs = {
  [Key in WorklogEntries]: string;
}

function getInitialStep(worklog: IWorklog | undefined, entries: WorklogEntryTuple[]): WorklogEntries {
  let index = 0;
  if (worklog) {
    index = Math.max(0, entries.findIndex(step => !worklog[step[0]]));
  }
  return entries[index][0];
}

export const WorklogForm = ({ project, worklog: initialWorklog }: Props) => {
  const { control, getValues, reset, formState: { isDirty, dirtyFields } } = useForm<Inputs>();
  const [worklog, setWorklog] = useState(initialWorklog);
  const entries = useMemo(() => getWorklogEntryTuples(project.category), [project.category]);
  const [{ step, nextStep }, setStep] = useState<{step: WorklogEntries, nextStep?: WorklogEntries}>({ step: getInitialStep(worklog, entries) });
  const validationKey = getValidationKey(step);
  const canValidate = worklogService.canFromProject(Action.Update, project, 'stateValidated');
  const isValidated = worklog ? worklog[validationKey] : false;

  const uploadHandler = useCallback((blob: Blob, filename: string) => mediaService.addImageToClient(project.clientId, blob, filename), [project]);

  const resetUsing = useCallback((worklog?: IWorklog) => reset({
    goal: worklog?.goal || '',
    action: worklog?.action || '',
    state: worklog?.state || '',
    result: worklog?.result || '',
    conclusion: worklog?.conclusion || '',
  }), [reset]);

  const submit = useCallback((validate = false) => {
    const values = getValues();
    const data: Partial<IWorklog> = {
      [step]: values[step],
    };
    if (validate) {
      data[validationKey] = true;
    }
    worklogService
      .update(project.id, data)
      .then((worklog) => {
        setStep({ step: getInitialStep(worklog, entries) });
        setWorklog(worklog);
        resetUsing(worklog);
        showToast({ type: 'success' });
      })
      .catch(error => showToast({ error }));
  }, [entries, getValues, project, resetUsing, step, validationKey]);

  const proceedConfirmation = useCallback(() => {
    if (nextStep) {
      setStep({ step: nextStep });
      resetUsing(worklog);
    }
  }, [nextStep, setStep, resetUsing, worklog]);

  const changePanel = useCallback((target: WorklogEntries) => {
    if (step !== target) {
      setStep(isDirty ? { step, nextStep: target } : { step: target });
    }
  }, [step, isDirty]);

  const cancel = useCallback(() => resetUsing(worklog), [resetUsing, worklog]);

  return (
    <Box title="Cahier de suivi">
      <p className="block md:hidden text-right text-xs">Du projet: <span className="font-semibold">{project.name}</span></p>
      {
        entries.length > 1 && (
          <>
            <Panels className="my-10 hidden md:flex">
              {
                entries.map(([stepValue, label]) => {
                  const isDirty = (step === stepValue) && dirtyFields[stepValue];
                  return (
                    <Panel
                      key={stepValue}
                      active={step === stepValue}
                      grayed={!dirtyFields[stepValue]}
                      icon={isDirty ? 'fas fa-edit' : undefined}
                      checked={Boolean(worklog && worklog[stepValue]) && !isDirty}
                      onClick={() => changePanel(stepValue)}
                    >{label}</Panel>
                  );
                })
              }
            </Panels>
            <select className="my-10 flex md:hidden" onChange={e => changePanel(e.target.value as WorklogEntries)} value={step}>
              { entries.map(([stepValue, label]) => <option key={stepValue} value={stepValue}>{label}</option>)}
            </select>
          </>
        )
      }

      <form>
        {
          entries.map(([stepValue, label]) => (
            <div key={stepValue} className={clsx(step !== stepValue && 'hidden')}>
              <div className="relative">
                <h2 className="flex text-lg leading-6 font-medium text-gray-900 mb-4">{label}</h2>
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <Badge color={isValidated ? 'green' : 'gray'}>{isValidated ? 'Validé' : 'Non validé'}</Badge>
                </div>
              </div>
              <WysiwygEditor field={{ type: 'wysiwyg', name: stepValue, label: '', outputFormat: 'html', defaultValue: worklog ? worklog[stepValue] || '' : '', uploadHandler }} control={control} />
            </div>
          ))
        }
      </form>
      <ButtonBar>
        <button className="btn btn-cancel ml-3" disabled={!isDirty} onClick={cancel}>Annuler</button>
        {
          (!canValidate || !isValidated) && <button type="submit" className="btn btn-primary ml-3" disabled={!isDirty} onClick={() => submit()}>Enregistrer</button>
        }
        {
          (canValidate) && <button type="submit" className="btn btn-primary ml-3" disabled={!isDirty && isValidated} onClick={() => submit(true)}>{ isValidated ? 'Mettre à jour' : 'Valider'}</button>
        }
      </ButtonBar>
      { nextStep &&
        <ConfirmationPopup title="Attention !" onNo={() => setStep({ step })} onYes={proceedConfirmation}>
          <p className="text-sm text-gray-500">
            Des modifications n&apos;ont pas été enregistrées, êtes-vous sûr de vouloir continuer et perdre ces modifications ?
          </p>
        </ConfirmationPopup>
      }
    </Box>
  );
};
