import { Page } from '../../general/Page';
import { Box } from '../../general/Box';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { AddButton } from '../../general/buttons/AddButton';
import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';
import { ProjectList } from '../components/ProjectList';

export const ProjectListPage = () => (
  <Page>
    <Box title="Liste des projets" transparent>
      <ButtonBar>
        <Can I={Action.Create} a={Subject.Project}>
          <AddButton to="/projects/create" label="Créer un projet" />
        </Can>
      </ButtonBar>
      <ProjectList />
    </Box>
  </Page>
);
