import { useContext } from 'react';
import clsx from 'clsx';

import { AccountContext } from '../contexts/account.context';
import { IProject } from '../../interfaces/project.interface';
import { IAdminProject } from '../../interfaces/admin-project.interface';
import { TextLink } from './TextLink';

type Props = {
  projects: IProject[] | IAdminProject[];
  activeId?: string;
}

function alphaSort(a: IProject, b: IProject) {
  return a.name < b.name ? -1 : 1;
}

const ProjectItem = ({ activeId, project, projects }: Props & { project: IProject | IAdminProject }) => {
  const { isAdmin } = useContext(AccountContext);
  const children = projects.filter(items => items.parentId === project.id);
  children.sort(alphaSort);
  return (
    <>
      <TextLink to={isAdmin ? `/clients/${project.clientId}/projects/${project.id}` : `/projects/${project.id}`} className='flex items-center group px-3 py-2 text-sm font-medium rounded-md hover:bg-gray-50'>
        <i className={ clsx('fas mr-2', project.isTask ? 'fa-file text-lg' : 'fa-folder text-xl', project.id === activeId ? 'text-purple-400 group-hover:text-purple-500' : 'text-gray-400 group-hover:text-gray-500') } />
        <span className={ clsx('truncate', project.id === activeId && 'text-purple-500') }>{project.name}</span>
      </TextLink>
      <div className='pl-4'>
        { children.map(project => <ProjectItem key={project.id} project={project} projects={projects} activeId={activeId} />) }
      </div>
    </>
  );
};

export const ProjectTree = ({ projects, activeId }: Props) => {
  const parents = projects.filter(project => !project.parentId);
  parents.sort(alphaSort);
  return (
    <nav className="space-y-1" aria-label="Sidebar">
      { parents.map(project => <ProjectItem key={project.id} project={project} projects={projects} activeId={activeId} />) }
    </nav>
  );
};
