import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { authenticationService } from './authentication.service';
import { PromiseSyncer } from '../utils/promise-syncer';
import { showToast } from '../components/general/show-toast';

const api = `${process.env.REACT_APP_API_URL || ''}`;

function headers(headers: any = {}) {
  const token = authenticationService.getToken();
  if (token) {
    return { ...headers, Authorization: `Bearer ${token}` };
  }
  return headers;
}

async function refreshToken() {
  try {
    const { data: { access_token } } = await axios.request<void, AxiosResponse<{ access_token: string }>>({
      url: `${api}/auth/refresh_token`,
      method: 'POST',
      headers: headers({ 'Content-Type': 'application/json' }),
      withCredentials: true,
    });
    authenticationService.replaceToken(access_token);
    return true;
  } catch (err) {
    return false;
  }
}

const refreshTokenSynced = PromiseSyncer.sync(refreshToken);

async function request<Payload,ResponsePayload>(config: AxiosRequestConfig): Promise<AxiosResponse<ResponsePayload>> {
  try {
    return await axios.request<Payload, AxiosResponse<ResponsePayload>>({ ...config, withCredentials: true });
  } catch (err) {
    if (err.response?.status === 401 && !config.url?.includes('/auth/')) {
      if (await refreshTokenSynced()) {
        return await axios.request<Payload, AxiosResponse<ResponsePayload>>({ ...config, headers: headers(config.headers), withCredentials: true });
      } else {
        showToast({ type: 'error', title:'Session expirée', content: 'Veuillez vous reconnecter' });
        authenticationService.clearToken();
      }
    }
    throw err;
  }
}

async function upload<ResponsePayload>(endpoint: string, file: Blob | File, fileName?: string) {
  const formData = new FormData();
  formData.append('file', file, fileName);
  const { data } = await request<any, ResponsePayload>({
    url: `${api}/${endpoint}`,
    method: 'POST',
    data: formData,
    headers: headers({ 'Content-Type': 'multipart/form-data' }),
  });
  return data;
}

async function get<ResponsePayload = Record<string, unknown>>(endpoint: string): Promise<ResponsePayload> {
  const { data } = await request<void, ResponsePayload>({
    url: `${api}/${endpoint}`,
    method: 'GET',
    headers: headers({ 'Content-Type': 'application/json' }),
  });
  return data;
}

async function del<ResponsePayload = Record<string, unknown>>(endpoint: string): Promise<ResponsePayload> {
  const { status, data } = await request<void, ResponsePayload>({
    url: `${api}/${endpoint}`,
    method: 'DELETE',
    headers: headers({ 'Content-Type': 'application/json' }),
  });
  if (status >= 200 && status < 300) {
    return data;
  }
  throw new Error(`Unknown error (axios status code ${status})`);
}

async function send<Payload, ResponsePayload>(method: 'POST' | 'PUT', endpoint: string, payload: Payload): Promise<ResponsePayload> {
  const { data } = await request<Payload, ResponsePayload>({
    url: `${api}/${endpoint}`,
    method,
    data: payload,
    headers: headers({ 'Content-Type': 'application/json' }),
  });
  return data;
}

async function post<Payload, Response = Record<string, unknown>>(endpoint: string, payload: Payload): Promise<Response> {
  return send<Payload, Response>('POST', endpoint, payload);
}

async function put<Payload, Response = Record<string, unknown>>(endpoint: string, payload: Payload): Promise<Response> {
  return send<Payload, Response>('PUT', endpoint, payload);
}

export const apiService = {
  get,
  post,
  delete: del,
  put,
  upload,
};
