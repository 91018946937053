import { useCallback, useContext, useMemo, useState } from 'react';

import { adminService } from '../../services/admin.service';
import { UserRoleAdminList } from '../../enums/user-role.enum';
import { Action } from '../../casl/enums';
import { FormField } from './form/Form';
import { getRoleLabel } from '../../casl/roles-label';
import { BoxForm } from './BoxForm';
import { IAdmin } from '../../interfaces/admin.interface';
import { isEmail } from '../../utils/is-email';
import { updateValidators } from '../../utils/update-validators';
import { AvatarForm } from './AvatarForm';
import { AccountContext } from '../contexts/account.context';

function getFields(admin?: IAdmin) {
  const updating = Boolean(admin);
  const roles = UserRoleAdminList.filter(role => adminService.can(Action.Create, { role }));

  let fields: FormField[] = [
    {
      name: 'id',
      type: 'hidden',
      defaultValue: admin?.id,
    },
    {
      name: 'role',
      label: 'Type',
      registerOptions: { required: true },
      type: 'select',
      options: roles.map(role => ({ value: role, label: getRoleLabel(role) })),
      cols: 4,
      emptyCols: 4
    },
    {
      name: 'lastname',
      label: 'Nom',
      autoComplete: 'family-name',
      registerOptions: { required: true }
    },
    {
      name: 'firstname',
      label: 'Prénom',
      autoComplete: 'given-name',
      registerOptions: { required: true }
    },
];

  if (!updating) {
    fields.push(
      {
        name: 'email',
        label: 'Email',
        autoComplete: 'email',
        registerOptions: {
          required: true,
          validate: {
            Email: isEmail
          }
        }
      },
      {
        name: 'password',
        label: 'Mot de passe',
        autoComplete: 'new-password',
        placeholder: undefined,
        type: 'password',
        registerOptions: {
          required: true,
          validate: updateValidators(false, {
            Length: (value) => value.length >= 12,
            Special: (value) => /[[\]^_!"#@$%&'()*+,\-./\\:;{}<>=|~?]/.test(value),
            Maj: (value) => /[A-Z]/.test(value),
            Min: (value) => /[a-z]/.test(value),
            Number: (value) => /[0-9]/.test(value)
          })
        }
      },
    );
  }
  fields.push(
    {
      name: 'phone',
      label: 'Téléphone',
      autoComplete: 'tel',
      type: 'phone',
      registerOptions: {
        validate: updateValidators(false, { Number1014: (value) => value.length > 0 ? /^[0-9]{10,14}$/.test(value) : true })
      }
    },
    {
      name: 'job',
      label: 'Fonction',
      autoComplete: 'organization-title',
      registerOptions: {
        required: true,
      },
      emptyCols: 4
    },
  );

  // filter form on modifiable fields only and set default value
  if (admin) {
    fields = fields
      .filter(field => field.type === 'hidden' || adminService.can(Action.Update, admin, field.name as keyof IAdmin))
      .map(field => {
        const name = field.name as keyof IAdmin;
        return {
          ...field,
          defaultValue: admin[name] || undefined
        };
      });
  }
  return fields;
}

type Props = {
  admin?: IAdmin;
  onSave: (admin: IAdmin) => void;
};

export const AdminForm = ({ admin, onSave }: Props) => {
  const [currentUser, setCurrentUser] = useState(admin);
  const { account, setAccount } = useContext(AccountContext);
  const fields = useMemo(() => getFields(admin), [admin]);

  const updated = useCallback((admin: IAdmin) => {
    if (account.id === admin.id) {
      setAccount(admin);
    }
    setCurrentUser(admin);
    return admin;
  }, [account.id, setAccount]);

  const submit = useCallback((data: any) => {
    return adminService
        .upsert(data)
        .then(updated)
        .then(onSave);
  }, [updated, onSave]);

  return <>
    { currentUser && <AvatarForm user={currentUser} onSave={updated} /> }
    <BoxForm
        title={ admin ? `${admin.firstname} ${admin.lastname}` : 'Création d\'un nouvel administrateur' }
        labelOk={ admin ? 'Modifier' : 'Créer' }
        fields={fields} submit={submit} backOnCancel />
  </>;

};
