import { useEffect, useState } from 'react';

import { IAdmin } from '../interfaces/admin.interface';
import { adminService } from '../services/admin.service';

type DataState = {
  loading: boolean;
  admins?: IAdmin[];
  error?: any;
};

export function useAdmins(ids?: string[]) {
  const [{ loading, admins, error }, setState] = useState<DataState>({ loading: true });

  useEffect(() => {
    adminService
      .getList(ids)
      .then(admins => setState({ admins, loading: false }))
      .catch(error => setState({ error, loading: false }));
  }, []);

  return { loading, admins, error };
}
