import { useRef } from 'react';

import { getWorklogEntryTuples } from '../../enums/worklog.enum';
import { IProject } from '../../interfaces/project.interface';
import { IWorklog } from '../../interfaces/worklog.interface';
import { NoData } from './NoData';

type Props = {
  project: IProject;
  worklog: IWorklog | undefined;
  className?: string;
}

function completeIFrameDoc(srcDoc: string): string {
  return `<!DOCTYPE html>
<html>
  <head>
  <style>
  table {
     border-width:1px; 
     border-style:solid; 
     border-color:black;
     width:100%;
     border-collapse: collapse;
   }
  td { 
     border-width:1px;
     border-style:solid; 
     border-color:#000000;
   }
  </style>
  </head>
  <body>${srcDoc}</body>
</html>`;
}

const AutoHeightIframe = ({ srcDoc }: {srcDoc: string}) => {
  const ref = useRef<HTMLIFrameElement>(null);

  function resizeIframe() {
    const iframe = ref.current;
    if (iframe) {
      const height = iframe.contentWindow?.document.body.scrollHeight;
      if (height) {
        // https://stackoverflow.com/a/20722176/722096
        iframe.style.height = '0';
        iframe.style.height = `${height + 25}px`;
      }
    }
  }

  setTimeout(resizeIframe, 500);
  return <iframe style={{ width: '100%' }} ref={ref} srcDoc={completeIFrameDoc(srcDoc)} onLoad={resizeIframe} frameBorder="0" />;
};

export const Worklog = ({ project, worklog, className }: Props) => {
  if (!worklog) {
    return <NoData>Ce cahier est vide</NoData>;
  }

  const entries = getWorklogEntryTuples(project.category);

  return (
    <div className={className}>
      {
        entries.map(([key, label]) => (
          <div key={key} className="sm:col-span-2 mb-6">
            <dt className="text-sm font-medium text-gray-500">
              {label}
            </dt>
            {
              worklog[key] && (
                <dd className="text-sm text-gray-900 m-2 p-2 border rounded border-gray-200">
                  <AutoHeightIframe srcDoc={worklog[key]} />
                </dd>
              )
            }
          </div>
        ))
      }
    </div>
  );
};
