import { FC } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
}

export const ButtonBar: FC<Props> = ({ className, children }) => (
  <div className={clsx('flex flex-shrink justify-end', className)}>{children}</div>
);
