import { useHistory, useLocation } from 'react-router-dom';

export function useQuery() {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  function updateQuery(values: Record<string, any>) {
    const query = new URLSearchParams(location.search);
    for (const [key, value] of Object.entries(values)) {
      query.set(key, value);
    }
    history.push({
      pathname: location.pathname,
      search: `?${query.toString()}`
    });
  }

  return { query, updateQuery };
}
