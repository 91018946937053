import { FC } from 'react';

type Props = {
  label: string;
  'full-span'?: boolean;
}

export const Item: FC<Props> = ({ label, children, 'full-span': fullSpan }) => (
  <div className={fullSpan ? 'sm:col-span-2' : 'col-span-1' }>
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 text-sm text-gray-900">{children}</dd>
  </div>
);
