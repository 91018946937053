import { useCallback, useEffect, useState } from 'react';

import { IUser } from '../../interfaces/user.interface';
import { IAdmin } from '../../interfaces/admin.interface';
import { Box } from './Box';
import { DropAndCropImage, useDropAndCropImage } from './DropAndCropImage';
import { ButtonBar } from './buttons/ButtonBar';
import { userService } from '../../services/user.service';
import { getMediaUrl } from '../../utils/get-media-url';
import { isUser } from '../../type-guards/is-user.type-guard';
import { isAdmin } from '../../type-guards/is-admin.type-guard';
import { adminService } from '../../services/admin.service';

type Props =
    | { user: IUser; onSave: (user: IUser) => void; }
    | { user: IAdmin; onSave: (user: IAdmin) => void; };

export const AvatarForm = ({ user, onSave }: Props) => {
  const url = getMediaUrl(user.avatar?.url);
  const [update, setUpdate] = useState(false);
  const { getDataURL, getProps, isDirty, reset } = useDropAndCropImage();

  useEffect(() => setUpdate(!url), [url]);

  const submit = useCallback(() => {
    if (isUser(user)) {
      userService
          .updateAvatar(user.id, getDataURL())
          .then(onSave as (user:IUser)=>void);
    }
    if (isAdmin(user)) {
      adminService
          .updateAvatar(user.id, getDataURL())
          .then(onSave as (user:IAdmin)=>void);
    }
  }, [user, getDataURL, onSave]);

  const cancel = useCallback(() => {
    if (url) {
      setUpdate(false);
    } else {
      reset();
    }
  }, [url, reset]);

  return (
    <Box title={`${user.firstname} ${user.lastname}`}>
      {
        url && !update ?
          <div className="flex justify-center">
            <img className="h-24 w-24 rounded-full" src={url} alt={`${user.firstname} ${user.lastname}`}/>
          </div>
          :
          <DropAndCropImage {...getProps()} />
      }
      <ButtonBar>
        { update && <button key="btn-cancel" className="btn ml-3" disabled={!(isDirty || url)} onClick={cancel}>Annuler</button> }
        { update && <button key="btn-ok" className="btn btn-primary ml-3" disabled={!isDirty} onClick={submit}>Enregistrer</button> }
        { !update && <button key="btn-ok" className="btn" onClick={() => setUpdate(true)}>Modifier</button> }
      </ButtonBar>
    </Box>
  );
};
