import { Page } from '../../general/Page';
import { ClientList } from '../ClientList';
import { Box } from '../../general/Box';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { AddButton } from '../../general/buttons/AddButton';
import { Action, Subject } from '../../../casl/enums';
import { Can } from '../../../casl/can';

export const ClientListPage = () => (
  <Page>
    <Box title='Liste des clients' transparent>
      <ButtonBar>
        <Can I={Action.Create} a={Subject.Client}>
            <AddButton to="/clients/create" />
        </Can>
      </ButtonBar>
      <ClientList />
    </Box>
  </Page>
);
