import { Control, FieldErrors } from 'react-hook-form';

import { Box } from '../../../general/Box';
import { MissionField } from './components/MissionField';
import { IMission } from '../../../../interfaces/mission.interface';
import { getMissionMonitoringLabel, MonitoringType } from '../../../../enums/mission-monitoring.enum';
import { MonitoringInput } from './components/MonitoringInput';
import { CheckListDateInput } from './components/CheckListDateInput';
import { MissionCategory } from '../../../../enums/mission-category.enum';
import { InputField } from './components/InputField';
import { InputFieldAmount } from './components/InputFieldAmount';

type Props = {
  mission: IMission | undefined;
  control: Control<any>;
  errors: FieldErrors;
};

export const MissionFormFinancing = ( { mission, control, errors } : Props ) => (
  <>
    <Box title="Suivi de mission" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-8 gap-6">
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.KickOff)} error={errors[MonitoringType.KickOff]}>
          <MonitoringInput
            id={MonitoringType.KickOff}
            name={MonitoringType.KickOff}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.KickOff)}
            done={{ label: 'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.Pitch)} error={errors[MonitoringType.Pitch]}>
          <MonitoringInput
            id={MonitoringType.Pitch}
            name={MonitoringType.Pitch}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.Pitch)}
            done={{ label: 'Effectuée ?', incompatible: true, ongoing: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.FinancierSent)} error={errors[MonitoringType.FinancierSent]}>
          <MonitoringInput
            id={MonitoringType.FinancierSent}
            name={MonitoringType.FinancierSent}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.FinancierSent)}
            done={{ label: 'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.FinancierReturn)} error={errors[MonitoringType.FinancierReturn]}>
          <MonitoringInput
            id={MonitoringType.FinancierReturn}
            name={MonitoringType.FinancierReturn}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.FinancierReturn)}
            done={{ label:'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.FinancierMeeting)} error={errors[MonitoringType.FinancierMeeting]}>
          <MonitoringInput
            id={MonitoringType.FinancierMeeting}
            name={MonitoringType.FinancierMeeting}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.FinancierMeeting)}
            done={{ label: 'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.FileTechnical)} error={errors[MonitoringType.FileTechnical]}>
          <MonitoringInput
            id={MonitoringType.FileTechnical}
            name={MonitoringType.FileTechnical}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.FileTechnical)}
            done={{ label: 'Effectuée ?', incompatible: true, ongoing: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.FileFinancial)} error={errors[MonitoringType.FileFinancial]}>
          <MonitoringInput
            id={MonitoringType.FileFinancial}
            name={MonitoringType.FileFinancial}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.FileFinancial)}
            done={{ label: 'Effectuée ?', incompatible: true, ongoing: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.FileBusiness)} error={errors[MonitoringType.FileBusiness]}>
          <MonitoringInput
            id={MonitoringType.FileBusiness}
            name={MonitoringType.FileBusiness}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.FileBusiness)}
            done={{ label: 'Effectuée ?', incompatible: true, ongoing: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.FinalValidation)} error={errors[MonitoringType.FinalValidation]}>
          <MonitoringInput
            id={MonitoringType.FinalValidation}
            name={MonitoringType.FinalValidation}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.FinalValidation)}
            done={{ label: 'Effectuée ?', incompatible: true, ongoing: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.FinancierReSent)} error={errors[MonitoringType.FinancierReSent]}>
          <MonitoringInput
            id={MonitoringType.FinancierReSent}
            name={MonitoringType.FinancierReSent}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.FinancierReSent)}
            done={{ label: 'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.InformationRequest)} error={errors[MonitoringType.InformationRequest]}>
          <MonitoringInput
            id={MonitoringType.InformationRequest}
            name={MonitoringType.InformationRequest}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.InformationRequest)}
            done={{ label: 'Effectuée ?', incompatible: true, ongoing: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.InformationResponse)} error={errors[MonitoringType.InformationResponse]}>
          <MonitoringInput
            id={MonitoringType.InformationResponse}
            name={MonitoringType.InformationResponse}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.InformationResponse)}
            done={{ label: 'Effectuée ?', incompatible: true, ongoing: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
      </div>
    </Box>

    <Box title="Suivi de la contractualisation" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-8 gap-6">
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.AgreementOral)} error={errors[MonitoringType.AgreementOral]}>
          <MonitoringInput
            id={MonitoringType.AgreementOral}
            name={MonitoringType.AgreementOral}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.AgreementOral)}
            done={{ incompatible: true }}
            date={true}
            control={control}
          />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.AgreementWritten)} error={errors[MonitoringType.AgreementWritten]}>
          <MonitoringInput
            id={MonitoringType.AgreementWritten}
            name={MonitoringType.AgreementWritten}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.AgreementWritten)}
            done={{ label: 'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.Payment1)} error={errors[MonitoringType.Payment1]}>
          <MonitoringInput
            id={MonitoringType.Payment1}
            name={MonitoringType.Payment1}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.Payment1)}
            done={{ label: 'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.Payment2)} error={errors[MonitoringType.Payment2]}>
          <MonitoringInput
            id={MonitoringType.Payment2}
            name={MonitoringType.Payment2}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.Payment2)}
            done={{ label: 'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.Balance)} error={errors[MonitoringType.Balance]}>
          <MonitoringInput
            id={MonitoringType.Balance}
            name={MonitoringType.Balance}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.Balance)}
            done={{ label: 'Effectuée ?', incompatible: true, ongoing: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.BalanceSent)} error={errors[MonitoringType.BalanceSent]}>
          <MonitoringInput
            id={MonitoringType.BalanceSent}
            name={MonitoringType.BalanceSent}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.BalanceSent)}
            done={{ label: 'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.BalancePayment)} error={errors[MonitoringType.BalancePayment]}>
          <MonitoringInput
            id={MonitoringType.BalancePayment}
            name={MonitoringType.BalancePayment}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.BalancePayment)}
            done={{ label: 'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.ArchivedNotification)} error={errors[MonitoringType.ArchivedNotification]}>
          <MonitoringInput
            id={MonitoringType.ArchivedNotification}
            name={MonitoringType.ArchivedNotification}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ArchivedNotification)}
            done={{ incompatible: true }}
            date={true}
            control={control}
          />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.ArchivedConvention)} error={errors[MonitoringType.ArchivedConvention]}>
          <MonitoringInput
            id={MonitoringType.ArchivedConvention}
            name={MonitoringType.ArchivedConvention}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ArchivedConvention)}
            done={{ incompatible: true }}
            date={true}
            control={control}
          />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.AmountRequested)} error={errors[MonitoringType.AmountRequested]}>
          <InputFieldAmount
            name={MonitoringType.AmountRequested}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.AmountRequested)}
            control={control}/>
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.AmountGranted)} error={errors[MonitoringType.AmountGranted]}>
          <InputFieldAmount
            name={MonitoringType.AmountGranted}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.AmountGranted)}
            control={control}/>
        </MissionField>
        <MissionField className='col-span-4 sm:col-span-4' label={getMissionMonitoringLabel(MonitoringType.ExpensesDate)} error={errors[MonitoringType.ExpensesDate]} >
          <MonitoringInput
            name={MonitoringType.ExpensesDate}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ExpensesDate)}
            date={{ label: '' }}
            control={control} />
        </MissionField>
        <MissionField className='col-span-4 sm:col-span-4' label={getMissionMonitoringLabel(MonitoringType.ProgramEndDate)} error={errors[MonitoringType.ProgramEndDate]} >
          <MonitoringInput
            name={MonitoringType.ProgramEndDate}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ProgramEndDate)}
            date={{ label: '' }}
            control={control} />
        </MissionField>
        <MissionField className='col-span-4 sm:col-span-8' label={getMissionMonitoringLabel(MonitoringType.ModalityConvention)} error={errors[MonitoringType.ModalityConvention]}>
          <InputField
            name={MonitoringType.ModalityConvention}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ModalityConvention)}
            control={control}/>
        </MissionField>
      </div>
    </Box>

    <Box title="Checking-list des éléments reçus" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-6 gap-6">
       <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.ChecklistKbis)} error={errors[MonitoringType.ChecklistKbis]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistKbis}
            name={MonitoringType.ChecklistKbis}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistKbis)}
            date
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.ChecklistStatutes)} error={errors[MonitoringType.ChecklistStatutes]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistStatutes}
            name={MonitoringType.ChecklistStatutes}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistStatutes)}
            date
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.ChecklistRIB)} error={errors[MonitoringType.ChecklistRIB]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistRIB}
            name={MonitoringType.ChecklistRIB}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistRIB)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.ChecklistTaxReturn1)} error={errors[MonitoringType.ChecklistTaxReturn1]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistTaxReturn1}
            name={MonitoringType.ChecklistTaxReturn1}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistTaxReturn1)}
            year
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.ChecklistTaxReturn2)} error={errors[MonitoringType.ChecklistTaxReturn2]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistTaxReturn2}
            name={MonitoringType.ChecklistTaxReturn2}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistTaxReturn2)}
            year
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.ChecklistTaxReturn3)} error={errors[MonitoringType.ChecklistTaxReturn3]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistTaxReturn3}
            name={MonitoringType.ChecklistTaxReturn3}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistTaxReturn3)}
            year
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.ChecklistRegularityFiscal)} error={errors[MonitoringType.ChecklistRegularityFiscal]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistRegularityFiscal}
            name={MonitoringType.ChecklistRegularityFiscal}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistRegularityFiscal)}
            date
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.ChecklistRegularitySocial)} error={errors[MonitoringType.ChecklistRegularitySocial]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistRegularitySocial}
            name={MonitoringType.ChecklistRegularitySocial}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistRegularitySocial)}
            date
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistIdentificationLeader)} error={errors[MonitoringType.ChecklistIdentificationLeader]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistIdentificationLeader}
            name={MonitoringType.ChecklistIdentificationLeader}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistIdentificationLeader)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistFunding)} error={errors[MonitoringType.ChecklistFunding]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistFunding}
            name={MonitoringType.ChecklistFunding}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistFunding)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistPlanPresentation)} error={errors[MonitoringType.ChecklistPlanPresentation]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistPlanPresentation}
            name={MonitoringType.ChecklistPlanPresentation}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistPlanPresentation)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistPlanFinancial)} error={errors[MonitoringType.ChecklistPlanFinancial]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistPlanFinancial}
            name={MonitoringType.ChecklistPlanFinancial}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistPlanFinancial)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistActivities)} error={errors[MonitoringType.ChecklistActivities]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistActivities}
            name={MonitoringType.ChecklistActivities}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistActivities)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistSheetSigned)} error={errors[MonitoringType.ChecklistSheetSigned]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistSheetSigned}
            name={MonitoringType.ChecklistSheetSigned}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistSheetSigned)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className='col-span-4 sm:col-span-6' label={getMissionMonitoringLabel(MonitoringType.ChecklistAnother)} error={errors[MonitoringType.ChecklistAnother]}>
          <InputField
            name={MonitoringType.ChecklistAnother}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistAnother)}
            control={control}/>
        </MissionField>
      </div>
    </Box>

    <Box title="Paiement client de la mission" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-6 gap-6">
        <MissionField className='col-span-4 sm:col-span-6' label={getMissionMonitoringLabel(MonitoringType.PaymentBilling)} error={errors[MonitoringType.PaymentBilling]} >
          <MonitoringInput
            id={MonitoringType.PaymentBilling}
            name={MonitoringType.PaymentBilling}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentBilling)}
            done={{ label:'Facturable ?' }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        {/*<MissionField className="col-span-4 sm:col-span-6" label={getMissionMonitoringLabel(MonitoringType.PaymentLastReminder)} error={errors[MonitoringType.PaymentLastReminder]}>*/}
        {/*  <MonitoringInput*/}
        {/*    id={MonitoringType.PaymentLastReminder}*/}
        {/*    name={MonitoringType.PaymentLastReminder}*/}
        {/*    defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentLastReminder)}*/}
        {/*    provisionalDate={{ label: 'Date prévisionnelle' }}*/}
        {/*    date={{ label: "Date d'envoi" }}*/}
        {/*    control={control} />*/}
        {/*</MissionField>*/}
        {/*<MissionField className='col-span-4 sm:col-span-3' label={getMissionMonitoringLabel(MonitoringType.PaymentDateInvoice)} error={errors[MonitoringType.PaymentDateInvoice]} >*/}
        {/*  <MonitoringInput*/}
        {/*    name={MonitoringType.PaymentDateInvoice}*/}
        {/*    defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentDateInvoice)}*/}
        {/*    date={{ label: '' }}*/}
        {/*    control={control} />*/}
        {/*</MissionField>*/}
      </div>
    </Box>
  </>
);
