import { ChangeEvent, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { format } from 'date-fns';

import { MissionCategory } from '../../../../../enums/mission-category.enum';
import { MissionDatePicker } from './MissionDatePicker';
import { MissionYear } from './MissionYear';
import {
  getMonitoringFinancingStatusLabel,
  MonitoringFinancingStatus,
  MonitoringFinancingStatusList
} from '../../../../../enums/monitoring-financing-status.enum';

type Props = {
  id?: string;
  name: string;
  defaultValue?: Result;
  control: Control<any>;
  type: MissionCategory | undefined;
  date?: true | {
    label?: string;
    max?: Date;
    min?: Date;
  };
  year?: true | {
    label?: string;
  };
}

type Result = {
  statusFinancing?: MonitoringFinancingStatus;
  incompatible?: boolean;
  dateFinancing?: string;
  yearFinancing?: number;
}

function optionAsObject<T extends Record<string, any>>(value: true | undefined | T): T | Record<string, never> | undefined {
  if (value === true) {
    return {};
  }
  return value;
}

export const CheckListDateInput = ({ id, name, defaultValue, type, control, ...config }: Props) => {
  const selectId = `${name}__select_`;
  const datFormat = 'yyyy-MM-dd';
  const datePickerId1 = `${name}__datepicker_1_`;
  const datePickerId2 = `${name}__datepicker_2_`;

  const initialValue = useMemo(() => {
    if (defaultValue) {
      return defaultValue;
    } else if (type === MissionCategory.Finance) {
      return { statusFinancing: MonitoringFinancingStatus.NotRequested };
    } else {
      return { incompatible: true };
    }
  }, [defaultValue]);

  const date = optionAsObject(config.date);
  const year = optionAsObject(config.year);

  const addLabels = Boolean(date?.label || year?.label);
  const nbsp = '\u00A0';

  const displayDate = [MonitoringFinancingStatus.Received, MonitoringFinancingStatus.Complete];

  return (
    <Controller
      name={name}
      defaultValue={initialValue}
      control={control}
      rules={{
        validate: {
          ...(date ? { RequiredDate: (value: Result) => !displayDate.includes(value.statusFinancing as MonitoringFinancingStatus) || Boolean(value.dateFinancing) || 'La date est requise' } : null),
          ...(year ? { RequiredDate: (value: Result) => !displayDate.includes(value.statusFinancing as MonitoringFinancingStatus) || Boolean(value.yearFinancing) || 'L\'année est requise' } : null),
        }
      }}
      render={({ field: { onChange, value: data } }: { field: { onChange: (value: Result) => void, value: Result }}) => {

        function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
          const status = event.target.value as MonitoringFinancingStatus | '-1';
          if (type === MissionCategory.Finance) {
            if ((status === MonitoringFinancingStatus.Received) || (status === MonitoringFinancingStatus.Complete)) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { incompatible, ...rest } = data;
              onChange({ ...rest, statusFinancing: status as MonitoringFinancingStatus });
            } else if (status === '-1') {
              onChange({ incompatible: true });
            } else {
              onChange({ statusFinancing: status as MonitoringFinancingStatus });
            }
          }
        }

        return (
          <div id={id} className="flex">
            <div className="flex-grow mx-2">
              <select id={selectId} defaultValue={initialValue.incompatible ? '-1' : initialValue.statusFinancing} onChange={onSelectChange}>
                <option value='-1'>Non applicable</option>
                { MonitoringFinancingStatusList.map(status => <option key={status} value={status} >{getMonitoringFinancingStatusLabel(status)}</option>) }
              </select>
            </div>
            {
              date && (
                <div className='flex-1 px-2'>
                  {addLabels && (
                    <label className="block text-sm font-medium text-gray-400" htmlFor={datePickerId1}>
                      {date.label || nbsp}
                      {(displayDate.includes(data.statusFinancing as MonitoringFinancingStatus)) && <span className="text-sm text-red-600"> *</span>}
                    </label>
                  )}
                  <MissionDatePicker
                    id={datePickerId1}
                    date={data.dateFinancing}
                    onChange={(value: Date | null) => onChange({ ...data, dateFinancing: value ? format(value, datFormat) : undefined })}
                    minDate={date.min}
                    maxDate={date.max}
                    disabled={Boolean(date) && !displayDate.includes(data.statusFinancing as MonitoringFinancingStatus)}
                  />
                </div>
              )
            }
            {
              year && (
                <div className='flex-1 px-2'>
                  {addLabels &&
                    <label className="block text-sm font-medium text-gray-400" htmlFor={datePickerId2}>
                      {year.label || nbsp}
                      {(displayDate.includes(data.statusFinancing as MonitoringFinancingStatus)) && <span className="text-sm text-red-600"> *</span>}
                    </label>
                  }
                  <MissionYear
                    id={datePickerId2}
                    year={data.yearFinancing}
                    clear={Boolean(year) && !displayDate.includes(data.statusFinancing as MonitoringFinancingStatus)}
                    onChange={(e) => onChange({ ...data, yearFinancing: Number(e.target.value) || undefined })}
                    disabled={Boolean(year) && !displayDate.includes(data.statusFinancing as MonitoringFinancingStatus)}
                  />
                </div>
              )
            }
          </div>
        );
      }}
    />
  );
};
