import { useInvoice } from '../../../hooks/use-invoice';
import { Spinner } from '../../general/Spinner';
import { NotFound } from '../../general/NotFound';
import { Page } from '../../general/Page';
import { Box } from '../../general/Box';
import { Item } from '../../general/grid/Item';
import { Grid } from '../../general/grid/Grid';
import { formatToLongFrench } from '../../../utils/timeslot-dates';
import { formatCurrency } from '../../../tools/format-currency';
import { getPaymentTypeLabel } from '../../../enums/payment-type.enum';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';
import { EditButton } from '../../general/buttons/EditButton';
import { useMission } from '../../../hooks/use-mission';
import { useEffect, useState } from 'react';
import { IMission } from '../../../interfaces/mission.interface';
import { useClient } from '../../../hooks/use-client';
import { IClient } from '../../../interfaces/client.interface';

type Props = {
  clientId: string;
  invoiceId: string;
}
export const InvoicePage = ({ clientId, invoiceId }: Props) => {
  const { invoice, loading } = useInvoice(invoiceId);

  const { mission: fetchedMission } = useMission(invoice?.missionId);
  const [mission, setMission] = useState<IMission|undefined>(undefined);
  useEffect(() => {
    setMission(fetchedMission);
  }, [fetchedMission]);

  const { client: fetchedClient } = useClient(invoice?.clientId);
  const [client, setClient] = useState<IClient|undefined>(undefined);
  useEffect(() => {
    setClient(fetchedClient);
  }, [fetchedClient]);

  if (loading) {
    return <Spinner />;
  }
  if (!invoice) {
    return <NotFound title="Facture introuvable" />;
  }
  return (
    <Page>
      <Box title='Facture'>
        <ButtonBar>
          <Can I={Action.Update} this={invoice} as={Subject.Invoice}>
            <EditButton to={`/clients/${clientId}/invoices/${invoice.id}/edit`} />
          </Can>
        </ButtonBar>
        <Grid>
          <Item label='Client'>{client ? client.name : ''}</Item>
          <Item label='Mission'>{mission ? mission.name : ''}</Item>
          <Item label='Date prévisionnelle de facturation'>{invoice.provisionalDate ? formatToLongFrench(invoice.provisionalDate) : ''}</Item>
          <Item label='Facturé'>{invoice.ready ? 'Oui' : 'Non'}{invoice.ready ? `, depuis le ${formatToLongFrench(invoice.readyDate!)}` : ''}</Item>
          <Item label='Informations complémentaires' full-span>{invoice.information && <pre className='border rounded p-1'>{invoice.information}</pre>}</Item>
          <Item label='Date de Facturation'>{invoice.billingDate ? formatToLongFrench(invoice.billingDate) : ''}</Item>
          <Item label='Numéro'>{invoice.number || ''}</Item>
          <Item label='Montant H.T.'>{typeof invoice.amountCts === 'number' ? formatCurrency(invoice.amountCts / 100) : ''}</Item>
          <Item label='Échéance'>{invoice.deadline ? formatToLongFrench(invoice.deadline) : ''}</Item>
          <Item label='Mode de paiement'>{invoice.paymentType ? getPaymentTypeLabel(invoice.paymentType) : ''}</Item>
          <Item label='Date de la dernière relance client'>{invoice.lastPaymentClientReminder ? formatToLongFrench(invoice.lastPaymentClientReminder) : ''}</Item>
          <Item label='Date de paiement par le client'>{invoice.paidDate ? formatToLongFrench(invoice.paidDate) : ''}</Item>
        </Grid>
      </Box>
    </Page>
  );
};
