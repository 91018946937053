import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Form, FormField, FormHandle, FormState } from './form/Form';
import { Box } from './Box';
import { ConfirmationPopup } from './ConfirmationPopup';
import { showToast } from './show-toast';
import { ButtonBar } from './buttons/ButtonBar';

type Props = {
  title?: string;
  fields: FormField[];
  labelOk: string;
  labelCancel?: string;
  submit: (data: any) => Promise<any>;
  onCancel?: () => void;
  backOnCancel?: boolean;
  className?: string;
};

export const BoxForm = ({ submit, ...props }: Props) => {
  const ref = useRef<FormHandle>(null);
  const history = useHistory();
  const [formState, setFormState] = useState<FormState>();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const doSubmit = useCallback((data: any) => {
    submit(data)
      .catch(error => showToast({ error }));
  }, [submit]);

  const cancel = props.onCancel || ( props.backOnCancel ? () => history.goBack() : null);

  return (
    <>
      <Box title={props.title} className={props.className}>
        <Form ref={ref} fields={props.fields} submit={doSubmit} onStateChange={setFormState} />
        <ButtonBar>
          { cancel && (
            <button key="btn-cancel" type="button" className="btn ml-3" onClick={() => formState?.isDirty ? setShowConfirmation(true): cancel()}>
              {props.labelCancel || 'Annuler'}
            </button>
          )}
          <button key="btn-ok" type="submit" className="btn btn-primary ml-3" disabled={!formState?.isValid} onClick={() => ref.current?.submit()}>
            {props.labelOk}
          </button>
        </ButtonBar>
      </Box>
      {showConfirmation && cancel && (
        <ConfirmationPopup title="Annuler cette action ?" onNo={() => setShowConfirmation(false)} onYes={cancel}>
          <p className="text-sm text-gray-500">
            Les changements seront annulés. Cette action sera <strong>définitive</strong>. <br />
            Continuer ?
          </p>
        </ConfirmationPopup>
      )}
    </>
  );
};
