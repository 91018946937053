import DatePicker from 'react-datepicker';
import { addYears, parse } from 'date-fns';

import calendarLogo from '../../../../../assets/icons/calendar_icon.png';

type DatePickerOptions = {
  id: string;
  date: string | undefined;
  maxDate?: Date;
  minDate?: Date;
  onChange: (value: Date | null) => void;
  disabled?: boolean;
}

export const MissionDatePicker = ({ id, date, minDate, maxDate, onChange, disabled }: DatePickerOptions) => (
  <div className="flex">
    <DatePicker
      id={id}
      dateFormat='dd/MM/yyyy'
      selected={date ? parse(date, 'yyyy-MM-dd', new Date()) : undefined}
      onChange={onChange}
      locale="fr"
      placeholderText="Entrer une date"
      isClearable
      minDate={minDate ? minDate : addYears(new Date(), -100)}
      maxDate={maxDate ? maxDate : null}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dateFormatCalendar='LLLL yyyy'
      dropdownMode='select'
      disabled={disabled}
    />
    <label className="flex-initial cursor-pointer h-8 my-auto" htmlFor={id}>
      <img className="hidden sm:inline-block" src={calendarLogo} alt="calendrier" style={{ width: 32 }}/>
    </label>
  </div>
);
