import { startOfWeek, endOfWeek, format, parse, addDays, getISOWeek } from 'date-fns';
import fr from 'date-fns/locale/fr';

function ymd(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

export function fromYMD(date: string): Date {
  return parse(date, 'yyyy-MM-dd', new Date());
}

export function getNow() {
  return ymd(new Date());
}

type Boundaries = {
  from: string;
  to: string;
}

export function getWeekNumber(date?: string): number {
  return getISOWeek(date ? fromYMD(date) : new Date());
}

export function addToYMD(dateYMD: string, dayOffset: number): string {
  const date = addDays(fromYMD(dateYMD), dayOffset);
  return ymd(date);
}

export function getBoundaries(dateYMD?: string, dayOffset?: number): Boundaries {
  let date = dateYMD ? fromYMD(dateYMD) : new Date();
  if (dayOffset) {
    date = addDays(date, dayOffset);
  }
  const from = startOfWeek(date, { weekStartsOn: 1 });
  const to = endOfWeek(date, { weekStartsOn: 1 });
  return {
    from: ymd(from),
    to: ymd(to),
  };
}

export type Day = {
  date: string;
  label: string;
}

// https://date-fns.org/v2.20.3/docs/format
export function formatToFrench(ymd: string) {
  const date = fromYMD(ymd);
  return format(date, 'EEEE dd MMMM', { locale: fr });
}

export function formatToShortFrench(ymd: string) {
  const date = fromYMD(ymd);
  return format(date, 'dd/MM/yy');
}

export function formatToFeedFrench(ymd: string) {
  const date = fromYMD(ymd);
  return format(date, 'dd MMM yyyy', { locale: fr });
}

export function formatToLongFrench(ymd: string) {
  const date = fromYMD(ymd);
  return format(date, 'dd MMMM yyyy', { locale: fr });
}

export function formatAccountingPeriod(md: string) {
  const date = fromYMD(`2020-${md}`); // 2020 is a leap year (to get all valid date)
  return format(date, 'dd MMMM', { locale: fr });
}

export function getDays(from: string, to: string) {
  const result: Day[] = [];
  let current = from;
  let date = addDays(fromYMD(current), -1);
  while (current !== to) {
    date = addDays(date, 1);
    current = ymd(date);
    result.push({
      date: current,
      label: format(date, 'EEEE dd/MM', { locale: fr })
    });
  }
  return result;
}
