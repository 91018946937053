import { Control, UseFormRegister } from 'react-hook-form';

import { getMissionCategoryLabel, getMissionCategoryList, MissionCategory } from '../../../../enums/mission-category.enum';
import { Box } from '../../../general/Box';
import { MissionField } from './components/MissionField';
import { range } from '../../../../utils/array';
import { DateInput } from '../../../general/form/components/DateInput';
import { getMissionMonitoringLabel, MonitoringType } from '../../../../enums/mission-monitoring.enum';
import { MonitoringInput } from './components/MonitoringInput';
import { IMission } from '../../../../interfaces/mission.interface';
import { Action } from '../../../../casl/enums';
import { missionService } from '../../../../services/mission.service';
import { InputField } from './components/InputField';

type Props = {
  mission: IMission | undefined;
  category: MissionCategory;
  control: Control<any>;
  register: UseFormRegister<any>;
  errors: any;
}

// nb: <Can> can't be used <ith register() because, when Can does not show a component, the register has already been call
//     and the form expect the field to be filled

export const MissionHeaderForm = ({ mission, category, control, register, errors }: Props) => {
  const fieldAllowed = (field: keyof IMission) => !mission || missionService.can(Action.Update, mission, field);

  return (
    <Box title="Mission">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-12 gap-2">

        { fieldAllowed('category') && (
          <>
            <MissionField className='col-span-12 sm:col-span-4' label='Type de mission' error={errors.category} required>
              <select id='category' defaultValue={mission?.category || MissionCategory.CirCii} {...register('category', { required: true })}>
                {getMissionCategoryList().map(category => <option key={category} value={category}>{getMissionCategoryLabel(category)}</option> )}
              </select>
            </MissionField>
            <div className='col-span-8 invisible sm:visible' />
          </>
        ) }

        { fieldAllowed('name') && (
          <>
            <MissionField className='col-span-12 sm:col-span-8' label='Titre de la mission' error={errors.name} required>
              <input type="text" id="name"  defaultValue={mission?.name} {...register('name', { required: true })}/>
            </MissionField>
            <div className='col-span-4 invisible sm:visible' />
          </>
        )}

        { fieldAllowed('progress') && (
          <MissionField className='col-span-12 sm:col-span-4' label='Avancement' error={errors.progress} required>
            <select id='progress' defaultValue={(mission?.progress || 0).toString()} {...register('progress')}>
              { range(0, 100, 10).map((pct) => <option key={pct} value={pct.toString()}>{pct}%</option>) }
            </select>
          </MissionField>
        )}

        { fieldAllowed('deadline') && (
          <MissionField className='col-span-12 sm:col-span-4' label='Échéance' error={errors.deadline} required>
            <DateInput field={{ type: 'date', name: 'deadline', defaultValue: mission?.deadline, label: "Date d'échéance", registerOptions: { required: true } }} control={control}/>
          </MissionField>
        )}

        { fieldAllowed('completed') && (
          <MissionField className='col-span-12 sm:col-span-4' label='Terminée le' error={errors.deadline} >
            <DateInput field={{ type: 'date', name: 'completed', defaultValue: mission?.completed, label: 'Date de fin' }} control={control}/>
          </MissionField>
        )}

        { category === MissionCategory.CirCii &&
        <>
          <MissionField className="col-span-12 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.EligibleProjectCIR)} error={errors[MonitoringType.EligibleProjectCIR]}>
            <MonitoringInput
              id={MonitoringType.EligibleProjectCIR}
              name={MonitoringType.EligibleProjectCIR}
              defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.EligibleProjectCIR)}
              logic
              control={control} />
          </MissionField>
          <MissionField className="col-span-12 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.EligibleProjectCII)} error={errors[MonitoringType.EligibleProjectCII]}>
            <MonitoringInput
              id={MonitoringType.EligibleProjectCII}
              name={MonitoringType.EligibleProjectCII}
              defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.EligibleProjectCII)}
              logic
              control={control} />
          </MissionField>
        </>
        }

        { fieldAllowed('information') && (
          <MissionField className='col-span-12 sm:col-span-12' label='Informations générales sur le client et la mission' error={errors.Information}>
            <textarea className="flex mx-2 w-full" rows={3} id='information' defaultValue={mission?.information || ''} {...register('information')} />
          </MissionField>
        )}

        { fieldAllowed('modality') && (
          <MissionField className='col-span-12 sm:col-span-12' label='Modalités de la lettre de mission' error={errors.modality}>
            <textarea className="flex mx-2 w-full" rows={3} id='modality' defaultValue={mission?.modality || ''} {...register('modality')} />
          </MissionField>
        )}
        <MissionField className='col-span-12 sm:col-span-12' label='Échanges clients' error={errors.exchange}>
          <textarea className="flex mx-2 w-full" rows={3} id='exchange' defaultValue={mission?.exchange || ''} {...register('exchange')} />
        </MissionField>

      </div>
    </Box>
  );
};
