import { createContext, FC } from 'react';
import { createContextualCan } from '@casl/react';
import { subject } from '@casl/ability';
import { Subject } from './enums';

export const AbilityContext = createContext({});
export const CaslCan = createContextualCan(AbilityContext.Consumer as any);

type Props = {
  I: string;

  a?: Subject|Record<PropertyKey, any>;
  this?: Record<PropertyKey, any>;

  field?: string;

  as?: Subject;
}

export const Can: FC<Props> = ({ children, ...props }) => {
  const action = props.I;
  let target = props.a || props.this;

  if (typeof target === 'object') {
    if (props.as) {
      target = subject(props.as, target);
    } else if (typeof target === 'object') {
      console.error(`type is missing in <Can I="${action}"> for a `, target);
    }
  } else if (props.as) {
    console.error(`Type mismatch in <Can I="${action}" a=${target} as=${props.as}>, "as" property only works with plain objects as target`, props);
  }
  return <CaslCan do={action} on={target} field={props.field}>{children}</CaslCan>;
};
