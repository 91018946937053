import { adminProjectService } from '../services/admin.project.service';
import { projectService } from '../services/project.service';
import { useApi } from './use-api';

export const useProject = (projectId: string | undefined) => {
  const { loading, result: project, error } = useApi(projectService.get, projectId);
  return { loading, project, error };
};

export const useAdminProject = (projectId: string | undefined) => {
  const { loading, result: project, error } = useApi(adminProjectService.get, projectId);
  return { loading, project, error };
};
