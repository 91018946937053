import { ITimeslotSummary } from '../../services/timeslot.service';
import { Badge } from './Badge';

type Props = {
  summary: ITimeslotSummary;
  asManager?: boolean;
}

export const TimeslotSummaryBadge = ({ summary, asManager }: Props) => {
  return (
    <>
      {summary.free && <Badge color='lightGray'>RIEN A SAISIR</Badge>}
      {summary.incomplete && <Badge color='red'>A COMPLETER</Badge>}
      {summary.draft && <Badge color='yellow'>{ asManager ? 'EN BROUILLON' : 'A ENVOYER' }</Badge>}
      {summary.submitted && <Badge color='blue'>{ asManager ? 'A VALIDER' : 'VALIDATION EN COURS' }</Badge>}
      {summary.validated && <Badge color='green'>VALIDÉ</Badge>}
    </>
  );
};
