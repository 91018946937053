import { subject } from '@casl/ability';

import { FormField } from '../components/general/form/Form';
import { Action, Subject } from '../casl/enums';
import { abilityService } from '../services/ability.service';

export function setFormFieldsDefaultValues<T>(fields: FormField[], source: T, type: Subject): FormField[] {
  const ability = abilityService.getAbility();

  return fields
    // filter form on modifiable fields only and set default value
    .filter(field => (field.type === 'hidden') || ability.can(Action.Update, subject(type, source), field.name))
    .map(field => {
      const name = field.name as keyof T;
      return {
        ...field,
        defaultValue: source[name]
      };
    });
}
