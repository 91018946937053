import { FormFieldInput } from './Input';
import { RegisterFunction } from './types';

export type FormFieldPhoneNumber = Omit<FormFieldInput, 'type'> & {
  type: 'phone';
}

export const PhoneNumber = ({ field, register } : { field: FormFieldPhoneNumber, register: RegisterFunction }) => {
  return (
    <input type='tel'
           id={field.id || field.name}
           defaultValue={field.defaultValue}
           placeholder='0123456789'
           autoComplete={field.autoComplete || 'on'}
           {...register(field.name, field.registerOptions)}
    />
  );
};
