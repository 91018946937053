import { apiService } from './api.service';
import { IClient, ICreateClient, IUpdateClient } from '../interfaces/client.interface';

function get(id: string): Promise<IClient> {
  return apiService.get<IClient>(`clients/${id}`);
}

async function getList(): Promise<IClient[]> {
  const { clients } = await apiService.get<{ clients: IClient[] }>('clients');
  return clients;
}

function create(client: ICreateClient): Promise<IClient> {
  return apiService.post<ICreateClient, IClient>('clients', client);
}

function update(id: string, client: IUpdateClient): Promise<IClient> {
  return apiService.put<IUpdateClient, IClient>(`clients/${id}`, client);
}

async function upsert(client: ICreateClient | (IUpdateClient & { id: string }) ): Promise<IClient> {
  if ('id' in client && client.id) {
    return update(client.id, client);
  }
  return create(client as ICreateClient);
}

export const clientService = {
  get,
  getList,
  create,
  update,
  upsert,
};
