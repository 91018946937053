import { useEffect, useState } from 'react';

type DataState<R> = {
  loading: boolean;
  result?: R;
  error?: any;
};

export function useApi<T, R>(handler: (v: T) => Promise<R>, parameter: T | undefined) {
  const [{ loading, result, error }, setState] = useState<DataState<R>>({
    loading: Boolean(parameter)
  });

  useEffect(() => {
    setState({ loading: Boolean(parameter) });
    if (parameter) {
      handler(parameter)
        .then(result => setState({ loading: false, result }))
        .catch(error => setState({ loading: false, error }));
    }
  }, [handler, parameter]);

  return { loading, result, error };
}
