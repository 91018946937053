import { TextLink } from '../../TextLink';

type Props = {
  className: string;
}

export const SubMenuMobile = ({ className }: Props) => {
  return (
    <div className={className}>
      <TextLink to='/' className="block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem">
                Options mobile
      </TextLink>
    </div>
  );
};
