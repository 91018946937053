import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { Spinner } from '../../general/Spinner';
import { Table, TableColumn } from '../../general/Table';
import { CardAvatarName } from '../../general/CardAvatarName';
import { IAdminLight } from '../../../interfaces/admin.interface';
import { adminService } from '../../../services/admin.service';
import { formatPhone } from '../../../utils/format-phone-number';

type Props = {
  ids?: string[];
  admins?: IAdminLight[];
  className?: string;
}
export const AdminLightList = ({ ids, admins: list, className }: Props) => {
  const [admins, setAdmins] = useState<IAdminLight[]|undefined>(list);

  useEffect(() => {
    if (ids) {
    Promise
      .all(ids.map(userId => adminService.get(userId) as Promise<IAdminLight>))
      .then(setAdmins);
    }
  }, [ids]);

  const columns = useMemo<Array<TableColumn<IAdminLight>>>(
    () => [
      {
        Header: 'Nom',
        accessor: user => `${user.lastname} ${user.firstname}`,
        Cell: ({ row: { original: user } }: CellProps<IAdminLight, string>) =>
          <CardAvatarName user={user} />,
        sortType: 'basic'
      },
      { Header: 'Email', accessor: 'email', disableSortBy: true },
      { Header: 'Téléphone',
        accessor: 'phone',
        Cell : ({ value }) => formatPhone(value),
        disableSortBy: true },
    ],
    []
  );

  if (!admins) {
    return <Spinner />;
  }

  return <Table columns={columns} data={admins} className={className} />;
};
