import { useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { adminService } from '../../services/admin.service';
import { Spinner } from './Spinner';
import { getRoleLabel } from '../../casl/roles-label';
import { GetCellPropsFunction, Table, TableColumn } from './Table';
import { IAdmin } from '../../interfaces/admin.interface';
import { CardAvatarName } from './CardAvatarName';
import { IUser } from '../../interfaces/user.interface';
import { formatPhone } from '../../utils/format-phone-number';
import { OpenIconLink } from './OpenIconLink';

type Props = {
    className?: string;
    pageSize?: number;
}
export const AdminList = ({ className, pageSize }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [admins, setAdmins] = useState<IAdmin[]>([]);

  const columns = useMemo<Array<TableColumn<IAdmin>>>(
    () => [
      {
        Header: 'Nom',
        accessor: admin => `${admin.lastname} ${admin.firstname}`,
        Cell: ({ row: { original: admin } }: CellProps<IUser, string>) => <CardAvatarName user={admin} link={`/admins/${admin.id}`} />,
        sortType: 'basic'
      },
      { Header: 'Type', accessor: admin => getRoleLabel(admin.role) },
      { Header: 'Fonction', accessor: 'job' },
      { Header: 'Email', accessor: 'email', disableSortBy: true },
      { Header: 'Téléphone',
        accessor: 'phone',
        Cell : ({ value }) => formatPhone(value),
        disableSortBy: true },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({ row: { original: admin } }: CellProps<IUser>) => <OpenIconLink to={`/admins/${admin.id}`} title="Voir le profil"/>
      },
    ],
    []
  );

  const getCellProps: GetCellPropsFunction<IAdmin> = (cell) => {
    if (cell.column.id === 'actions') {
      return {
        className: 'text-right font-medium'
      };
    }
    return {};
  };

  useEffect(() => {
    adminService
      .getList()
      .then(setAdmins)
      .then(() => setLoading(false));
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return <Table columns={columns} data={admins} className={className} pageSize={pageSize} getCellProps={getCellProps} />;
};
