import { forwardRef, MutableRefObject, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import 'react-image-crop/dist/ReactCrop.css';

import { Crop } from './Crop';

type DropAndCropImageHandle = {
  reset: () => void;
  getDataURL: () => string | null;
}

type InternalProps = {
  ref: MutableRefObject<DropAndCropImageHandle>
  onDirtyChange: (isDirty: boolean) => void;
}

export const useDropAndCropImage = () => {
  const [isDirty, setIsDirty] = useState(false);
  const ref = useRef<DropAndCropImageHandle>();

  const getProps = useCallback(() => ({
    ref,
    onDirtyChange: (isDirty) => setIsDirty(isDirty),
  }) as InternalProps, [ref]);

  const reset = useCallback(() => { ref.current?.reset(); }, []);

  const getDataURL = useCallback(() => {
    const dataURL = ref.current?.getDataURL();
    if (!dataURL) {
      throw new Error('empty data');
    }
    return dataURL;
  }, []);

  return {
    getDataURL,
    getProps,
    isDirty,
    reset,
  };
};

export const DropAndCropImage = forwardRef<DropAndCropImageHandle, InternalProps>(({ onDirtyChange }, ref) => {
  const [upImg, setUpImg] = useState<string | null>(null);
  const [dataURL, setDataUrl] = useState<string | null>(null);

  useImperativeHandle(ref, () => ({
    reset: () => {
      setUpImg(null);
      onDirtyChange(false);
    },
    getDataURL: () => dataURL,
  }), [onDirtyChange, dataURL]);

  const onDrop = useCallback((files: File[]) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => setUpImg(reader.result as string));
    reader.readAsDataURL(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: ['image/jpeg', 'image/jpeg', 'image/png'],
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
    onDrop
  });

  const onCrop = useCallback((dataURL: string) => {
    setDataUrl(dataURL);
    if (onDirtyChange) {
      onDirtyChange(true);
    }
  }, [onDirtyChange]);

  let border = 'border-gray-300';
  if (isDragActive) {
    if (isDragReject) {
      border = 'border-red-400';
    } else {
      border = 'border-gray-400 hover:border-gray-600 hover';
    }
  }

  return (
    <div className="m-1">
      {
        upImg ?
          <div className="flex flex-col items-center">
            <div className="mb-2 h-30 w-30 bg-indigo-100 rounded-full flex items-center justify-center overflow-hidden">
              { dataURL && <img className="object-cover h-20 w-20" src={dataURL} alt="preview"/> }
            </div>
            <Crop data={upImg} onCrop={onCrop}/>
          </div>
          :
          <div className={clsx('flex justify-center flex-col p-6 border-2 border-dashed rounded-md cursor-pointer', border)} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="mx-auto h-24 w-24 bg-indigo-100 rounded-full flex items-center justify-center" />
            <p className="text-center text-sm p-1">
              <span className="text-indigo-500">Sélectionner</span>&nbsp;<span>ou glisser-déposer</span>&nbsp;<span className={clsx(isDragReject && 'text-red-500')}>une image</span>
              <br/>
              <span className={clsx('text-xs', isDragReject ? 'text-red-500' : 'text-gray-500')}>PNG OU JPG jusqu&apos;à 10Mo</span>
            </p>
          </div>
      }
    </div>
  );
});
