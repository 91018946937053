export enum MissionAdminFunction {
  Responsible = 'responsible',
  Skilled = 'skilled',
  SkilledFollow = 'skilled_follow',
  SkilledCost = 'skilled_cost',
  SkilledProof = 'skilled_proof',

  SkilledTechnical = 'skilled_technical',
  SkilledFinancial = 'skilled_financial',
  SkilledBusiness = 'skilled_business',

  Billing = 'billing',
  Sales = 'sales',
  Another = 'another',
}

export const MissionCIxAdminFunctionList: MissionAdminFunction[] = [
  MissionAdminFunction.Sales,
  MissionAdminFunction.Responsible,
  MissionAdminFunction.Skilled,
  MissionAdminFunction.SkilledFollow,
  MissionAdminFunction.SkilledCost,
  MissionAdminFunction.SkilledProof,
  MissionAdminFunction.Billing,
  MissionAdminFunction.Another,
];

export const MissionFinancingFunctionAdminList: MissionAdminFunction[] = [
  MissionAdminFunction.Sales,
  MissionAdminFunction.Responsible,
  MissionAdminFunction.SkilledTechnical,
  MissionAdminFunction.SkilledFinancial,
  MissionAdminFunction.SkilledBusiness,
  MissionAdminFunction.Billing,
  MissionAdminFunction.Another,
];

export const MissionAdminFunctionLabels: {[ role in MissionAdminFunction]: string} = {
  [MissionAdminFunction.Billing]: 'Facturation',

  [MissionAdminFunction.Sales]: 'Commercial',
  [MissionAdminFunction.Responsible]: 'Responsable',
  [MissionAdminFunction.Skilled]: 'Expert innoplan',
  [MissionAdminFunction.SkilledFollow]: 'Expert suivi mensuel',
  [MissionAdminFunction.SkilledCost]: 'Expert chiffrage',
  [MissionAdminFunction.SkilledProof]: 'Expert dossier justificatif',

  [MissionAdminFunction.SkilledTechnical]: 'Expert technique',
  [MissionAdminFunction.SkilledFinancial]: 'Expert financier',
  [MissionAdminFunction.SkilledBusiness]: 'Expert business',

  [MissionAdminFunction.Another]: 'Autre',
};

export function getMissionAdminFunctionLabel(role: MissionAdminFunction): string {
  return MissionAdminFunctionLabels[role];
}
