import { subject } from '@casl/ability';

import { apiService } from './api.service';
import { ICreateInvoice, IInvoice, IUpdateInvoice } from '../interfaces/invoice.interface';
import { buildQueryString } from '../utils/build-query-string';
import { Action, Subject } from '../casl/enums';
import { abilityService } from './ability.service';

function get(id: string): Promise<IInvoice> {
  return  apiService.get<IInvoice>(`invoices/${id}`);
}

type GetListQuery = {
  clientId?: string;
  missionId?: string;
  ready?: boolean;
  haveBillingDate?: boolean;
  havePaidDate?: boolean;
}
async function getList(query: GetListQuery): Promise<IInvoice[]> {
  const { invoices } = await apiService.get<{ invoices: IInvoice[] }>(`invoices/?${buildQueryString(query)}`);
  return invoices;
}

function create(invoice: ICreateInvoice): Promise<IInvoice> {
  return apiService.post<ICreateInvoice, IInvoice>('invoices', invoice);
}

function update(id: string, invoice: IUpdateInvoice): Promise<IInvoice> {
  return apiService.put<IUpdateInvoice, IInvoice>(`invoices/${id}`, invoice);
}

async function upsert(invoice: ICreateInvoice | (IUpdateInvoice & { id: string })): Promise<IInvoice> {
  if ('id' in invoice && invoice.id) {
    return update(invoice.id, invoice);
  }
  return create(invoice as ICreateInvoice);
}

function can(action : Action, source?: Partial<IInvoice>, field?: keyof IInvoice): boolean {
  const ability = abilityService.getAbility();
  return ability.can(action, source ? subject(Subject.Invoice, source) : Subject.Invoice, field);
}

export const invoiceService = {
  get,
  getList,
  can,
  upsert,
};
