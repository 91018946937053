import { useContext, useMemo } from 'react';

import { useApi } from '../../hooks/use-api';
import { projectService } from '../../services/project.service';
import { ProjectTree } from './ProjectTree';
import { IProject } from '../../interfaces/project.interface';
import { adminProjectService } from '../../services/admin.project.service';
import { AccountContext } from '../contexts/account.context';

type Props = {
  project: IProject;
}

// Only showing items when project is a part of a tree (return null if project is a standalone one)
export const ProjectTreeBox = ({ project }: Props) => {
  const { isAdmin } = useContext(AccountContext);
  const params = useMemo(() => ({ rootId: project.rootId, clientId: project.clientId }), [project] );
  const { loading, result: projects } = useApi(isAdmin ? adminProjectService.getList : projectService.getList, params);
  if (loading || !projects || projects.length === 0) {
    return null;
  }
  return (
    <div className="relative flex flex-col mb-4 pb-3 border-b border-gray-200 bg-gray-100 w-full md:h-full md:border-r md:border-b-0 md:w-52">
      <div className="text-sm font-medium text-gray-500 p-3 border-b mb-2">Arborescence du projet</div>
      <ProjectTree activeId={project.id} projects={projects} />
    </div>
  );
};
