import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useClient } from '../../../hooks/use-client';
import { useEffectExceptOnMount } from '../../../hooks/use-effect-except-on-mount';
import { IClient } from '../../../interfaces/client.interface';
import { clientService } from '../../../services/client.service';
import { isValidSiren } from '../../../utils/is-valid-siren';
import { Box } from '../../general/Box';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { ConfirmationPopup } from '../../general/ConfirmationPopup';
import { DateInput } from '../../general/form/components/DateInput';
import { FormErrorsHandler } from '../../general/form/components/FormErrorsHandler';
import { NotFound } from '../../general/NotFound';
import { Page } from '../../general/Page';
import { showToast } from '../../general/show-toast';
import { Spinner } from '../../general/Spinner';
import { AgreementInput } from '../components/AgreementInput';
import { TeamInput } from '../components/mission/components/TeamInput';

type Props = {
  clientId?: string;
}

function castToNumber<T>(items: T[], prop: keyof T) {
  return items.map(item => ({ ...item, [prop]: Number(item[prop]) }));
}

export const ClientFormPage = ({ clientId }: Props) => {
  const history = useHistory();
  const { loading, client } = useClient(clientId);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { register, control, handleSubmit, reset, formState: { isValid, isDirty, errors } } = useForm<IClient>({
    defaultValues:  client, mode: 'all' });

  useEffectExceptOnMount(() => {
    reset(client);
  }, [client, reset]);

  if (loading) {
    return <Spinner />;
  }

  if (clientId && !client) {
    return <NotFound title="Client introuvable" />;
  }

  const cancel = () => history.goBack();

  const onSubmit = (data: any) => {
    data.id = client?.id;
    data.agreements = data.agreements && castToNumber(data.agreements, 'duration');

    return clientService
      .upsert(data)
      .then(client => {
        history.push(`/clients/${client.id}`);
      })
      .catch(error => showToast({ error }));
  };

  return (
    <Page>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box title="Société">
          <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-8 gap-6">
            <div className="col-span-8 sm:col-span-4">
              <input type="hidden" id="id" defaultValue={client?.id}  />
              <label className="block text-sm font-medium text-gray-700" htmlFor="name">
                Nom de la société<span className="text-sm text-red-600"> *</span>
              </label>
              <div className="mt-1 block w-full py-2 px-3">
                <input type="text" id="name" defaultValue={client?.name} {...register('name', { required: true })}/>
                <FormErrorsHandler error={errors.name} />
              </div>
            </div>
            <div className="sm:col-span-4 hidden sm:block" />
            <div className="col-span-8 sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="siren">
                Numéro de SIREN
              </label>
              <div className="mt-1 block w-full py-2 px-3">
                <input type="text" id="siren" maxLength={9} defaultValue={client?.siren} {...register('siren', { validate: { Siren: isValidSiren } }) }/>
                <FormErrorsHandler error={errors.siren} />
              </div>
            </div>
            <div className="col-span-8 sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="accountingPeriod">
                Date de clôture de l&apos;exercice
              </label>
              <div className="mt-1 block w-full py-2 px-3">
                <DateInput field={{ type: 'date', name: 'accountingPeriod', defaultValue: client?.accountingPeriod, label: "Date de clôture de l'exercice",  maxDate: new Date(new Date().getFullYear(), 11, 31), minDate: new Date(new Date().getFullYear(), 0, 1), isNoYear: true }} control={control}/>
              </div>
            </div>
            <div className="col-span-8 sm:col-span-8">
              <label className="block text-sm font-medium text-gray-700" htmlFor="address">
                Adresse
              </label>
              <div className="mt-1 block w-full py-2 px-3">
                <input type="text" id="address" defaultValue={client?.address} {...register('address')}/>
              </div>
            </div>
            <div className="col-span-8 sm:col-span-8">
              <label className="block text-sm font-medium text-gray-700" htmlFor="addressMore">
                Complément d&apos;adresse
              </label>
              <div className="mt-1 block w-full py-2 px-3">
                <input type="text" id="addressMore" defaultValue={client?.addressMore} {...register('addressMore')} />
              </div>
            </div>
            <div className="col-span-8 sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="zipcode">
                Code postal
              </label>
              <div className="mt-1 block w-full py-2 px-3">
                <input type="text" id="zipcode" defaultValue={client?.zipcode} {...register('zipcode')} />
              </div>
            </div>
            <div className="col-span-8 sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="city">
                Ville
              </label>
              <div className="mt-1 block w-full py-2 px-3">
                <input type="text" id="city" defaultValue={client?.city} {...register('city')} />
              </div>
            </div>
            <div className="col-span-8 sm:col-span-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="country">
                Pays
              </label>
              <div className="mt-1 block w-full py-2 px-3">
                <input type="text" id="country" defaultValue={client?.country || 'France'} {...register('country')} />
              </div>
            </div>
          </div>
        </Box>

        <TeamInput title='Contacts' name='contacts' type={'client'} error={errors.contacts} register={register} control={control} />

        <AgreementInput title='Lettres de mission' name='agreements'  error={errors.agreements} register={register} control={control}  agreementValue={client?.agreements} />

        <ButtonBar className="mt-6">
          <button key="btn-cancel" type="button" className="btn ml-3" onClick={() => isDirty ? setShowConfirmation(true): cancel()}>
            Annuler
          </button>
          <button key="btn-ok" type="submit" className="btn btn-primary ml-3" disabled={!isValid || !isDirty}>
            {client ? 'Modifier': 'Créer'}
          </button>
        </ButtonBar>
      </form>

      {showConfirmation && (
        <ConfirmationPopup title="Annuler cette action ?" onNo={() => setShowConfirmation(false)} onYes={cancel}>
          <p className="text-sm text-gray-500">
            Les changements seront annulés. Cette action sera <strong>définitive</strong>. <br />
            Continuer ?
          </p>
        </ConfirmationPopup>
      )}
    </Page>
  );
};
