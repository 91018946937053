import { useHistory } from 'react-router-dom';

import { getRoleLabel } from '../../../casl/roles-label';
import { adminProjectService } from '../../../services/admin.project.service';
import { setFormFieldsDefaultValues } from '../../../utils/set-form-fields-default-values';
import { BoxForm } from '../../general/BoxForm';
import { FormField } from '../../general/form/Form';
import { Spinner } from '../../general/Spinner';
import { Subject } from '../../../casl/enums';
import { IAdminProject } from '../../../interfaces/admin-project.interface';
import { Box, BoxHeader } from '../../general/Box';
import { ProjectHeader } from '../../general/ProjectHeader';
import { useAdmins } from '../../../hooks/use-admins';

type Props = {
  project: IAdminProject;
}

export const ProjectForm = ({ project }: Props) => {
  const history = useHistory();
  const { admins } = useAdmins();

  if (!admins) {
    return <Spinner />;
  }

  let fields: FormField[] = [];

  if (!project.parentId) {
    fields.push({
      name: 'expertIds',
      label: '',
      type: 'checkboxes',
      options: admins.map(admin => ({ label: `${admin.lastname} ${admin.firstname} (${getRoleLabel(admin.role)})`, value: admin.id })),
      cols: 8,
      spread: 3
    });
  }

  fields = setFormFieldsDefaultValues(fields, project, Subject.Project);

  const submit = (data: any) => {
    return adminProjectService
      .update(project.id, data)
      .then(project => {
        history.push(`/clients/${project.clientId}/projects/${project.id}`);
      });
  };
  return <>
    <Box title={project.name} className="mb-4">
      <BoxHeader>
        <ProjectHeader project={project} />
      </BoxHeader>

      { project.description && (
        <div className="mb-4">
          <dt className="text-sm font-medium text-gray-500">Description</dt>
          <p className="mt-1 text-sm text-gray-900">{ project.description }</p>
        </div>
      )}
    </Box>

    <BoxForm
      title="Expert à affecter à ce projet"
      labelOk={'Affecter'}
      className=""
      fields={fields} submit={submit} backOnCancel />
  </>;
};
