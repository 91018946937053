// Make validator optional if needed
import { Validate } from 'react-hook-form';

export function updateValidators<T extends {[key: string]: Validate<any>}>(updating: boolean, source : T) {
  const target: Record<string, Validate<any>> = {};
  for (const [key, test] of Object.entries(source) ) {
    target[key] = (value: string) => {
      if (updating && !value) {
        return true;
      }
      return test(value);
    };
  }
  return target;
}
