import { Control, Controller } from 'react-hook-form';
import { ChangeEvent, useMemo } from 'react';
import { format } from 'date-fns';

import { MissionDatePicker } from './MissionDatePicker';
import { getMonitoringResponseLabel, MonitoringResponse } from '../../../../../enums/monitoring-response.enum';
import { MonitoringType } from '../../../../../enums/mission-monitoring.enum';

type Props = {
  id?: string;
  name: string;
  defaultValue?: Result;
  control: Control<any>;
  done?: true | {
    label?: string;
    incompatible?: true;
    ongoing?: true;
  }
  logic?: true;
  user?: true; // firstname / lastname
  provisionalDate?: true | {
    label?: string;
    max?: Date;
    min?: Date;
  };
  date?: true | {
    label?: string;
    max?: Date;
    min?: Date;
  };
  response?: true;
}

type Result = {
  done?: boolean;
  logic?: boolean;
  incompatible?: boolean;
  ongoing?: boolean;
  date?: string;
  provisionalDate?: string;
  firstname?: string;
  lastname?: string;
  response?: MonitoringResponse;
}

function optionAsObject<T extends Record<string, any>>(value: true | undefined | T): T | Record<string, never> | undefined {
  if (value === true) {
    return {};
  }
  return value;
}

export const MonitoringInput = ({ id, name, defaultValue, control, ...config }: Props) => {
  const datFormat = 'yyyy-MM-dd';
  const doneId = `${name}__done_`;
  const logicId = `${name}__logic_`;
  const datePickerId1 = `${name}__datepicker_1_`;
  const datePickerId2 = `${name}__datepicker_2_`;
  const lastnameId = `${name}__lastname_`;
  const firstnameId = `${name}__firstname_`;
  const responseId = `${name}__response_`;

  const done = optionAsObject(config.done);
  const date = optionAsObject(config.date);

  // need to store the value to get the same reference when re-rendering without a default value
  const initialValue = useMemo(() => defaultValue || (done ? { done: false } : {}), [defaultValue]);

  const provisionalDate = optionAsObject(config.provisionalDate);

  const addLabels = Boolean(done?.label || date?.label || provisionalDate?.label || config.user || config.response);
  const nbsp = '\u00A0';

  return (
    <Controller
      name={name}
      defaultValue={initialValue}
      control={control}
      rules={{
        validate: {
          ...(date ? { RequiredDate: (value: Result) => !value?.done || Boolean(value.date) || 'La date est requise' } : null),
          ...(config.user ? {
            UserLastname: (value: Result) => Boolean(value?.lastname) || !(value?.firstname || value?.date) || 'Le nom est requis',
            UserFirstname: (value: Result) => Boolean(value?.firstname) || !(value?.lastname || value?.date) || 'Le prénom est requis',
            UserDate: (value: Result) => Boolean(value?.date) || !(value?.firstname || value?.lastname) || 'La date est requise',
          } : null)
        }
      }}
      render={({ field: { onChange, value: data } }: { field: { onChange: (value: Result) => void, value: Result }}) => {
        function onDoneChange(event: ChangeEvent<HTMLSelectElement>) {
          if (event.target.value === '1') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { incompatible, ...rest } = data;
            if (config.response && !rest.response) {
              rest.response = initialValue.response || MonitoringResponse.Refused;
            }
            onChange({ ...rest, done: true });
          } else if (event.target.value === '-1') {
            onChange({ incompatible: true });
          } else if (event.target.value === '2') {
            onChange({ ongoing: true });
          } else {
            onChange({ done: false });
          }
        }

        function onDoneChangeLogic(event: ChangeEvent<HTMLSelectElement>) {
          if (event.target.value === '1') {
            onChange({ logic: true });
          } else {
            onChange({ logic: false });
          }
        }

        const disabled = Boolean(done) && !data.done;

        return (
          <div id={id} className="flex">
            {
              config.logic && (
                <div className="flex-1 mx-2">
                  {addLabels && <label className="block text-sm font-medium text-gray-400" htmlFor={logicId}>{nbsp}</label>}
                  <select id={logicId} defaultValue={initialValue.logic ? '1' : '0'} onChange={onDoneChangeLogic}>
                    <option value='1'>Oui</option>
                    <option value='0'>Non</option>
                  </select>
                </div>
              )
            }
            {
              done && (
                <div className="flex-1 mx-2">
                  {addLabels && <label className="block text-sm font-medium text-gray-400" htmlFor={doneId}>{done.label || nbsp}</label>}
                  <select id={doneId} defaultValue={initialValue.done ? '1' : (initialValue.incompatible ? '-1' : (initialValue.ongoing ? '2' : '0'))} onChange={onDoneChange}>
                    <option value='1'>Oui</option>
                    { done.ongoing && <option value='2'>En cours</option> }
                    <option value='0'>Non</option>
                    { done.incompatible && <option value='-1'>Non applicable</option> }
                  </select>
                </div>
              )
            }
            {
              config.user && (
                <>
                  <div className='flex-1 px-2'>
                    {addLabels && (
                      <label className="block text-sm font-medium text-gray-400" htmlFor={lastnameId}>
                        Nom
                        {(data.date || data.firstname) && <span className="text-sm text-red-600"> *</span>}
                      </label>
                    )}
                    <input type="text" className="flex" id={lastnameId} name="lastname" defaultValue={initialValue.lastname || ''} autoComplete="family-name" disabled={disabled} onChange={(e) => onChange({ ...data, lastname: e.target.value || undefined })}/>
                  </div>
                  <div className='flex-1 px-2'>
                    {addLabels && (
                      <label className="block text-sm font-medium text-gray-400" htmlFor={firstnameId}>
                        Prénom
                        {(data.date || data.lastname) && <span className="text-sm text-red-600"> *</span>}
                      </label>
                    )}
                    <input type="text" className="flex" id={firstnameId} name="firstname" defaultValue={initialValue.firstname || ''} autoComplete="given-name" disabled={disabled} onChange={(e) => onChange({ ...data, firstname: e.target.value || undefined })}/>
                  </div>
                </>
              )
            }
            {
              config.response && (
                <div className='flex-1 px-2'>
                  {addLabels && <label className="block text-sm font-medium text-gray-400" htmlFor={responseId}>Réponse</label>}
                  <select id={responseId} defaultValue={initialValue.response} disabled={disabled} onChange={(e) => onChange({ ...data, response: (e.target.value as MonitoringResponse) || undefined })}>
                    <option value={MonitoringResponse.Refused}>{getMonitoringResponseLabel(MonitoringResponse.Refused)}</option>
                    <option value={MonitoringResponse.Accepted}>{getMonitoringResponseLabel(MonitoringResponse.Accepted)}</option>
                  </select>
                </div>
              )
            }
            {
              provisionalDate && (
                <div className='flex-1 px-2'>
                  {addLabels && <label className="block text-sm font-medium text-gray-400" htmlFor={datePickerId2}>{provisionalDate.label || nbsp}</label>}
                  <MissionDatePicker
                    id={datePickerId2}
                    date={data.provisionalDate}
                    onChange={(value: Date | null) => onChange({ ...data, provisionalDate: value ? format(value, datFormat) : undefined })}
                    minDate={provisionalDate.min}
                    maxDate={provisionalDate.max}
                    disabled={data.incompatible || name === MonitoringType.PaymentBilling && disabled}
                  />
                </div>
              )
            }
            {
              date && (
                <div className='flex-1 px-2'>
                  {addLabels && (
                    <label className="block text-sm font-medium text-gray-400" htmlFor={datePickerId1}>
                      {date.label || nbsp}
                      {(data.done || data.lastname || data.firstname) && <span className="text-sm text-red-600"> *</span>}
                    </label>
                  )}
                  <MissionDatePicker
                    id={datePickerId1}
                    date={data.date}
                    onChange={(value: Date | null) => onChange({ ...data, date: value ? format(value, datFormat) : undefined })}
                    minDate={date.min}
                    maxDate={date.max}
                    disabled={disabled}
                  />
                </div>
              )
            }
          </div>
        );
      }}
    />
  );
};
