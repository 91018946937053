import { NavLink } from '../general/header/components/NavLink';
import { Can } from '../../casl/can';
import { Action, Subject } from '../../casl/enums';

export const NavLinks = () => {
  return (<>
    <NavLink route="/"><span className="hidden md:flex">Tableau de bord</span>
      <span className="md:hidden">
        <span className="fa-stack">
          <i className="fas fa-home fa-stack-2x"/>
        </span>
      </span>
    </NavLink>
    <Can I={Action.List} a={Subject.User}>
      <NavLink route="/users"><span className="hidden md:flex">Utilisateurs</span>
        <span className="md:hidden">
          <span className="fa-stack">
            <i className="fas fa-users fa-stack-2x"/>
          </span>
        </span>
      </NavLink>
    </Can>
    <Can I={Action.Read} a={Subject.Project}>
      <NavLink route="/projects"><span className="hidden md:flex">Projets</span>
        <span className="md:hidden">
          <span className="fa-stack">
            <i className="fas fa-project-diagram fa-stack-2x" />
          </span>
        </span>
      </NavLink>
    </Can>
    <Can I={Action.Create} a={Subject.Timeslot}>
      <NavLink route="/timeslots"><span className="hidden md:flex">Fiches de temps</span>
        <span className="md:hidden" >
          <span className="fa-stack">
            <i className="fas fa-calendar fa-stack-2x" />
            <i className="fas fa-clock fa-stack-1x text-primaryPandaColor top-1.5" />
          </span>
        </span>
      </NavLink>
    </Can>
  </>);
};
