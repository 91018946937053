import { toast } from 'react-toastify';
import { ReactNode } from 'react';

function forgeErrorMessage(error: any) {
  let serverError: string[] = error?.message ? [error.message] : ["Une erreur s'est produite"];
  if (error?.response?.data?.fields) {
    serverError = Object.values(error.response.data.fields);
  } else if (error?.response?.data?.message) {
    serverError = [error?.response?.data?.message];
  }
  return <ul>{serverError.map((error, index) => <li key={index} className="pb-1">{error}</li>)}</ul>;
}

type Props = {
  type?: 'success' | 'error' | 'warn';
  title?: string;
  content?: string | ReactNode;
  error?: any;
}
export function showToast({ type, title, content, error } : Props) {
  if (!type) {
    if (error) {
      type = 'error';
    } else {
      type = 'success';
    }
  }
  if (!title) {
    if (type === 'error') {
      title = 'Erreur';
    } else if (type === 'warn') {
      title = 'Attention';
    } else {
      title = 'Succès';
    }
  }
  if (!content) {
    if (type === 'error') {
      content = forgeErrorMessage(error);
    } else {
      content = 'La sauvegarde des données a été effectuée avec succès.';
    }
  }
  toast(
    <div className="flex items-start">
      <div className="flex-shrink-0">
        { type === 'success' && <i className="fas fa-check-circle text-green-500"/> }
        { type === 'warn' && <i className="fas fa-exclamation-circle fa-2x text-blue-500"/> }
        { type === 'error' && <i className="fas fa-exclamation-triangle fa-2x text-red-500"/> }
      </div>
      <div className="ml-3 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900">{title}</p>
        <div className="mt-1 text-sm text-gray-500">{content}</div>
      </div>
    </div>
    ,
    { hideProgressBar: true, pauseOnFocusLoss: false, autoClose: 5000 }
  );
}
