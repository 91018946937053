import { useUser } from '../../hooks/use-user';
import { IUser } from '../../interfaces/user.interface';

type Props = {
  userId?: string;
  user?: IUser;
}

export const UserTitleCard = ({ userId, user: initialUser }: Props) => {
  const { user: loadedUser } = useUser(userId);
  if (userId || initialUser) {
    const user = loadedUser || initialUser;
    return (
      <div className="text-lg leading-6 font-medium space-y-1">
        <h3>{user?.firstname} {user?.lastname}</h3>
        <p className="text-sm text-indigo-600">{user?.job}</p>
      </div>
    );
  }
  return null;
};
