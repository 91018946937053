import { Box } from '../../general/Box';
import { Page } from '../../general/Page';
import { Spinner } from '../../general/Spinner';

export const TimeEntryListPage = () => {

    return <Page>
        <Box title="Pointages" transparent>
            <Spinner />
        </Box>
    </Page>;
};
