import { useContext } from 'react';

import { Page } from '../../general/Page';
import { Box } from '../../general/Box';
import { ProjectList } from '../components/ProjectList';
import { Action, Subject } from '../../../casl/enums';
import { Can } from '../../../casl/can';
import { AccountContext } from '../../contexts/account.context';
import { MissionList } from '../components/MissionList';
import { InvoiceList } from '../components/InvoiceList';

export const Dashboard = () => {
  const { account } = useContext(AccountContext);
  return (
    <Page>

      <Can I={Action.Update} a={Subject.Project} field="expertIds">
        <Box title="Projets sans expert" transparent>
          <ProjectList needExpertOnly={true} emptyMsg='Tous les projets sont affectés.' />
        </Box>
      </Can>

      <Can I={Action.List} a={Subject.Project}>
        <Box title="Vos projets" transparent>
          <ProjectList expertId={account.id} emptyMsg="Aucun projet ne vous est affecté."  />
        </Box>
      </Can>

      <Can I={Action.Update} a={Subject.Mission}>
        <Box title="Vos missions" transparent>
          <MissionList showTitle={false} assignedOnly={true} emptyMsg="Aucune mission ne vous est affectée." />
        </Box>
      </Can>

      <Can I={Action.Update} a={Subject.Invoice}>
        <Box title="Factures à envoyer" transparent>
          <InvoiceList ready={true} haveBillingDate={false} emptyMsg="Aucune facture n'est à envoyer."/>
        </Box>
        <Box title="Factures en attente de paiement" transparent>
          <InvoiceList ready={true} haveBillingDate={true} havePaidDate={false} emptyMsg="Aucune facture n'est en attente de paiement."/>
        </Box>
      </Can>

    </Page>
  );
};
