import { Redirect, Route, Switch } from 'react-router-dom';

import { Dashboard } from './pages/Dashboard';
import { CredentialPage } from './pages/CredentialPage';
import { UserListPage } from './pages/UserListPage';
import { UserFormPage } from './pages/UserFormPage';
import { ProjectListPage } from './pages/ProjectListPage';
import { UserProfilePage } from './pages/UserProfilePage';
import { ProjectFormPage } from './pages/ProjectFormPage';
import { ProjectPage } from './pages/ProjectPage';
import { TimeslotSummaryPage } from './pages/TimeslotSummaryPage';
import { TimeslotPage } from '../general/TimeslotPage';
import { WorklogFormPage } from './pages/WorklogFormPage';
import { MyProfilePage } from './pages/MyProfilePage';
import { MyProfileFormPage } from './pages/MyProfileFormPage';

export const Routes = () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />

    <Route exact path="/profile" component={MyProfilePage} />
    <Route exact path="/profile/edit" component={MyProfileFormPage} />

    <Route exact path="/timeslots" component={TimeslotSummaryPage} />
    <Route exact path="/timeslots/view" component={TimeslotPage} />

    <Route exact path="/users" component={UserListPage} />
    <Route exact path="/users/create" component={UserFormPage} />
    <Route exact path="/users/:id" render={({ match: { params: { id } } }) => <UserProfilePage userId={id} />} />
    <Route exact path="/users/:id/edit" render={({ match: { params: { id } } }) => <UserFormPage userId={id} />} />
    <Route exact path="/users/:id/timeslots" render={({ match: { params: { id } } }) => <TimeslotPage userId={id} />} />
    <Route exact path="/users/:id/credentials" render={({ match: { params: { id } } }) => <CredentialPage userId={id} />} />

    <Route exact path="/projects" component={ProjectListPage} />
    <Route exact path="/projects/create" component={ProjectFormPage} />
    <Route exact path="/projects/:id" render={({ match: { params: { id } } }) => <ProjectPage projectId={id} />} />
    <Route exact path="/projects/:id/edit" render={({ match: { params: { id } } }) => <ProjectFormPage projectId={id} />} />
    <Route exact path="/projects/:id/worklog/edit" render={({ match: { params: { id } } }) => <WorklogFormPage projectId={id} />} />
    <Redirect from="/projects/:id/*" to="/projects/:id" />

    <Redirect to="/" />
  </Switch>
);
