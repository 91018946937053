import { Aside, Page } from '../../general/Page';
import { Spinner } from '../../general/Spinner';
import { NotFound } from '../../general/NotFound';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { Can } from '../../../casl/can';
import { Action, Subject } from '../../../casl/enums';
import { AddButton } from '../../general/buttons/AddButton';
import { EditButton } from '../../general/buttons/EditButton';
import { ProjectTreeBox } from '../../general/ProjectTreeBox';
import { ProjectUserList } from '../../general/ProjectUserList';
import { Box, BoxHeader } from '../../general/Box';
import { ProjectHeader } from '../../general/ProjectHeader';
import { Worklog } from '../../general/Worklog';
import { worklogService } from '../../../services/worklog.service';
import { AdminLightList } from '../components/AdminLightList';
import { IProject } from '../../../interfaces/project.interface';
import { IWorklog } from '../../../interfaces/worklog.interface';
import { useEffect, useState } from 'react';
import { projectService } from '../../../services/project.service';
import { showToast } from '../../general/show-toast';

type Props = {
  projectId: string;
}

type DataState = {
  loading?: boolean;
  project?: IProject;
  worklog?: IWorklog;
  canReadWorklog?: boolean;
}

export const ProjectPage = ({ projectId }: Props) => {
  const [{ loading, project, worklog, canReadWorklog }, setData] = useState<DataState>({ loading: true });

  useEffect(() => {
    setData({ loading: true });
    projectService
      .get(projectId)
      .then(project => {
        const canReadWorklog = worklogService.canFromProject(Action.Read, project);
        return Promise.all([
          project,
          canReadWorklog ? worklogService.get(projectId) : undefined,
          canReadWorklog
        ]);
      })
      .then(([project, worklog, canReadWorklog]) => {
        setData({ project, worklog, canReadWorklog });
      })
      .catch(error => {
        setData({ loading: false });
        showToast({ error });
      });
  },
  [projectId]);

  if (loading) {
    return <Spinner />;
  }

  if (!project) {
    return <NotFound title="Projet introuvable" />;
  }

  return (
    <Page>
      <Aside>
        <ProjectTreeBox project={project} />
      </Aside>

      <Box title={project.name}>
        <BoxHeader>
          <ProjectHeader project={project} />
        </BoxHeader>

        <ButtonBar>
          { !project.isTask && <Can I={Action.Create} a={Subject.Project}>
            <AddButton to={`/projects/create?parentId=${project.id}`} label="Créer un sous-projet" />
            <AddButton to={`/projects/create?parentId=${project.id}&task=1`} label="Créer une tâche" />
          </Can> }
          <Can I={Action.Update} a={project} as={Subject.Project}>
            <EditButton to={`/projects/${project.id}/edit`} />
          </Can>
        </ButtonBar>

        { project.description && (
          <div className="mb-6">
            <dt className="text-sm font-medium text-gray-500">Description</dt>
            <p className="mt-1 text-sm text-gray-900">{ project.description }</p>
          </div>
        )}
      </Box>

      <Box title="Experts en charge du projet" transparent>
        <AdminLightList ids={project.expertIds} />
      </Box>

      <Box title="Équipe" transparent>
        <ProjectUserList project={project} />
      </Box>

      {
        canReadWorklog && (
        <Box title="Cahier de suivi">
          <ButtonBar>
            { worklogService.canFromProject(Action.Update, project) && <EditButton to={`/projects/${project.id}/worklog/edit`} /> }
          </ButtonBar>
          <Worklog project={project} worklog={worklog} />
        </Box>
      )
    }
    </Page>
  );
};
