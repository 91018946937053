import { Children, FC, isValidElement, ReactElement } from 'react';
import { FieldError } from 'react-hook-form';
import clsx from 'clsx';

import { FormErrorsHandler } from '../../../../general/form/components/FormErrorsHandler';

// Important: id is required if first child is not the input
export const MissionField:FC<{ label: string; id?: string; className: string; required?: boolean; error: FieldError }> = ({ label, id, className, required, error, children }) => {
  const inputId = id || (Children.toArray(children).filter(isValidElement)[0] as ReactElement | undefined)?.props.id;
  return (
    <div className={clsx(className)}>
      <label className="block text-sm font-medium text-gray-700" htmlFor={inputId}>
        {label}{required && <span className="text-sm text-red-600"> *</span>}
      </label>
      <div className="mt-1 block w-full py-2 px-3">{children}</div>
      <FormErrorsHandler error={error} />
    </div>
  );
};
