import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage } from './LoginPage';

export const Routes = () => {
  return (
    <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'RGB(244, 244, 245)' }}>
      <Switch>
        <Route path="/login" render={() => <LoginPage />} />
        <Redirect to="/login" />
      </Switch>
    </div>
  );
};
