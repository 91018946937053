import { useContext } from 'react';

import { AdminProfile } from '../../general/AdminProfile';
import { Box } from '../../general/Box';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { EditButton } from '../../general/buttons/EditButton';
import { Page } from '../../general/Page';
import { AccountContext } from '../../contexts/account.context';
import { AvatarProfil } from '../../general/AvatarProfil';

export const MyProfilePage = () => {
  const { getAdmin } = useContext(AccountContext);
  return (
    <Page>
      <Box title="Votre profil">
        <ButtonBar>
          <EditButton to='/profile/edit'/>
        </ButtonBar>
        <AvatarProfil user={getAdmin()}/>
      </Box>
      <AdminProfile adminId={getAdmin().id}/>
    </Page>
  );
};
