import { FC, MouseEvent } from 'react';
import { OkButton } from './buttons/OkButton';

type Props = {
  onOk: (event: MouseEvent<HTMLButtonElement>) => void;
  title: string;
  label: string;
};

export const InformationPopup: FC<Props> = ({ onOk, title, label, children }) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div className="hidden sm:block absolute top-0 right-0 pt-2 pr-2">
            <button type="button" onClick={onOk} className="bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none hover:bg-transparent">
              <span className="sr-only">Close</span>
              <i className="fas fa-times text-2xl" />
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center sm:mx-0 sm:h-10 sm:w-10 ">
              <i className="fas fa-exclamation-circle fa-2x text-blue-500"/>
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">{ title }</h3>
              <div className="mt-2">{ children }</div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <OkButton onClick={onOk} label={label} />
          </div>
        </div>
      </div>
    </div>
  );
};
