import { subject } from '@casl/ability';

import { ICreateMission, IMission, IUpdateMission } from '../interfaces/mission.interface';
import { buildQueryString } from '../utils/build-query-string';
import { apiService } from './api.service';
import { Action, Subject } from '../casl/enums';
import { abilityService } from './ability.service';

function create(mission: ICreateMission): Promise<IMission> {
  return apiService.post<ICreateMission, IMission>('missions', mission);
}

function update(id: string, mission: IUpdateMission): Promise<IMission> {
  return apiService.put<IUpdateMission, IMission>(`missions/${id}`, mission);
}

function get(id: string): Promise<IMission> {
  return apiService.get<IMission>(`missions/${id}`);
}

type GetListQuery = {
  clientId?: string;
  activeOnly?: boolean;
  assignedOnly?: boolean;
}

async function getList(query: GetListQuery = {}): Promise<IMission[]> {
  const { missions } = await apiService.get<{missions: IMission[]}>(`missions?${buildQueryString(query)}`);
  return missions;
}

async function upsert(mission: ICreateMission | (IUpdateMission & { id: string }) ): Promise<IMission> {
  if ('id' in mission && mission.id) {
    return update(mission.id, mission);
  }
  return create(mission as ICreateMission);
}

function can(action : Action, source?: Partial<IMission>, field?: keyof IMission): boolean {
  const ability = abilityService.getAbility();
  return ability.can(action, source ? subject(Subject.Mission, source) : Subject.Mission, field);
}

export const missionService = {
  create,
  can,
  get,
  getList,
  upsert,
};
