import { FC } from 'react';
import { ChildManager } from '../../utils/child-manager';

export const Aside: FC = ({ children }) => <>{children}</>;

export const Page: FC = ({ children }) => {
  const manager = new ChildManager(children);
  const aside = manager.findAndExtract(Aside);
  return (
    <main className="flex flex-col w-full pb-16 bg-gray-100 space-y-8 sm:px-6 md:p-6">
      {
        aside ? (
          <div className="flex flex-1 h-full flex-col overflow-hidden">
            <div className="flex flex-1 flex-col md:flex-row">
              <aside className="flex flex-shrink md:h-screen">
                { aside }
              </aside>
              <div className="flex-1 pl-6">
                <div className="flex flex-col space-y-8">
                { manager.children() }
                </div>
              </div>
            </div>
          </div>
        ) : manager.children()
      }
    </main>
  );
};
