import { IWorklog } from '../interfaces/worklog.interface';
import { ProjectCategory } from './project-category.enum';

export type WorklogEntries = keyof Pick<IWorklog, 'goal' | 'state' | 'action' | 'result' | 'conclusion'>;
export type WorklogStates = keyof Pick<IWorklog, 'goalValidated' | 'stateValidated' | 'actionValidated' | 'resultValidated' | 'conclusionValidated'>;

export type WorklogEntryTuple = [WorklogEntries, string];

const validationTuples: {[k in WorklogEntries]: WorklogStates } = {
  conclusion: 'conclusionValidated',
  goal: 'goalValidated',
  result: 'resultValidated',
  action: 'actionValidated',
  state: 'stateValidated',
};

export function getValidationKey(key: WorklogEntries): WorklogStates {
  if (!validationTuples[key]) {
    throw new Error(`Unexpected entry value ${key}`);
  }
  return validationTuples[key];
}

export function getWorklogEntryTuples(category: ProjectCategory): WorklogEntryTuple[] {
  if (category === ProjectCategory.ProjetInnovant) {
    return [
      ['goal', 'Description du produit'],
      ['state', 'État du marché'],
      ['action', 'Objectifs visés'],
      ['result', 'Travaux et résultats'],
      ['conclusion', 'Indicateurs d\'innovation']
    ];
  } else if (category === ProjectCategory.OperationRD){
    return [
      ['goal', 'Problème à résoudre'],
      ['state', 'État de l\'art'],
      ['action', 'Difficultés techniques'],
      ['result', 'Travaux et résultats'],
      ['conclusion', 'Indicateurs de R&D']
    ];
  } else {
    return [
      ['goal', 'Description']
    ];
  }
}
