import { FC, MouseEvent, useState } from 'react';
import clsx from 'clsx';

import { ChildManager } from '../../utils/child-manager';
import { useQuery } from '../../hooks/use-query';

type Props = {
  title: string;
  name?: string;
}

export const Tab: FC<Props> = ({ children }) => <>{children}</>;

export const Tabs: FC = ({ children }) => {
  const { query, updateQuery } = useQuery();
  const name = query.get('tab');
  const manager = new ChildManager(children);
  const tabs = manager.filter(Tab);
  const [selected, setSelected] = useState(Math.max(0, tabs.findIndex(tab => tab.props.name === name)));

  function onClick(index: number, event: MouseEvent<HTMLElement>) {
    event.preventDefault();
    setSelected(index);
    const name = tabs[index].props.name;
    if (name) {
      updateQuery({ tab: name });
    }
  }

  return (
    <>
      <div className="border-b border-gray-200 mb-5">
        <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
          {
            tabs.map((tab, index) => (
              <a key={index} href="#" onClick={onClick.bind(null, index)} className={clsx('whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm', selected === index ? 'border-purple-500 text-purple-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200')}>
                {tab.props.title}
              </a>
            ))
          }
        </nav>
      </div>
      { tabs[selected] }
    </>
  );
};
