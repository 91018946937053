import { Control, FieldErrors } from 'react-hook-form';

import { Box } from '../../../general/Box';
import { MissionField } from './components/MissionField';
import { getMissionMonitoringLabel, MonitoringType } from '../../../../enums/mission-monitoring.enum';
import { MonitoringInput } from './components/MonitoringInput';
import { IMission } from '../../../../interfaces/mission.interface';
import { MissionCategory } from '../../../../enums/mission-category.enum';
import { CheckListDateInput } from './components/CheckListDateInput';
import { InputFieldAmount } from './components/InputFieldAmount';

type Props = {
  mission: IMission | undefined;
  control: Control<any>;
  errors: FieldErrors;
};

export const MissionFormCirCii = ( { mission, control, errors } : Props ) => (
  <>
    <Box title="Suivi de mission" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-8 gap-6">
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.BriefClient)} error={errors[MonitoringType.BriefClient]}>
          <MonitoringInput
            id={MonitoringType.BriefClient}
            name={MonitoringType.BriefClient}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.BriefClient)}
            done={true}
            date={true}
            control={control}
          />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.TimeslotTemplate)} error={errors[MonitoringType.TimeslotTemplate]}>
          <MonitoringInput
            id={MonitoringType.TimeslotTemplate}
            name={MonitoringType.TimeslotTemplate}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.TimeslotTemplate)}
            done={{ incompatible: true }}
            date={true}
            control={control}
          />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.OperationTemplate)} error={errors[MonitoringType.OperationTemplate]}>
          <MonitoringInput
            id={MonitoringType.OperationTemplate}
            name={MonitoringType.OperationTemplate}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.OperationTemplate)}
            done={{ incompatible: true }}
            date={true}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.ProjectTemplate)} error={errors[MonitoringType.ProjectTemplate]}>
          <MonitoringInput
            id={MonitoringType.ProjectTemplate}
            name={MonitoringType.ProjectTemplate}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ProjectTemplate)}
            done={{ incompatible: true }}
            date={true}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.AccountantRequest)} error={errors[MonitoringType.AccountantRequest]}>
          <MonitoringInput
            id={MonitoringType.AccountantRequest}
            name={MonitoringType.AccountantRequest}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.AccountantRequest)}
            done={{ label:'Effectuée ?' }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.TimeslotRequest)} error={errors[MonitoringType.TimeslotRequest]}>
          <MonitoringInput
            id={MonitoringType.TimeslotRequest}
            name={MonitoringType.TimeslotRequest}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.TimeslotRequest)}
            done={{ label:'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.OperationRequest)} error={errors[MonitoringType.OperationRequest]}>
          <MonitoringInput
            id={MonitoringType.OperationRequest}
            name={MonitoringType.OperationRequest}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.OperationRequest)}
            done={{ label:'Effectuée ?', incompatible: true }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
      </div>
    </Box>

    <Box title="Checking-list des éléments reçus" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-6 gap-6">
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistTimeslot)} error={errors[MonitoringType.ChecklistTimeslot]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistTimeslot}
            name={MonitoringType.ChecklistTimeslot}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistTimeslot)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistOperation)} error={errors[MonitoringType.ChecklistOperation]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistOperation}
            name={MonitoringType.ChecklistOperation}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistOperation)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistDeliveredAtPlace)} error={errors[MonitoringType.ChecklistDeliveredAtPlace]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistDeliveredAtPlace}
            name={MonitoringType.ChecklistDeliveredAtPlace}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistDeliveredAtPlace)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistTechWatch)} error={errors[MonitoringType.ChecklistTechWatch]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistTechWatch}
            name={MonitoringType.ChecklistTechWatch}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistTechWatch)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistPatent)} error={errors[MonitoringType.ChecklistPatent]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistPatent}
            name={MonitoringType.ChecklistPatent}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistPatent)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistSubcontractor)} error={errors[MonitoringType.ChecklistSubcontractor]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistSubcontractor}
            name={MonitoringType.ChecklistSubcontractor}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistSubcontractor)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistPayslip)} error={errors[MonitoringType.ChecklistPayslip]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistPayslip}
            name={MonitoringType.ChecklistPayslip}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistPayslip)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistPayroll)} error={errors[MonitoringType.ChecklistPayroll]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistPayroll}
            name={MonitoringType.ChecklistPayroll}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistPayroll)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistCV)} error={errors[MonitoringType.ChecklistCV]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistCV}
            name={MonitoringType.ChecklistCV}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistCV)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistDegree)} error={errors[MonitoringType.ChecklistDegree]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistDegree}
            name={MonitoringType.ChecklistDegree}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistDegree)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-2" label={getMissionMonitoringLabel(MonitoringType.ChecklistApproval)} error={errors[MonitoringType.ChecklistApproval]}>
          <CheckListDateInput
            id={MonitoringType.ChecklistApproval}
            name={MonitoringType.ChecklistApproval}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ChecklistApproval)}
            type={MissionCategory.Finance}
            control={control} />
        </MissionField>
      </div>
    </Box>

    <Box title="Rapport" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-8 gap-6">
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.ReportPrior)} error={errors[MonitoringType.ReportPrior]}>
          <MonitoringInput
            id={MonitoringType.ReportPrior}
            name={MonitoringType.ReportPrior}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ReportPrior)}
            user
            date={{ label: 'Date' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.ReportReview)} error={errors[MonitoringType.ReportReview]}>
          <MonitoringInput
            id={MonitoringType.ReportReview}
            name={MonitoringType.ReportReview}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ReportReview)}
            user
            date={{ label: 'Date' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.ReportSent)} error={errors[MonitoringType.ReportSent]}>
          <MonitoringInput
            id={MonitoringType.ReportSent}
            name={MonitoringType.ReportSent}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ReportSent)}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: "Date d'envoi" }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.ReportValidated)} error={errors[MonitoringType.ReportValidated]}>
          <MonitoringInput
            id={MonitoringType.ReportValidated}
            name={MonitoringType.ReportValidated}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ReportValidated)}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: "Date d'envoi" }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.AmountClaimedCIR)} error={errors[MonitoringType.AmountClaimedCIR]}>
          <InputFieldAmount
            name={MonitoringType.AmountClaimedCIR}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.AmountClaimedCIR)}
            control={control}/>
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.AmountClaimedCII)} error={errors[MonitoringType.AmountClaimedCII]}>
          <InputFieldAmount
            name={MonitoringType.AmountClaimedCII}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.AmountClaimedCII)}
            control={control}/>
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.AmountAwardedCIR)} error={errors[MonitoringType.AmountAwardedCIR]}>
          <InputFieldAmount
              name={MonitoringType.AmountAwardedCIR}
              defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.AmountAwardedCIR)}
              control={control}/>
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-4" label={getMissionMonitoringLabel(MonitoringType.AmountAwardedCII)} error={errors[MonitoringType.AmountAwardedCII]}>
          <InputFieldAmount
              name={MonitoringType.AmountAwardedCII}
              defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.AmountAwardedCII)}
              control={control}/>
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.Cerfa)} error={errors[MonitoringType.Cerfa]}>
          <MonitoringInput
            id={MonitoringType.Cerfa}
            name={MonitoringType.Cerfa}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.Cerfa)}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: "Date d'envoi" }}
            control={control} />
        </MissionField>
      </div>
    </Box>

    <Box title="Élaboration du dossier" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-8 gap-6">
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.PreparatoryMeeting)} error={errors[MonitoringType.PreparatoryMeeting]}>
          <MonitoringInput
            id={MonitoringType.PreparatoryMeeting}
            name={MonitoringType.PreparatoryMeeting}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PreparatoryMeeting)}
            done={{ label:'Effectuée ?' }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.DraftFile)} error={errors[MonitoringType.DraftFile]}>
          <MonitoringInput
            id={MonitoringType.DraftFile}
            name={MonitoringType.DraftFile}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.DraftFile)}
            done={{ label:'Effectuée ?' }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.FinalFile)} error={errors[MonitoringType.FinalFile]}>
          <MonitoringInput
            id={MonitoringType.FinalFile}
            name={MonitoringType.FinalFile}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.FinalFile)}
            done={{ label:'Effectuée ?' }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
      </div>
    </Box>

    <Box title="Contrôle" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-8 gap-6">
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.ControlRequest)} error={errors[MonitoringType.ControlRequest]}>
          <MonitoringInput
            id={MonitoringType.ControlRequest}
            name={MonitoringType.ControlRequest}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ControlRequest)}
            done={{ label:'Effectuée ?' }}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: 'Date effective' }}
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.ControlProofSent)} error={errors[MonitoringType.ControlProofSent]}>
          <MonitoringInput
            id={MonitoringType.ControlProofSent}
            name={MonitoringType.ControlProofSent}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ControlProofSent)}
            done
            date
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-8" label={getMissionMonitoringLabel(MonitoringType.ControlResponse)} error={errors[MonitoringType.ControlResponse]}>
          <MonitoringInput
            id={MonitoringType.ControlResponse}
            name={MonitoringType.ControlResponse}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.ControlResponse)}
            done={{ label: 'Reçue ?' }}
            response
            date={{ label: 'Date' }}
            control={control} />
        </MissionField>
      </div>
    </Box>

    <Box title="Paiement du CIR" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-6 gap-6">
        <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.PaymentTaxSettlement)} error={errors[MonitoringType.PaymentTaxSettlement]}>
          <MonitoringInput
            id={MonitoringType.PaymentTaxSettlement}
            name={MonitoringType.PaymentTaxSettlement}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentTaxSettlement)}
            logic
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-3" label={getMissionMonitoringLabel(MonitoringType.PaymentRefund)} error={errors[MonitoringType.PaymentRefund]}>
          <MonitoringInput
            id={MonitoringType.PaymentRefund}
            name={MonitoringType.PaymentRefund}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentRefund)}
            logic
            control={control} />
        </MissionField>
        <MissionField className="col-span-4 sm:col-span-6" label={getMissionMonitoringLabel(MonitoringType.PaymentClientReminder)} error={errors[MonitoringType.PaymentClientReminder]}>
          <MonitoringInput
            id={MonitoringType.PaymentClientReminder}
            name={MonitoringType.PaymentClientReminder}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentClientReminder)}
            provisionalDate={{ label: 'Date prévisionnelle' }}
            date={{ label: "Date d'envoi" }}
            control={control} />
        </MissionField>
        {/*<MissionField className='col-span-4 sm:col-span-8' label={getMissionMonitoringLabel(MonitoringType.PaymentBilling)} error={errors[MonitoringType.PaymentBilling]} >*/}
        {/*  <MonitoringInput*/}
        {/*    name={MonitoringType.PaymentBilling}*/}
        {/*    defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentBilling)}*/}
        {/*    done*/}
        {/*    date*/}
        {/*    control={control} />*/}
        {/*</MissionField>*/}
        {/*<MissionField className="col-span-4 sm:col-span-6" label={getMissionMonitoringLabel(MonitoringType.PaymentLastReminder)} error={errors[MonitoringType.PaymentLastReminder]}>*/}
        {/*  <MonitoringInput*/}
        {/*    id={MonitoringType.PaymentLastReminder}*/}
        {/*    name={MonitoringType.PaymentLastReminder}*/}
        {/*    defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentLastReminder)}*/}
        {/*    provisionalDate={{ label: 'Date prévisionnelle' }}*/}
        {/*    date={{ label: "Date d'envoi" }}*/}
        {/*    control={control} />*/}
        {/*</MissionField>*/}
        <MissionField className='col-span-4 sm:col-span-3' label={getMissionMonitoringLabel(MonitoringType.PaymentDateCIR)} error={errors[MonitoringType.PaymentDateCIR]} >
          <MonitoringInput
            name={MonitoringType.PaymentDateCIR}
            defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentDateCIR)}
            date={{ label: '' }}
            control={control} />
        </MissionField>
        {/*<MissionField className='col-span-4 sm:col-span-3' label={getMissionMonitoringLabel(MonitoringType.PaymentDateInvoice)} error={errors[MonitoringType.PaymentDateInvoice]} >*/}
        {/*  <MonitoringInput*/}
        {/*    name={MonitoringType.PaymentDateInvoice}*/}
        {/*    defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentDateInvoice)}*/}
        {/*    date={{ label: '' }}*/}
        {/*    control={control} />*/}
        {/*</MissionField>*/}
      </div>
    </Box>
    <Box title="Paiement client de la mission" className="mt-6">
      <div className="bg-white py-6 px-4 sm:p-6 mt-6 grid grid-cols-6 gap-6">
        <MissionField className='col-span-4 sm:col-span-6' label={getMissionMonitoringLabel(MonitoringType.PaymentBilling)} error={errors[MonitoringType.PaymentBilling]} >
          <MonitoringInput
              id={MonitoringType.PaymentBilling}
              name={MonitoringType.PaymentBilling}
              defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentBilling)}
              done={{ label:'Facturable ?' }}
              provisionalDate={{ label: 'Date prévisionnelle' }}
              date={{ label: 'Date effective' }}
              control={control} />
        </MissionField>
        {/*<MissionField className="col-span-4 sm:col-span-6" label={getMissionMonitoringLabel(MonitoringType.PaymentLastReminder)} error={errors[MonitoringType.PaymentLastReminder]}>*/}
        {/*  <MonitoringInput*/}
        {/*    id={MonitoringType.PaymentLastReminder}*/}
        {/*    name={MonitoringType.PaymentLastReminder}*/}
        {/*    defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentLastReminder)}*/}
        {/*    provisionalDate={{ label: 'Date prévisionnelle' }}*/}
        {/*    date={{ label: "Date d'envoi" }}*/}
        {/*    control={control} />*/}
        {/*</MissionField>*/}
        {/*<MissionField className='col-span-4 sm:col-span-3' label={getMissionMonitoringLabel(MonitoringType.PaymentDateInvoice)} error={errors[MonitoringType.PaymentDateInvoice]} >*/}
        {/*  <MonitoringInput*/}
        {/*    name={MonitoringType.PaymentDateInvoice}*/}
        {/*    defaultValue={mission?.monitoring.find((type) => type.type === MonitoringType.PaymentDateInvoice)}*/}
        {/*    date={{ label: '' }}*/}
        {/*    control={control} />*/}
        {/*</MissionField>*/}
      </div>
    </Box>
  </>
);
