import { apiService } from './api.service';
import { AdvancedSearchQuery } from '../components/admin/pages/AdvancedSearchPage';

async function search(searchQuery: AdvancedSearchQuery): Promise<any[]> {
  const results = await apiService.post('advanced-search', searchQuery);

  return results as any;
}

async function extract(searchQuery: AdvancedSearchQuery): Promise<string> {
  const url = await apiService.post('advanced-search/extract', searchQuery);

  return url as any;
}

export const advancedSearchService = {
  search,
  extract
};
