type Props = {
  title?: string;
}
export const NotFound = ({ title }: Props) => (
  <div className="flex-1 flex justify-center items-center">
    <span className="px-24 py-12 border rounded-xl italic text-gray-500">
      {title || 'Ressource introuvable'}
    </span>
  </div>
);
