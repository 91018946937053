import { Page } from '../../general/Page';
import { MissionForm } from '../components/mission/MissionForm';

type Props = {
  clientId: string;
  missionId?: string;
}

export const MissionFormPage = ({ clientId, missionId }: Props) => {
  return (
    <Page>
      <MissionForm clientId={clientId} missionId={missionId} />
    </Page>
  );
};
