import { subject } from '@casl/ability';

import { IAdminProject } from '../interfaces/admin-project.interface';
import { apiService } from './api.service';
import { IUpdateWorklog, IWorklog } from '../interfaces/worklog.interface';
import { getMediaUrl } from '../utils/get-media-url';
import { Action, Subject } from '../casl/enums';
import { abilityService } from './ability.service';
import { IProject } from '../interfaces/project.interface';

function completeUrl<T extends (IWorklog | IWorklog[] | undefined)>(target: T): T {
  if (target) {
    if (Array.isArray(target)) {
      target.forEach(completeUrl);
    } else {
      if (target.download) {
        target.download = getMediaUrl(target.download);
      }
    }
  }
  return target;
}

async function get(projectId: string): Promise<IWorklog | undefined> {
  try {
    return completeUrl(await apiService.get<IWorklog>(`projects/${projectId}/worklog`));
  } catch (err) {
    if (err.response?.status === 404) {
      return undefined;
    }
    throw err;
  }
}

export interface IWorklogSummary {
  validated: number;
  partialValidated: number;
  dirty: number;
}

export interface IWorklogStatus {
  project: IAdminProject;
  summary: IWorklogSummary;
}

function getStatus(projectId: string): Promise<IWorklogStatus> {
  return apiService.get<IWorklogStatus>(`projects/${projectId}/worklog/status`);
}

async function getList(projectId: string): Promise<IWorklog[]> {
  return completeUrl(await apiService.get<IWorklog[]>(`projects/${projectId}/worklog/all`));
}

async function update(projectId: string, worklog: Partial<IUpdateWorklog>) {
  return completeUrl(await apiService.put<Partial<IUpdateWorklog>, IWorklog>(`projects/${projectId}/worklog`, worklog));
}

function can(action: Action, source?: Partial<IWorklog>, field?: keyof IWorklog): boolean {
  const ability = abilityService.getAbility();
  return ability.can(action, source ? subject(Subject.Worklog, source) : Subject.Worklog, field);
}

function canFromProject(action: Action, project: Partial<IProject>, field?: keyof IWorklog) {
  return can(
    action,
    {
      projectId: project.id,
      clientId: project.clientId,
      managerId: project.managerId,
      userIds: project.userIds,
    },
    field);
}

export const worklogService = {
  can,
  canFromProject,
  get,
  getList,
  update,
  getStatus,
};
