import { useMemo } from 'react';

import { getClientFunctionLabel } from '../../../enums/client-function.enum';
import { IClientContact } from '../../../interfaces/client-contact.interface';
import { NoData } from '../../general/NoData';
import { Table, TableColumn } from '../../general/Table';
import { formatPhone } from '../../../utils/format-phone-number';

type Props = {
  contacts: IClientContact[];
}
export const MissionContactList = ({ contacts }: Props) => {
  const columns = useMemo<Array<TableColumn<IClientContact>>>(
    () => [
      {
        Header: 'Fonction',
        accessor: 'role',
        Cell : ({ value }) => getClientFunctionLabel(value),
      },
      { Header: 'Nom', accessor: 'lastname' },
      { Header: 'Prénom', accessor: 'firstname' },
      { Header: 'Email', accessor: 'email', disableSortBy: true },
      { Header: 'Téléphone', accessor: contact => formatPhone(contact.phone), disableSortBy: true },
    ],
    []
  );

  if(contacts.length === 0){
    return <NoData>Aucun contact</NoData>;
  }
  return <Table columns={columns} data={contacts} />;
};
