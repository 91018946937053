import { Link } from 'react-router-dom';
import clsx from 'clsx';

import Avatar from '../../assets/avatar.png';
import { Badge } from './Badge';
import { getMediaUrl } from '../../utils/get-media-url';
import { IUser } from '../../interfaces/user.interface';
import { UserRole } from '../../enums/user-role.enum';
import { IAdmin, IAdminLight } from '../../interfaces/admin.interface';

const colors = {
  purple: { name: 'text-purple-600', link: 'hover:text-purple-800', details: 'text-gray-400' },
  gray: { name: 'text-gray-700', link: 'hover:text-gray-900', details: 'text-gray-400' },
};

type Props = {
  user: IUser | IAdmin | IAdminLight;
  color?: keyof typeof colors;
  showJob?: boolean;
  link?: string;
  badge?: boolean;
};

export const CardAvatarName = ( { user, color='gray', showJob, link, badge } : Props ) => (
  <div className="flex items-center">
    <img className="h-9 w-9 rounded-full" src={ getMediaUrl(user.avatar?.url) || Avatar } alt={`${user.firstname} ${user.lastname}`}/>
    <div className="ml-4">
      <div className={ showJob ? 'flex items-center space-x-3' : '' }>
        <p className={clsx('text-sm font-medium truncate', colors[color].name)}>
          { link ?
            <Link className={colors[color].link} to={link}>{user.firstname} {user.lastname}</Link>
            :
            <span>{user.firstname} {user.lastname}</span>
          }
        </p>
        { badge && 'role' in user && (user.role === UserRole.ClientRoot) && <Badge color='purple'>Admin</Badge> }
      </div>
      { showJob && 'job' in user && user.job && <p className={clsx('text-sm truncate', colors[color].details)}>{user.job}</p> }
    </div>
  </div>
);
