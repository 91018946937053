import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';

import { IUser } from '../../interfaces/user.interface';
import { userService } from '../../services/user.service';
import { Box } from './Box';
import { MediaList } from './MediaList';
import { IMedia } from '../../interfaces/media.interface';
import { ConfirmationPopup } from './ConfirmationPopup';
import { mediaService } from '../../services/media.service';
import { showToast } from './show-toast';

type Props = {
  user: IUser;
  onSave: (user: IUser) => void;
};

export const UserDocumentsForm = ({ user, onSave }: Props ) => {
  const [{ mediaToDelete, deleteConfirmed }, setDeleteState] = useState<Partial<{mediaToDelete: IMedia, deleteConfirmed: boolean}>>({});

  const onDeleteRequest = useCallback((media: IMedia) => {
    setDeleteState({ mediaToDelete: media });
  }, []);

  const onDrop = useCallback(async (files: File[]) => {
    let userUpdated: IUser | undefined  = undefined;
    for (const file of files) {
      try {
        userUpdated = await userService.addDocument(user.id, file);
      } catch (error) {
        showToast({ error });
      }
    }
    if (userUpdated) {
      onSave(userUpdated);
    }
  }, [user.id, onSave]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    maxSize: 20 * 1024 * 1024,
    onDrop,
  });

  useEffect(() => {
    if (mediaToDelete && deleteConfirmed) {
      mediaService
        .deleteMedia(mediaToDelete)
        .then(({ success }) => {
          console.log(success);
          setDeleteState({});
          if (success) {
            onSave({ ...user, documents: user.documents.filter(media => media.url !== mediaToDelete.url ) });
          } else {
            showToast({ type: 'error', content: 'Une erreur s\'est produite' });
          }
        })
        .catch(error => showToast({ error }));
    }
  }, [user, mediaToDelete, deleteConfirmed, onSave]);

  let border = 'border-gray-300';
  if (isDragActive) {
    if (isDragReject) {
      border = 'border-red-400';
    } else {
      border = 'border-gray-400 hover:border-gray-600 hover';
    }
  }

  return (
    <>
      <Box title="Documents">
        <MediaList list={user.documents} onDeleteRequest={onDeleteRequest} />
        <div className={clsx('flex justify-center flex-col p-6 border-2 border-dashed rounded-md cursor-pointer', border)} {...getRootProps()}>
          <input {...getInputProps()} />
          <p className="text-center text-sm p-1">
            <span className="text-indigo-500">Sélectionner</span>&nbsp;<span>ou glisser-déposer des CV, diplômes...</span>
            <br/>
            <span className={clsx('text-xs', isDragReject ? 'text-red-500' : 'text-gray-500')}>jusqu&apos;à 20Mo</span>
          </p>
        </div>
      </Box>
      {
        mediaToDelete && !deleteConfirmed && (
          <ConfirmationPopup title="Attention !" onNo={() => setDeleteState({})} onYes={() => setDeleteState({ mediaToDelete, deleteConfirmed: true })}>
            <p className="text-sm text-gray-500">
              Ce fichier va être supprimé de manière <strong>définitive</strong>. <br />
              Continuer ?
            </p>
          </ConfirmationPopup>
        )
      }
    </>
  );
};
