import { getMissionCategoryLabel } from '../../../enums/mission-category.enum';
import { IMission } from '../../../interfaces/mission.interface';
import { formatToShortFrench } from '../../../utils/timeslot-dates';

type Props = {
  mission: IMission;
}

export const MissionHeader = ({ mission }: Props) => {
  return (
    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
      <div className="mt-2 flex items-center text-sm text-gray-500" title="Catégorie de la mission">
        <i className="far fa-clipboard"/>&nbsp;<span>{getMissionCategoryLabel(mission.category)}</span>
      </div>
      <div className="mt-2 flex items-center text-sm text-gray-500" title="Avancement de la mission">
        <i className="fas fa-tasks"/>&nbsp;{mission.progress}%
      </div>
      <div className="mt-2 flex items-center text-gray-500 text-sm" title="Échéance de la mission">
        <i className="far fa-calendar"/>&nbsp;{formatToShortFrench(mission.deadline)}
      </div>
      <div className="mt-2 flex items-center text-gray-500 text-sm" title={mission.completed ?  'Mission terminée' :  'Mission en cours'} >
        <i className="far fa-calendar-check" />
        &nbsp;{ mission.completed ? formatToShortFrench(mission.completed!) : 'Mission en cours' }
      </div>
    </div>
  );
};
