import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';

import { FormFieldBase  } from './types';

export type FormFieldCustom = FormFieldBase & {
  type: 'custom';
  render: (props: { field: ControllerRenderProps }) => ReactElement
}

export const Custom = ({ field, control }: { field: FormFieldCustom, control: any } ) => (
  <Controller
    name={field.name}
    defaultValue={field.defaultValue}
    control={control}
    render={field.render}
  />
);
