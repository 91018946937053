import { Redirect, Route, Switch } from 'react-router-dom';

import { AdminCredentialPage } from './pages/AdminCredentialPage';
import { CredentialPage } from './pages/CredentialPage';
import { MissionFormPage } from './pages/MissionFormPage';
import { MissionListPage } from './pages/MissionListPage';
import { MissionPage } from './pages/MissionPage';
import { ProjectFormPage } from './pages/ProjectFormPage';
import { AdminFormPage } from './pages/adminFormPage';
import { AdminListPage } from './pages/AdminListPage';
import { AdminProfilePage } from './pages/AdminProfilePage';
import { ProjectPage } from './pages/ProjectPage';
import { UserProfilePage } from './pages/UserProfilePage';
import { ClientListPage } from './pages/ClientListPage';
import { ClientPage } from './pages/ClientPage';
import { ClientFormPage } from './pages/ClientFormPage';
import { UserFormPage } from './pages/UserFormPage';
import { MyProfilePage } from './pages/MyProfilePage';
import { MyProfileFormPage } from './pages/MyProfileFormPage';
import { TimeslotPage } from '../general/TimeslotPage';
import { Dashboard } from './pages/Dashboard';
import { Action } from '../../casl/enums';
import { missionService } from '../../services/mission.service';
import { InvoiceListPage } from './pages/InvoiceListPage';
import { InvoiceFormPage } from './pages/InvoiceFormPage';
import { InvoicePage } from './pages/InvoicePage';
import { AdvancedSearchPage } from './pages/AdvancedSearchPage';
import { TimeEntryListPage } from './pages/TimeEntryListPage';

export const Routes = () => {
    const canCreateMission = missionService.can(Action.Create);
    return (
      <Switch>
        <Route exact path="/" component={Dashboard}/>

        <Route exact path="/profile" component={MyProfilePage}/>
        <Route exact path="/profile/edit" component={MyProfileFormPage}/>

        { canCreateMission && <Route exact path="/missions" component={MissionListPage}/> }

        <Route exact path="/clients" component={ClientListPage}/>
        <Route exact path="/admins" component={AdminListPage}/>
        <Route exact path="/invoices" component={InvoiceListPage}/>

        <Route exact path="/admins/create" component={AdminFormPage}/>
        <Route exact path="/admins/:id" render={({ match: { params: { id } } }) => <AdminProfilePage adminId={id}/>}/>
        <Route exact path="/admins/:id/edit" render={({ match: { params: { id } } }) => <AdminFormPage adminId={id}/>}/>
        <Route exact path="/admins/:id/credentials" render={({ match: { params: { id } } }) => <AdminCredentialPage adminId={id}/>}/>

        <Route exact path="/clients/create" render={() => <ClientFormPage/>}/>
        <Route exact path="/clients/:id" render={({ match }) => <ClientPage clientId={match.params.id}/>}/>
        <Route exact path="/clients/:id/edit" render={({ match }) => <ClientFormPage clientId={match.params.id}/>}/>

        <Route exact path="/clients/:id/missions/create" render={({ match }) => <MissionFormPage clientId={match.params.id}/>}/>
        <Route exact path="/clients/:id/missions/:missionId" render={({ match: { params: { id, missionId } } }) => <MissionPage clientId={id} missionId={missionId}/>}/>
        <Route exact path="/clients/:id/missions/:missionId/edit" render={({ match: { params: { id, missionId } } }) => <MissionFormPage clientId={id} missionId={missionId}/>}/>
        <Route exact path="/clients/:id/missions/:missionId/invoices/create" render={({ match: { params: { missionId } } }) => <InvoiceFormPage missionId={missionId}/>}/>

        <Route exact path="/clients/:id/users/create" render={({ match }) => <UserFormPage clientId={match.params.id}/>}/>
        <Route exact path="/clients/:id/users/:userId" render={({ match: { params: { id, userId } } }) => <UserProfilePage clientId={id} userId={userId}/>}/>
        <Route exact path="/clients/:id/users/:userId/credentials" render={({ match: { params: { userId } } }) => <CredentialPage userId={userId}/>}/>
        <Route exact path="/clients/:id/users/:userId/edit" render={({ match: { params: { id, userId } } }) => <UserFormPage clientId={id} userId={userId}/>}/>
        <Route exact path="/clients/:id/users/:userId/timeslots" render={({ match: { params: { id, userId } } }) => <TimeslotPage clientId={id} userId={userId}/>}/>

        <Route exact path="/clients/:id/projects/:projectId" render={({ match: { params: { id, projectId } } }) => <ProjectPage clientId={id} projectId={projectId}/>}/>
        <Route exact path="/clients/:id/projects/:projectId/edit" render={({ match: { params: { projectId } } }) => <ProjectFormPage projectId={projectId}/>}/>

        <Route exact path="/clients/:id/invoices/:invoiceId" render={({ match: { params: { id, invoiceId } } }) => <InvoicePage clientId={id} invoiceId={invoiceId}/>}/>
        <Route exact path="/clients/:id/invoices/:invoiceId/edit" render={({ match: { params: { invoiceId } } }) => <InvoiceFormPage invoiceId={invoiceId}/>}/>

        <Route exact path="/advanced-search" render={({ match }) => <AdvancedSearchPage />}/>
        <Route exact path="/time-entries" render={({ match }) => <TimeEntryListPage />}/>

        <Redirect from="/clients/:id/invoices*" to={{ pathname: '/clients/:id', search: '?tab=invoices' }}/>
        <Redirect from="/clients/:id/missions*" to={{ pathname: '/clients/:id', search: '?tab=missions' }}/>
        <Redirect from="/clients/:id/projects*" to={{ pathname: '/clients/:id', search: '?tab=projects' }}/>
        <Redirect from="/clients/:id/users*" to={{ pathname: '/clients/:id', search: '?tab=users' }}/>
        <Redirect from="/clients/:id/*" to="/clients/:id"/>

        <Redirect to='/'/>
    </Switch>
    );
};
