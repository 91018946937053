import { Page } from '../../general/Page';
import { InvoiceForm } from '../components/InvoiceForm';
import { useInvoice } from '../../../hooks/use-invoice';
import { Spinner } from '../../general/Spinner';

type Props = {
  missionId?: string;
  invoiceId?: string;
}

export const InvoiceFormPage =({ missionId, invoiceId }: Props) => {
  const { invoice, loading } = useInvoice(invoiceId);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Page>
      <InvoiceForm missionId={missionId} invoice={invoice} />
    </Page>
  );
};
