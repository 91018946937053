import { MouseEvent } from 'react';

type Props = {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  label?: string;
}

export const CancelButton = ({ onClick, label } : Props) => (
  <button className="btn ml-3" onClick={onClick}>
    {label || 'Non'}
  </button>
);
