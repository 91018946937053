import { Editor } from '@tinymce/tinymce-react';
import { Controller } from 'react-hook-form';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import { RawEditorSettings } from 'tinymce';

import { FormFieldBase } from './types';
import { IMedia } from '../../../../interfaces/media.interface';
import { getMediaUrl } from '../../../../utils/get-media-url';

export type UploadHandler = (blob: Blob, filename: string) => Promise<IMedia>;

function tinymceInit(darkSkin: boolean, options: Partial<{ uploadHandler: UploadHandler}>): RawEditorSettings & { selector?: undefined; target?: undefined; } {
  return {
    skin_url: darkSkin ? '/tinymce/skins/ui/oxide-dark' : '/tinymce/skins/ui/oxide',
    branding: false,
    elementpath: false,
    entity_encoding: 'raw',
    resize: true,
    height: '35rem',
    language: 'fr_FR',
    language_url: '/tinymce/langs/fr_FR.js',
    contextmenu: 'link',
    menubar: 'edit insert format tableau',
    menu: {
      insert: { title: 'Insert', items: 'image' },
      format: { title: 'Format', items: 'bold italic underline | subscript superscript | align  | forecolor backcolor | removeformat' },
      tableau: { title: 'Tableau', items: 'inserttable deletetable | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolumnbefore tableinsertcolumnafter tabledeletecolumn | tablemergecells tablesplitcells' },
    },
    plugins: ['link lists advlist image table paste'],
    toolbar: 'undo redo | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | subscript superscript | bullist | image',
    table_toolbar: 'tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tablemergecells tablesplitcells',
    table_column_resizing: 'resizetable',
    formats: {
      alignleft: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table', styles: { textAlign: 'left' } },
      aligncenter: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table', styles: { textAlign: 'center' } },
      alignright: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table', styles: { textAlign: 'right' } },
      underline: { inline: 'u', styles: { 'text-decoration': 'underline' }, exact: true },
    },
    advlist_bullet_styles: 'default',
    block_unsupported_drop: true,
    convert_urls: false,
    relative_urls: false,
    remove_script_host: false,
    paste_data_images: false,
    image_advtab: false,
    images_reuse_filename: true,
    image_title: true,
    automatic_uploads: true,
    file_picker_types: 'image',
    file_picker_callback: function (cb) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      /*
        Note: In modern browsers input[type="file"] is functional without
        even adding it to the DOM, but that might not be the case in some older
        or quirky browsers like IE, so you might want to add it to the DOM
        just in case, and visually hide it. And do not forget do remove it
        once you do not need it anymore.
      */

      input.onchange = function () {
        const files = input.files;
        if (!files) {
          return ;
        }
        const file = files[0];

        const reader = new FileReader();
        reader.onload = function () {
          const id = 'blobid' + (new Date()).getTime();
          const blobCache =  (window as any).tinymce.activeEditor.editorUpload.blobCache;
          const base64 = reader.result?.toString().split(',')[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };

      input.click();
    },
    images_upload_handler: (blobInfo, success, failure) => {
      if (options.uploadHandler) {
        options
          .uploadHandler(blobInfo.blob(), blobInfo.filename())
          .then(media => {
            success(getMediaUrl(media.url));
          })
          .catch(error => failure(error?.response?.data?.message || error.message || error));
      }
    }

  };
}

export type FormFieldWysiwyg = FormFieldBase & {
  type: 'wysiwyg';
  name: string;
  disabled?: boolean;
  defaultValue?: string;
  outputFormat?: 'html' | 'text';
  rules?: any;
  uploadHandler?: UploadHandler
}

/**
 * Server code is sanitized against unexpected tag & style,
 * the code sanitized does not follow the tinyMCE format (spaces, semi-colon...)
 * so we need to reformat it to avoid the dirty checker to get fucked
 *
 * Example:
 *  code from server
 *    <span style="color:#b96ad9;background-color:#169179">...</span>
 *
 *  TinyMCE
 *    <span style="color: #b96ad9; background-color: #169179;">...</span>
 *
 */
function dirtySafe(html: string): string {
  // catch all styles
  return html.replace(/style="([^"]+);?"/g, (match, content) => {
    // add spaces around all tuples and semi-colon after each of them
    content = content.replace(/([^:;]+):([^:;]+)[\s;]*/g, ' $1: $2;');
    return `style="${content.trim()}"`; // re-inject style removing the first space
  });
}

export const WysiwygEditor = ({ field, control } : { field: FormFieldWysiwyg, control: any }) => {
  const darkSkin = false;
  const defaultValue = dirtySafe(field.defaultValue);
  return (
    <div className="flex">
      <div className="flex-grow">
        <Controller
          name={field.name}
          control={control}
          defaultValue={defaultValue}
          rules={field.rules}
          render={({ field: { onChange, value } }) => (
            <Editor
              initialValue = {defaultValue}
              init = {tinymceInit(darkSkin, field) as any}
              disabled = {field.disabled}
              outputFormat = {field.outputFormat}
              textareaName = {field.name}
              value = {value}
              onEditorChange = {onChange}
            />
          )}
        />
      </div>
    </div>
  );
};
