import { ChangeEvent } from 'react';

import calendarLogo from '../../../../../assets/icons/calendar_icon.png';

type YearOptions = {
  id: string;
  year: number | undefined;
  clear: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const MissionYear = ({ id, year, clear, onChange, disabled }: YearOptions) => (
  <div className="flex">
    <input
      id={id}
      type='number'
      value={!clear ? year : ''}
      onChange={onChange}
      placeholder="Entrer une année"
      min={2020}
      max={2040}
      disabled={disabled}
    />
    <label className="flex-initial cursor-pointer h-8 my-auto" htmlFor={id}>
      <img className="hidden sm:inline-block" src={calendarLogo} alt="calendrier" style={{ width: 32 }}/>
    </label>
  </div>
);
