import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { differenceInMilliseconds } from 'date-fns';

import { MissionCategory } from '../../../../enums/mission-category.enum';
import { MonitoringType } from '../../../../enums/mission-monitoring.enum';
import { useMission } from '../../../../hooks/use-mission';
import { missionService } from '../../../../services/mission.service';
import { fromYMD } from '../../../../utils/timeslot-dates';
import { ButtonBar } from '../../../general/buttons/ButtonBar';
import { ConfirmationPopup } from '../../../general/ConfirmationPopup';
import { NotFound } from '../../../general/NotFound';
import { Spinner } from '../../../general/Spinner';
import { TeamInput } from './components/TeamInput';
import { showToast } from '../../../general/show-toast';
import { MissionFormCirCii } from './MissionFormCirCii';
import { MissionFormFinancing } from './MissionFormFinancing';
import { MissionFormAnother } from './MissionFormAnother';
import { AdminInput } from './components/AdminInput';
import { Can } from '../../../../casl/can';
import { Action, Subject } from '../../../../casl/enums';
import { MissionHeaderForm } from './MissionHeaderForm';
import { IMission } from '../../../../interfaces/mission.interface';

type Props = {
  clientId: string;
  missionId?: string;
}

function calculateTimeBetween (started?: string, ended?: string) {
  const timeBetween = started && ended && differenceInMilliseconds( fromYMD(started), fromYMD(ended));
  return timeBetween || undefined;
}

const arrayKeys: Array<keyof IMission> = ['admins', 'clientContacts'];

export const MissionForm = ({ clientId, missionId }: Props) => {
  const history = useHistory();
  const { loading, mission } = useMission(missionId);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const { register, control, setValue, getValues, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<any>({ mode: 'all', shouldUnregister: true });

  useEffect(() => {
    if (mission) {
      // required by the fields array to get the default value
      arrayKeys.forEach(key => {
        if (missionService.can(Action.Update, mission, key)) {
          setValue(key, mission[key]);
        }
      });
    }
  }, [mission]);

  if (loading) {
    return <Spinner />;
  }

  if (missionId && !mission) {
    return <NotFound title="Mission introuvable" />;
  }

  const category = getValues('category') || (missionId && mission?.category) || MissionCategory.CirCii;

  const cancel = () => history.goBack();

  const onSubmit = (data: any) => {

    const payload: any = {
      id: mission?.id,
      clientId,
      monitoring: [],
    };

    arrayKeys.forEach(key => {
      if (missionService.can(Action.Update, mission, key)) {
        payload[key] = data[key] || [];
      }
    });

    const monitoringTypes: string[] = Object.values(MonitoringType);

    for (const key of Object.keys(data)) {
      if (monitoringTypes.includes(key)) {
        payload.monitoring.push({
          type: key,
          ... data[key]
        });
      } else {
        payload[key] = data[key];
      }
    }

    // calculate time between
    if (category === MissionCategory.CirCii) {
      payload.monitoring.push({ type: MonitoringType.ProcessTimeCerfa, process: calculateTimeBetween(data[MonitoringType.PaymentDateCIR].date, data[MonitoringType.Cerfa].date) });
      // payload.monitoring.push({ type: MonitoringType.ProcessTimeInvoicing, process: calculateTimeBetween(data[MonitoringType.PaymentDateInvoice].date, data[MonitoringType.BriefClient].date) });
    }
    // payload.monitoring.push({ type: MonitoringType.ProcessTimeBilling, process: calculateTimeBetween(data[MonitoringType.PaymentDateInvoice].date, data[MonitoringType.PaymentBilling].date) });

    if (payload.progress) {
      payload.progress = Number(payload.progress) || 0;
    }

    return missionService
      .upsert(payload)
      .then(mission => {
        history.push(`/clients/${clientId}/missions/${mission.id}`);
      })
      .catch(error => showToast({ error }));
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>

      <MissionHeaderForm mission={mission} category={category} register={register} control={control} errors={errors} />

      <Can I={Action.Update} this={mission || {}} as={Subject.Mission} field={'admins'}>
        <AdminInput title="Équipe Panda" name='admins' category={category} register={register} control={control}/>
      </Can>

      <Can I={Action.Update} this={mission || {}} as={Subject.Mission} field={'clientContacts'}>
        <TeamInput title='Équipe Client' name='clientContacts' type='mission' error={errors.clientContacts} register={register} control={control} />
      </Can>

      { category === MissionCategory.CirCii && <MissionFormCirCii mission={mission} control={control} errors={errors} /> }
      { category === MissionCategory.Finance && <MissionFormFinancing mission={mission} control={control} errors={errors} /> }
      { category === MissionCategory.Another && <MissionFormAnother mission={mission} control={control} errors={errors} /> }

      <ButtonBar className="mt-6">
        <button key="btn-cancel" type="button" className="btn ml-3" onClick={() => isDirty ? setShowConfirmation(true): cancel()}>
          Annuler
        </button>
        <button key="btn-ok" type="submit" className="btn btn-primary ml-3" disabled={!isValid || !isDirty}>
          { mission ? 'Modifier' : 'Créer'}
        </button>
      </ButtonBar>
    </form>

    {showConfirmation && (
      <ConfirmationPopup title="Annuler cette action ?" onNo={() => setShowConfirmation(false)} onYes={cancel}>
        <p className="text-sm text-gray-500">
          Les changements seront annulés. Cette action sera <strong>définitive</strong>. <br />
          Continuer ?
        </p>
      </ConfirmationPopup>
    )}
    </>
  );
};
