import { useContext } from 'react';

import { AccountContext } from '../../contexts/account.context';
import { AvatarProfil } from '../../general/AvatarProfil';
import { Box } from '../../general/Box';
import { ButtonBar } from '../../general/buttons/ButtonBar';
import { EditButton } from '../../general/buttons/EditButton';
import { Page } from '../../general/Page';
import { UserProfile } from '../../general/UserProfile';

export const MyProfilePage = () => {
  const { getUser } = useContext(AccountContext);
  const me = getUser();
  return (
    <Page>
      <Box title="Votre profil">
        <ButtonBar>
          <EditButton to='/profile/edit'/>
        </ButtonBar>
        <AvatarProfil user={me}/>
      </Box>
      <UserProfile user={me}/>
    </Page>
  );
};
