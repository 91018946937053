import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import { Can } from '../../casl/can';
import { Action, Subject } from '../../casl/enums';
import { getRoleLabel } from '../../casl/roles-label';
import { useClient } from '../../hooks/use-client';
import { IUser } from '../../interfaces/user.interface';
import { Box } from './Box';
import { NotFound } from './NotFound';
import { Spinner } from './Spinner';
import { Item } from './grid/Item';
import { Grid } from './grid/Grid';
import { MediaList } from './MediaList';
import { formatPhone } from '../../utils/format-phone-number';

type Props = {
  user: IUser;
};

function toDateString(value: string | undefined) {
  if (value) {
    return format(new Date(value), 'dd MMMM yyyy', { locale: fr });
  }
}

export const UserProfile = ({ user }: Props) => {
  const { client, loading } = useClient(user.clientId);

  if (loading) {
    return <Spinner />;
  }

  if (!client) {
    return <NotFound title="Société introuvable" />;
  }

  return <>
    <Box title="Documents">
      <MediaList list={user.documents} />
    </Box>
    <Box title="Renseignements personnels">
      <Grid>
        <Item label='Nom'>{user.lastname}</Item>
        <Item label='Prénom'>{user.firstname}</Item>
        <Item label='Email'>{user.email}</Item>
        <Item label='Téléphone'>{formatPhone(user.phone)}</Item>
        <Item label='Diplôme'>{user.diploma}</Item>
        <Item label='Obtenu le'>{toDateString(user.diplomaDate)}</Item>
        <Item label='Jeune docteur'>{user.youngDoctor ? 'oui' : 'non'}</Item>
      </Grid>
    </Box>
    <Box title="Renseignements professionnels">
      <Grid>
        {client && (<Item label="Société">{client.name}</Item>)}
        <Can I={Action.Read} a={user} as={Subject.User} field="role">
          <Item label="Type">{getRoleLabel(user.role)}</Item>
        </Can>
        <Item label="Fonction">{user.job}</Item>
        <Item label="Date d'embauche">{toDateString(user.startDate)}</Item>
        <Can I={Action.Read} a={user} as={Subject.User} field="endDate">
          <Item label="Date de fin">{user.endDate ? toDateString(user.endDate) : 'En poste'}</Item>
        </Can>
      </Grid>
    </Box>
  </>;
};
