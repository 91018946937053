import { FC } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

type Props = {
  to: string;
  className?: string;
  role?: string;
}

export const TextLink: FC<Props> = ({ className, to, role, children }) => (
  <Link to={to} className={clsx('truncate text-gray-600 hover:text-gray-900', className)} role={role}>{children}</Link>
);
