import { useContext, useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { AccountContext } from '../contexts/account.context';
import { userService } from '../../services/user.service';
import { Spinner } from './Spinner';
import { getRoleLabel } from '../../casl/roles-label';
import { GetCellPropsFunction, Table, TableColumn } from './Table';
import { IUser } from '../../interfaces/user.interface';
import { CardAvatarName } from './CardAvatarName';
import { formatPhone } from '../../utils/format-phone-number';
import { OpenIconLink } from './OpenIconLink';

type Props = {
  clientId: string;
  className?: string;
  pageSize?: number;
}

export const UserList = ({ clientId, className, pageSize }: Props) => {
  const { isAdmin } = useContext(AccountContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<IUser[]>([]);

  const columns = useMemo<Array<TableColumn<IUser>>>(
    () => [
      {
        Header: 'Nom',
        accessor: user => `${user.lastname} ${user.firstname}`,
        Cell: ({ row: { original: user } }: CellProps<IUser>) => <CardAvatarName user={user} link={isAdmin ? `/clients/${clientId}/users/${user.id}` : `/users/${user.id}`}/>,
        sortType: 'basic'
      },
      { Header: 'Type', accessor: user => getRoleLabel(user.role) },
      { Header: 'Fonction', accessor: 'job' },
      { Header: 'Email', accessor: 'email', disableSortBy: true },
      { Header: 'Téléphone',
        accessor: 'phone',
        Cell : ({ value }) => formatPhone(value),
        disableSortBy: true },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({ row: { original: user } }: CellProps<IUser>) => <OpenIconLink to={isAdmin ? `/clients/${clientId}/users/${user.id}` : `/users/${user.id}`} title="Voir le profil"/>
      },
    ],
    [clientId, isAdmin]
  );

  const getCellProps: GetCellPropsFunction<IUser> = (cell) => {
    if (cell.column.id === 'actions') {
      return {
        className: 'text-right font-medium'
      };
    }
    return {};
  };

  useEffect(() => {
    userService
      .getList(clientId)
      .then(setUsers)
      .then(() => setLoading(false));
  }, [clientId]);

  if (loading) {
    return <Spinner />;
  }

  return <Table columns={columns} data={users} className={className} pageSize={pageSize} getCellProps={getCellProps} />;
};
