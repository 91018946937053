import { Page } from '../../general/Page';
import { useProject } from '../../../hooks/use-project';
import { Spinner } from '../../general/Spinner';
import { NotFound } from '../../general/NotFound';
import { useWorklog } from '../../../hooks/use-worklog';
import { WorklogForm } from '../components/WorklogForm';

type Props = {
  projectId?: string;
};

export const WorklogFormPage = ({ projectId }: Props) => {
  const { project, loading } = useProject(projectId);
  const { worklog, loading: loadingWorklog } = useWorklog(projectId);

  if (loading || loadingWorklog) {
    return <Spinner />;
  }

  if (!project) {
    return <NotFound title="Projet introuvable" />;
  }

  return (
    <Page>
      <WorklogForm project={project} worklog={worklog} />
    </Page>
  );
};
