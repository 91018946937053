import React from 'react';

import { IUser } from '../../interfaces/user.interface';
import { IAdmin } from '../../interfaces/admin.interface';
import { isUser } from '../../type-guards/is-user.type-guard';
import { isAdmin } from '../../type-guards/is-admin.type-guard';

type AccountContextType = {
  account: IUser | IAdmin;
  getAdmin: () => IAdmin;
  getUser: () => IUser;
  isAdmin: boolean;
  setAccount: (user: IUser | IAdmin | undefined) => void;
}

export const AccountContext = React.createContext<AccountContextType>({
  account: {} as any,
  getAdmin: () => { throw new Error('user undefined'); },
  getUser: () => { throw new Error('user undefined'); },
  isAdmin: false,
  setAccount: () => { throw new Error('user undefined'); },
});

export const AccountContextValue = ({ account, setAccount }: Pick<AccountContextType, 'account' | 'setAccount'>): AccountContextType => ({
  account,
  getAdmin: () => {
    if (!isAdmin(account)) {
      throw new Error('Erreur de compte');
    }
    return account;
  },
  getUser: () => {
    if (!isUser(account)) {
      throw new Error('Erreur de compte');
    }
    return account;
  },
  isAdmin: isAdmin(account),
  setAccount,
});
