import { subject } from '@casl/ability';

import { IUpdateCredentials } from '../interfaces/credentials.interface';
import { apiService } from './api.service';
import { ICreateUser, IUpdateUser, IUser } from '../interfaces/user.interface';
import { buildQueryString } from '../utils/build-query-string';
import { Action, Subject } from '../casl/enums';
import { abilityService } from './ability.service';

async function login(credentials : { email: string; password: string }): Promise<{ access_token: string; rules: any } | { ban: number } | null > {
  try {
    return await apiService.post<{ email: string; password: string }, { access_token: string, rules: any }>('auth/login', credentials);
  } catch (err) {
    if (err?.response?.data?.ban) {
      return { ban: err?.response.data.ban };
    }
    return null;
  }
}

function logout() {
  return apiService.post('auth/logout', {});
}

function get(id: string): Promise<IUser> {
  return apiService.get<IUser>(`users/${id}`);
}

async function getList(clientId?: string): Promise<IUser[]> {
  const { users } = await apiService.get<{ users: IUser[] }>(`users?${buildQueryString({ clientId })}`);
  return users;
}

function create(user: ICreateUser): Promise<IUser> {
  return apiService.post<ICreateUser, IUser>('users', user);
}

function update(id: string, user: IUpdateUser): Promise<IUser> {
  return apiService.put<IUpdateUser, IUser>(`users/${id}`, user);
}

function upsert(user: ICreateUser | (IUpdateUser & { id : string }) ): Promise<IUser> {
  if ('id' in user && user.id) {
    return update(user.id, user);
  }
  return create(user as ICreateUser);
}

async function updateAvatar(userId: string, dataURL: string) {
  // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript/36183085#36183085
  const res = await fetch(dataURL);
  const blob = await res.blob();
  return apiService.upload<IUser>(`users/${userId}/avatar`, blob, 'avatar.jpg');
}

async function addDocument(userId: string, file: File) {
  return apiService.upload<IUser>(`users/${userId}/documents`, file);
}

function updateCredentials(id: string, credentials: Partial<IUpdateCredentials>): Promise<IUser> {
  return apiService.put<Partial<IUpdateCredentials>, IUser>(`users/${id}/credentials`, credentials);
}

function can(action : Action, source?: Partial<IUser>, field?: keyof IUser): boolean {
  const ability = abilityService.getAbility();
  return ability.can(action, source ? subject(Subject.User, source) : Subject.User, field);
}

export const userService = {
  addDocument,
  can,
  create,
  get,
  getList,
  login,
  logout,
  update,
  updateAvatar,
  updateCredentials,
  upsert,
};
