import { Aside, Page } from '../../general/Page';
import { ProjectForm } from '../components/ProjectForm';
import { useQuery } from '../../../hooks/use-query';
import { useProject } from '../../../hooks/use-project';
import { Spinner } from '../../general/Spinner';
import { NotFound } from '../../general/NotFound';
import { ProjectTreeBox } from '../../general/ProjectTreeBox';

type Props = {
  projectId?: string;
};

export const ProjectFormPage = ({ projectId }: Props) => {
  const { query } = useQuery();
  const { project, loading } = useProject(projectId);
  const parentId = projectId ? undefined : query.get('parentId') || undefined;
  const isTask = project?.isTask || query.get('task') === '1';
  const { project: parent, loading: loadingParent } = useProject(parentId);

  if (loading || loadingParent) {
    return <Spinner />;
  }

  if (projectId && !project) {
    return <NotFound title="Projet introuvable" />;
  }

  const projectOrParent = project || parent;

  return (
    <Page>
      { projectOrParent && (
        <Aside>
          <ProjectTreeBox project={projectOrParent} />
        </Aside>
      )}
      <ProjectForm project={project} parent={parent} isTask={isTask} />
    </Page>
  );
};
