import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';

import { apiService } from './api.service';

const ability = new Ability();

function renameIds(target: any): any {
  if (Array.isArray(target)) {
    return target.map(renameIds);
  }
  if (target && typeof target === 'object') {
    const copy: any = {};
    for (const [key, value] of Object.entries(target)) {
      copy[key === '_id' ? 'id' : key] = renameIds(value);
    }
    return copy;
  }
  return target;
}

function load(rules: any) {
  ability.update(unpackRules(renameIds(rules)));
}

function getAbility() {
  return ability;
}

async function refreshAbilities(): Promise<boolean> {
  try {
    const { rules } = await apiService.get<{ rules: any }>('rules');
    load(rules);
    return true ;
  } catch (err) {
    return false;
  }
}

export const abilityService = {
  load,
  getAbility,
  refreshAbilities
};
