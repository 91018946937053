import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { usePrevious } from '../../../hooks/use-previous';

type Props = {
  onChange?: (state: boolean) => void;
  className?: string;
  initialValue?: boolean;
}

export const ToggleButton: FC<Props> = ({ onChange, className, initialValue, children }) => {
  const [enabled, setEnabled] = useState(initialValue || false);
  const previous = usePrevious(enabled);

  useEffect(() => {
    if (onChange && enabled !== previous) {
      onChange(enabled);
    }
  }, [onChange, enabled, previous]);

  return (
    <div className={clsx('flex items-center cursor-pointer group', className)} onClick={() => setEnabled(state => !state)}>
      <button
        type="button"
        className={clsx(
          'p-0 relative inline-flex flex-shrink-0 justify-start h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none',
          enabled ? 'bg-indigo-600 focus:bg-indigo-700 hover:bg-indigo-700' : 'bg-gray-200 focus:bg-gray-300 hover:bg-gray-300'
        )}
        role="switch"
        aria-checked="false">
        <span
          aria-hidden="true"
          className={clsx(
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            enabled ? 'translate-x-5' : 'translate-x-0'
          )}
        />
      </button>
      { children }
    </div>
  );
};
