import { useContext } from 'react';

import Avatar from '../../../../assets/avatar.png';
import { SubMenu } from './SubMenu';
import { useToggle } from '../../../../hooks/use-toggle';
import { useClickOutsideRef } from '../../../../hooks/use-click-outside-ref';
import { AccountContext } from '../../../contexts/account.context';
import { getMediaUrl } from '../../../../utils/get-media-url';

export const MenuButton = () => {
  const { account } = useContext(AccountContext);
  const [opened, toggle, showMenu] = useToggle();
  const close = () => showMenu(false);
  const ref = useClickOutsideRef<HTMLDivElement>(close);

  return (
    <div className="flex items-center">
      <div className="ml-4 relative flex-shrink-0" ref={ref}>
        <div className="hidden md:flex">
          <button type="button" onClick={toggle}
            className="p-0.5 focus:bg-gray-600 hover:bg-secondaryPandaColor rounded-full text-sm"
            id="user-menu" aria-haspopup="true">
            <span className="sr-only">Open user menu</span>
            <img className="h-8 w-8 rounded-full" src={getMediaUrl(account.avatar?.url) || Avatar} alt="" />
          </button>
        </div>
        <div className="flex md:hidden text-white">
          <span className="fa-stack">
            <i className="fas fa-ellipsis-v fa-2x" onClick={toggle}/>
          </span>
        </div>
        {opened && <SubMenu onClick={close} />}
      </div>
    </div>
  );
};
