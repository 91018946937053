import { useEffect, useState } from 'react';

import { IAdminProject } from '../../../interfaces/admin-project.interface';
import { adminProjectService } from '../../../services/admin.project.service';
import { sortProjectsAndLimit } from '../../../tools/project';
import { NotFound } from '../../general/NotFound';
import { Spinner } from '../../general/Spinner';
import { WorklogListStatus } from './WorklogListStatus';

type Props = {
  clientId: string;
};

type DataState = {
  loading?: boolean;
  projects?: IAdminProject[];
}

export const WorklogList = ({ clientId }: Props) => {
  const [{ loading, projects }, setData] = useState<DataState>({ loading: true });

  useEffect(() => {
    setData({ loading: true });
    adminProjectService
      .getList({ rootOnly: true, clientId })
      .then(projects => sortProjectsAndLimit(projects))
      .then(projects => setData({ projects }));
  }, [clientId]);

  if (loading) {
    return <Spinner />;
  }

  if (!projects) {
    return <NotFound title="projet introuvable" />;
  }

  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      { projects.map((project) => <WorklogListStatus key={project.id} projectId={project.id} />) }
    </ul>
  );
};
