import { ReactNode, useEffect, useMemo, useState } from 'react';
import { CellProps } from 'react-table';

import { IMission } from '../../../interfaces/mission.interface';
import { missionService } from '../../../services/mission.service';
import { Spinner } from '../../general/Spinner';
import { Table, TableCell, TableColumn } from '../../general/Table';
import { TextLink } from '../../general/TextLink';
import { showToast } from '../../general/show-toast';
import { getMissionCategoryLabel } from '../../../enums/mission-category.enum';
import { formatToLongFrench } from '../../../utils/timeslot-dates';
import { useClient } from '../../../hooks/use-client';
import { EmptyMsg } from '../../general/EmptyMsg';
import { OpenIconLink } from '../../general/OpenIconLink';

type Props = {
  clientId?: string;
  className?: string;
  showEnded?: boolean;
  showTitle?: boolean;
  assignedOnly?: boolean;
  emptyMsg?: string | ReactNode;
}

function ClientCell( value: string) {
  const { client } = useClient(value);
  return client ? <TextLink to={`/clients/${client.id}`} className='text-sm font-medium'>{ client.name }</TextLink> : '';
}

type DataState = {
  missionsNotCompleted?: IMission[],
  missionsCompleted?: IMission[],
}

export const MissionList = ({ clientId, assignedOnly, showTitle, showEnded, className, emptyMsg }: Props) => {
  const [{ missionsCompleted, missionsNotCompleted }, setMissions] = useState<DataState>({});

  const columns = useMemo<Array<TableColumn<IMission>>>(
    () => [
      ...(clientId ? [] as any : [{
          Header: 'Client',
          accessor: 'clientId',
          disableSortBy: true,
          Cell: ({ value }) => ClientCell(value),
        },
      ] as Array<TableColumn<IMission>>),
      {
        Header: 'Nom',
        accessor: 'name',
        Cell: ({ row: { original: mission } }: TableCell<IMission> ) => (
          <TextLink to={`/clients/${mission.clientId}/missions/${mission.id}`} className='text-sm font-medium'>{ mission.name }</TextLink>
        )
      },
      {
        Header: 'Catégorie',
        accessor: mission => getMissionCategoryLabel(mission.category)
      },
      {
        Header: 'Avancement',
        accessor: 'progress',
        Cell: ({ value }) => `${value}%`
      },
      {
        Header: 'Échéance',
        accessor: 'deadline',
        Cell: ({ value }) => formatToLongFrench(value)
      },
      {
        Header: 'Date de fin',
        accessor: 'completed',
        Cell: ({ value }) => value ? formatToLongFrench(value) : 'en cours'
      },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({ row: { original: mission } }: CellProps<IMission>) => <OpenIconLink to={`/clients/${mission.clientId}/missions/${mission.id}`} title="Ouvrir la mission" />
      },
    ],
    [clientId]
  );

  useEffect(() => {
    setMissions({});
    missionService.getList(clientId ? { clientId, assignedOnly } : { activeOnly: true, assignedOnly })
      .then(missions => {
        setMissions({
          missionsCompleted: missions.filter(mission => mission.completed),
          missionsNotCompleted: missions.filter(mission => !mission.completed),
        });
      })
      .catch(error => showToast({ error }));
  }, [clientId, assignedOnly]);

  if (!missionsCompleted || !missionsNotCompleted) {
    return <Spinner/>;
  }

  return (
    <>
      { showTitle !== false && <h2 className="text-lg mb-2 font-medium text-gray-900">Missions en cours</h2> }
      { missionsNotCompleted.length || !emptyMsg ? (
          <Table columns={columns} data={missionsNotCompleted} className={className}/>
        ) : (
          <EmptyMsg>{emptyMsg}</EmptyMsg>
        )
      }
      {
        showEnded && (
          <>
            { showTitle !== false && <h2 className="text-lg mb-2 font-medium text-gray-900 mt-8">Missions terminées</h2> }
            { missionsCompleted.length || !emptyMsg ? (
              <Table columns={columns} data={missionsCompleted} className={className}/>
            ) : (
              <EmptyMsg>{emptyMsg}</EmptyMsg>
            )
            }
          </>
        )
      }
    </>
  );
};
