import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AccountContext } from '../../../contexts/account.context';
import Logo from '../../../../assets/logo-panda-conseil.png';
import { NavLinks } from '../../../non-admin/NavLinks';
import { NavLinks as AdminNavLinks } from '../../../admin/NavLinks';

export const Navigation = () => {
  const { isAdmin } = useContext(AccountContext);
  return (
    <div className="flex items-center md:px-2 lg:px-0 w-11/12">
      <Link to="/" className="hidden flex-shrink-0 md:flex items-center">
        <span className="text-center h-9 pl-2"><img className="h-9" src={Logo} alt="Panda Conseil"/></span>
      </Link>
      <div className="lg:ml-4 flex lg:space-x-4 min-w-full md:justify-start justify-evenly">
        { isAdmin ? <AdminNavLinks /> : <NavLinks />}
      </div>
    </div>
  );
};
