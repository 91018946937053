import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { ITimeslotStatus, ITimeslotSummary, IUserTimeslotSummary, timeslotService } from '../../../services/timeslot.service';
import { Widget } from './Widget';

const SummaryStatus = ({ summaries }: {summaries: ITimeslotSummary[]}) => {
  let color: string;
  let text: string;

  const incomplete = summaries.reduce((acc, summary) => acc + (summary.incomplete || summary.draft ? 1 : 0), 0);

  if (incomplete) {
    color = 'text-red-600';
    text = incomplete > 1 ? `${incomplete} fiches incomplètes` : '1 fiche incomplète';
  } else {
    color = 'text-green-500';
    text = 'A jour';
  }

  return <span className={clsx(color, 'text-base')}>{text}</span>;
};

const TeamSummaryStatus = ({ team }: { team: IUserTimeslotSummary[]}) => {
  let color: string;
  let text: string;

  const summaries = ([] as Array<ITimeslotSummary>).concat(...(team.map(item => item.summaries)));

  const submitted = summaries.reduce((acc, summary) => acc + (summary.submitted && !summary.incomplete ? 1 : 0), 0);
  const incomplete = summaries.reduce((acc, summary) => acc + (summary.incomplete || summary.draft ? 1 : 0), 0);

  if (submitted) {
    color = 'text-orange-500';
    text = submitted > 1 ? `${submitted} fiches à valider` : '1 fiche à valider';
  } else if (incomplete) {
    color = 'text-red-600';
    text = incomplete > 1 ? `${incomplete} fiches incomplètes` : '1 fiche incomplète';
  } else {
    color = 'text-green-500';
    text = 'A jour';
  }

  return <span className={clsx(color, 'text-base')}>{text}</span>;
};

export const TimeslotSummaryWidget = () => {
  const [status, setStatus] = useState<ITimeslotStatus>();

  useEffect(() => {
    timeslotService
      .getStatus()
      .then(setStatus)
      .catch(err => console.error(err));
  }, []);

  if (!status) {
    return null;
  }

  return (
    <>
      <Widget title='Vos fiches de temps' icon='fa-calendar-check' link="/timeslots">
        <SummaryStatus summaries={status.summaries} />
      </Widget>
      {
        status.team && (
          <Widget title='Celles de votre équipe' icon='fa-calendar-check' link="/timeslots">
            <TeamSummaryStatus team={status.team} />
          </Widget>
        )
      }
    </>
  );
};
