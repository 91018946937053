export enum MissionCategory {
  CirCii = 'cir_cii',
  Finance = 'finance',
  Another = 'another',
}

export const getMissionCategoryList = () => Object.values(MissionCategory);

export const MissionCategoryLabels: {[category in MissionCategory]: string} = {
  [MissionCategory.CirCii]: 'CIR ou CII',
  [MissionCategory.Finance]: 'Financement non dilutif',
  [MissionCategory.Another]: 'Autre',
};

export function getMissionCategoryLabel(category: MissionCategory): string {
  return MissionCategoryLabels[category];
}
